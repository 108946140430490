import { FormStateContextType } from '../formContext/FormContext.types';

export const RESET_FORM = 'RESET_FORM';
export const SET_READY_FOR_PROCESSING = 'SET_READY_FOR_PROCESSING';
export const SET_BOOKING_DONE_AND_CONTACT = 'SET_BOOKING_DONE_AND_CONTACT';
export const SET_GLOBAL_DATA = 'SET_GLOBAL_DATA';
export const SET_CALL_ME_NOW = 'SET_CALL_ME_NOW';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_PARTNER_DATA = 'SET_PARTNER_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';

type ReducerAction<Type, Payload = undefined> = {
  type: Type;
  payload: Payload;
};

export type FormStatereadyForRequestProcessingType = {
  readyForRequestProcessing: FormStateContextType['readyForRequestProcessing'];
};

export type FormStateContact = NonNullable<FormStateContextType['contactData']>;

export type FormStateGlobalDataType = {
  token?: FormStateContextType['token'];
  locationId?: FormStateContextType['locationId'];
  accountId?: FormStateContextType['accountId'];
  appointmentId?: FormStateContextType['appointmentId']; // B: for bMove
  zoneId?: FormStateContextType['zoneId'];
  isReadyToPing?: FormStateContextType['isReadyToPing'];
  isCallRequired?: FormStateContextType['isCallRequired'];
};

export type FormStatePartnerData = NonNullable<FormStateContextType['partnerData']>;
export type FormStateUserData = NonNullable<FormStateContextType['user']>;

export type FormAction =
  | ReducerAction<typeof RESET_FORM>
  | ReducerAction<typeof SET_GLOBAL_DATA, FormStateGlobalDataType>
  | ReducerAction<typeof SET_READY_FOR_PROCESSING, FormStatereadyForRequestProcessingType>
  | ReducerAction<typeof SET_CALL_ME_NOW, FormStateContact>
  | ReducerAction<typeof SET_BOOKING_DONE_AND_CONTACT, FormStateContact>
  | ReducerAction<typeof SET_PARTNER_DATA, FormStatePartnerData>
  | ReducerAction<typeof SET_USER_DATA, FormStateUserData>;

export type FormReducerType = (state: FormStateContextType, action: FormAction) => FormStateContextType;
