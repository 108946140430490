import React from 'react';
import clsx from 'clsx';
import { SvgIcon, makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

const useStyles = makeStyles({
  root: {
    fontSize: 24,
  },
});

export const ArrowLeftIcon = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  const styles = useStyles();

  return (
    <SvgIcon
      className={clsx([className, styles.root])}
      onClick={onClick}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M21 12C21 11.4477 20.5523 11 20 11H6.83L9.70647 8.11549C10.0952 7.7257 10.0947 7.09474 9.70549 6.70549C9.31586 6.31586 8.68414 6.31586 8.29451 6.70549L3.70711 11.2929C3.31658 11.6834 3.31658 12.3166 3.70711 12.7071L8.29451 17.2945C8.68414 17.6841 9.31586 17.6841 9.70549 17.2945C10.0947 16.9053 10.0952 16.2743 9.70647 15.8845L6.83 13H20C20.5523 13 21 12.5523 21 12Z"
        fill={colors.basic.black}
      />
    </SvgIcon>
  );
};
