import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const MuscleIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M20.7402 9.85767C21.2754 9.14565 21.6525 8.06534 20.7524 6.54309C19.8157 4.97174 13.0524 3.22852 12.3712 3.22852C10.7534 3.22852 7.4204 8.43363 7.16495 10.3978C5.71741 21.3359 2.60337 29.4751 3.28456 34.5206C3.63733 37.1109 3.67382 39.026 5.06054 42.316C6.21614 45.0536 12.9794 45.2009 17.1396 45.5201C22.4554 45.9375 28.6591 49.8659 38.1594 46.8705C50.1168 43.1017 48.2678 37.9088 47.2096 34.9994C42.1492 21.2009 26.2871 17.0761 19.9495 29.9784C18.3804 33.1579 17.8208 33.2684 17.8208 33.2684C15.8259 23.4965 13.0889 24.0735 14.5608 17.6285C14.5608 17.6285 18.6115 16.7692 20.2536 14.9523C21.2146 13.8965 23.9394 11.2326 22.1756 10.0664C20.7037 9.09655 19.7306 10.717 18.1006 11.1467"
        fill="#F1CCA5"
      />
      <g clipPath="url(#clip6)">
        <path
          d="M28.4158 7.09571C28.1725 5.40159 27.2845 4.50543 25.6059 4.2599C25.5207 4.24763 25.5207 4.13714 25.6059 4.12487C27.2724 3.87934 28.1725 2.98318 28.4158 1.28906C28.428 1.20313 28.5374 1.20313 28.5496 1.28906C28.7929 2.9709 29.6809 3.87934 31.3595 4.12487C31.4447 4.13714 31.4447 4.24763 31.3595 4.2599C29.6809 4.50543 28.7929 5.40159 28.5496 7.09571C28.5374 7.18164 28.428 7.18164 28.4158 7.09571Z"
          fill="#226937"
        />
        <path
          d="M39.339 21.9374C39.1079 18.8192 37.5995 17.2847 34.5098 17.0637C34.4733 17.0637 34.4733 17.0024 34.5098 17.0024C37.5995 16.7691 39.12 15.2469 39.339 12.1287C39.339 12.0919 39.3998 12.0919 39.3998 12.1287C39.6309 15.2469 41.1393 16.7814 44.229 17.0024C44.2655 17.0024 44.2655 17.0637 44.229 17.0637C41.1393 17.297 39.6188 18.8192 39.3998 21.9374C39.3998 21.9742 39.339 21.9742 39.339 21.9374Z"
          fill="#E9F0EB"
        />
        <path
          d="M42.0878 23.5578C41.7958 20.0468 40.0685 18.3036 36.5896 18.0089C36.5287 18.0089 36.5287 17.923 36.5896 17.923C40.0685 17.6284 41.7958 15.8852 42.0878 12.3742C42.0878 12.3128 42.1729 12.3128 42.1729 12.3742C42.4649 15.8852 44.1922 17.6284 47.6712 17.923C47.732 17.923 47.732 18.0089 47.6712 18.0089C44.1922 18.3036 42.4649 20.0468 42.1729 23.5578C42.1729 23.6192 42.1 23.6192 42.0878 23.5578Z"
          fill="#226937"
        />
        <path d="M42.9337 17.964L42.1338 17.1567L41.3339 17.964L42.1338 18.7713L42.9337 17.964Z" fill="white" />
      </g>
      <path
        d="M18.3076 7.40259C18.8428 6.69057 19.2199 5.61027 18.3198 4.08802C17.3831 2.51666 10.6198 0.773438 9.93862 0.773438C8.32078 0.773438 4.98778 5.97855 4.73233 7.94275C3.28479 18.8809 0.170751 27.02 0.851947 32.0655C1.20471 34.6558 1.2412 36.5709 2.62792 39.8609C3.78352 42.5985 10.5468 42.7458 14.707 43.065C20.0228 43.4824 26.2265 47.4108 35.7268 44.4154C47.6842 40.6466 45.8352 35.4537 44.7769 32.5443C39.7166 18.7458 23.8545 14.621 17.5169 27.5233C15.9477 30.7029 15.3882 30.8133 15.3882 30.8133C13.3933 21.0415 10.6563 21.6185 12.1282 15.1734C12.1282 15.1734 16.1789 14.3141 17.821 12.4972C18.782 11.4415 21.5068 8.77753 19.743 7.61129C18.2711 6.64147 15.7288 8.64249 12.4566 9.61231C11.6781 9.84556 13.5392 6.92382 12.3836 6.50643"
        stroke="#EC7200"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <clipPath id="clip6">
          <rect width="22.1754" height="22.3795" fill="white" transform="translate(25.5449 1.22754)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
