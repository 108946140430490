import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';

import { AppRoute } from 'common/routing/AppRoute';
import { setGlobalData } from 'common/context/form/formActionCreators/formActionCreators';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { checkPostalCodeAction } from 'common/api/actions/booking/bookingActions';
import { CategoryIdEnum } from 'common/api/types';
import { MovingFormStep1Step2 } from 'bMove/app/moving/movingFormStep1Step2/MovingFormStep1Step2';
import { MovingStep1Step2FormValues } from 'bMove/app/moving/movingFormStep1Step2/MovingFormStep1Step2.types';
import { findNearestLocationId } from 'common/utils/findNearestLocationId';
import { useResetState } from 'common/hooks/useResetState/useResetState';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';
import { useAccount } from 'common/hooks/useAccount/useAccount';

export const MovingStep1 = () => {
  const history = useHistory();
  const dispatch = useFormDispatch();
  const resetState = useResetState();
  const [{ moving }, { movingDispatch }] = useMovingContext();
  const { recreateAccountWithNewLocationId, isRecreatingAccount } = useAccount();
  const { mutate, loading } = useMutation(checkPostalCodeAction);

  const handleSubmit = useCallback(
    async (values: MovingStep1Step2FormValues, isDirty) => {
      if (!isDirty) history.push(movingRoutes.step2);
      const { apartment, ...restValues } = values;

      const { payload } = await mutate({ postal: restValues.fullAddress.postal, job_category_id: CategoryIdEnum.move });

      // if (payload?.meta.errors?.length) {
      //   resetState();
      //   history.push(movingRoutes.unserviced);
      //   return;
      // }

      if (!payload?.locations?.length) {
        resetState();
        history.push(movingRoutes.unserviced);
        return;
      }
      const addressForApi = {
        ...restValues.fullAddress,
        address2: apartment,
      };
      const nearestLocationId = findNearestLocationId(payload?.locations);
      const zoneId = payload.locations[0].zone.id;

      if (moving?.step5) {
        await recreateAccountWithNewLocationId(
          {
            contactInformationData: moving.step5,
            billingAddress: addressForApi,
            categoryId: CategoryIdEnum.move,
          },
          nearestLocationId,
        );
      }

      dispatch(setGlobalData({ locationId: nearestLocationId, zoneId }));

      movingDispatch(
        movingActions.setStep1({
          ...restValues,
          rooms: restValues.rooms as string,
          fullAddress: addressForApi,
        }),
      );

      history.push(movingRoutes.step2);
    },
    [mutate, recreateAccountWithNewLocationId, dispatch, movingDispatch, history, moving?.step5, resetState],
  );

  return (
    <MovingFormStep1Step2
      withRooms
      title="1. Moving from"
      onSubmit={handleSubmit}
      onBackClick={() => history.push(AppRoute.home)}
      defaultValues={{
        shortAddress: moving?.step1?.shortAddress || '',
        fullAddress: moving?.step1?.fullAddress || null,
        apartment: moving?.step1?.fullAddress.address2 || '',
        typeOfDwelling: moving?.step1?.typeOfDwelling ?? null,
        floor: moving?.step1?.floor ?? null,
        accessType: moving?.step1?.accessType ?? null,
        truckParking: moving?.step1?.truckParking ?? null,
        rooms: moving?.step1?.rooms || null,
      }}
      isLoading={loading || isRecreatingAccount}
      tipBoxData={{
        icon: 'h',
        text: (
          <>
            Did you know? HUNKS stands for <strong>H</strong>onest, <strong>U</strong>niformed, <strong>N</strong>ice,{' '}
            <strong>K</strong>nowledgeable <strong>S</strong>ervice – our promise to you!
          </>
        ),
      }}
    />
  );
};
