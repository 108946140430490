import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const HeroImgDecorationSmall = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} style={{ height: 55, width: 112 }} viewBox="0 0 112 55" fill="none">
      <path
        opacity="0.75"
        d="M0 55L-2.40413e-06 0L108.143 -4.72708e-06C108.61 -4.7475e-06 109.062 0.163505 109.422 0.462161L111.151 1.89966C112.112 2.69934 112.112 4.17565 111.151 4.97533L110.715 5.33716C109.754 6.13684 109.754 7.61315 110.715 8.41283L111.151 8.77466C112.112 9.57434 112.112 11.0507 111.151 11.8503L110.715 12.2122C109.754 13.0118 109.754 14.4882 110.715 15.2878L111.151 15.6497C112.112 16.4493 112.112 17.9257 111.151 18.7253L110.715 19.0872C109.754 19.8868 109.754 21.3632 110.715 22.1628L111.151 22.5247C112.112 23.3243 112.112 24.8007 111.151 25.6003L110.715 25.9622C109.754 26.7618 109.754 28.2382 110.715 29.0378L111.151 29.3997C112.112 30.1993 112.112 31.6757 111.151 32.4753L110.715 32.8372C109.754 33.6368 109.754 35.1132 110.715 35.9128L111.151 36.2747C112.112 37.0743 112.112 38.5507 111.151 39.3503L110.715 39.7122C109.754 40.5118 109.754 41.9882 110.715 42.7878L111.151 43.1497C112.112 43.9493 112.112 45.4257 111.151 46.2253L110.715 46.5872C109.754 47.3868 109.754 48.8632 110.715 49.6628L111.151 50.0247C112.112 50.8243 112.112 52.3007 111.151 53.1003L109.422 54.5378C109.062 54.8365 108.61 55 108.143 55L0 55Z"
        fill={colors.primary.main}
      />
    </SvgIcon>
  );
};
