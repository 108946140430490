import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    position: 'relative',
    padding: '16px 36px 24px 36px',
    backgroundColor: colors.basic.white,
    borderRadius: '4px',
    boxShadow: '0px 0px 16px rgba(134, 145, 166, 0.16)',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },

    '&:not(:last-child)': {
      marginBottom: 20,
    },
  },
  cell: {
    border: 'none',
    padding: 0,
  },
  title: {
    marginBottom: 19,
  },
  icon: {
    position: 'absolute',
    right: 16,
    top: 22,

    fontSize: 16,
    color: colors.secondary.main,
    fill: 'none',
    cursor: 'pointer',
  },
  key: {
    color: colors.grey120,
    fontWeight: 'bold',
    width: ({ wideKeyField }: { wideKeyField?: boolean }) => (wideKeyField ? 270 : 126),

    [theme.breakpoints.up('sm')]: {
      width: ({ wideKeyField }: { wideKeyField?: boolean }) => (wideKeyField ? 300 : 126),
      marginRight: ({ wideKeyField }: { wideKeyField?: boolean }) => (wideKeyField ? 20 : 0),
    },
    [theme.breakpoints.up('md')]: {
      width: ({ wideKeyField }: { wideKeyField?: boolean }) => (wideKeyField ? 519 : 126),
    },

    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
  contentWrapper: {
    '&:not(:last-child)': {
      marginBottom: 20,

      [theme.breakpoints.up('sm')]: {
        marginBottom: 16,
      },
    },
  },
}));
