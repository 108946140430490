import React from 'react';

import { AllDone } from 'common/app/allDone/AllDone';

export const MovingAllDone = () => {
  return <AllDone title="we’ll call you soon!" subtitle="You'll receive a call at:" isAnotherServiceBtnVisible />;
};

export const MovingAllDoneError = () => {
  return (
    <AllDone
      title={'we’ll call you soon to collect prepayment.'}
      altTitle
      isAnotherServiceBtnVisible
      subtitle="You'll receive a call at:"
    />
  );
};

export const MovingAllDonePrepayment = () => {
  return (
    <AllDone
      title="see you soon!"
      subtitle="You'll receive a confirmation email at:"
      reschedule
      isAnotherServiceBtnVisible
    />
  );
};
