import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CheckIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="25" height="24" viewBox="0 0 25 24" fill="none">
      <path
        d="M6.85694 13.472C6.38102 12.9961 6.38102 12.2142 6.85694 11.7383C7.33286 11.2623 8.11473 11.2623 8.59065 11.7383L10.4943 13.6419L16.4093 7.72694C16.8853 7.25101 17.6671 7.25101 18.1431 7.72694C18.619 8.20286 18.619 8.98473 18.1431 9.46065L11.3442 16.2595C11.1742 16.4295 10.9363 16.5655 10.6983 16.5995C10.6303 16.5995 10.5283 16.6335 10.4603 16.6335C10.1544 16.6335 9.81445 16.4975 9.57649 16.2595L6.85694 13.472Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
