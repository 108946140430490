import React, { useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { ContactInformationFormValues } from 'common/app/contactInformationForm/ContactInformationForm.types';
import { CategoryIdEnum } from 'common/api/types';
import { ContactInformationForm } from 'common/app/contactInformationForm/ContactInformationForm';
import { laborRoutes } from 'common/routing/AppRoute';
import { useLaborContext } from 'common/hooks/useLabor/useLabor';
import { laborActions } from 'common/context/labor/LaborContextContextSlice';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { useFormState } from 'common/hooks/useFormState/useFormState';

export const LaborStep2 = () => {
  const history = useHistory();
  const [{ labor }, { laborDispatch }] = useLaborContext();
  const { createOrUpdateAccount, isCreatingOrUpdatingAccount } = useAccount();
  const { user } = useFormState();

  const handleSubmit = useCallback(
    async (values: ContactInformationFormValues, isDirty: boolean) => {
      if (!labor?.step1) return;
      if (!isDirty) {
        history.push(laborRoutes.step3);
        return;
      }
      const account = await createOrUpdateAccount({
        contactInformationData: values,
        billingAddress: labor.step1.fullAddress,
        categoryId: CategoryIdEnum.labor,
      });

      if (!account) {
        return;
      }

      laborDispatch(laborActions.setStep2(values));
      history.push(laborRoutes.step3);
    },
    [labor?.step1, laborDispatch, history, createOrUpdateAccount],
  );

  if (!labor?.step1) {
    return <Redirect to={laborRoutes.step1} />;
  }

  return (
    <ContactInformationForm
      loading={isCreatingOrUpdatingAccount}
      onSubmit={handleSubmit}
      title="2. Contact information"
      onBackClick={() => history.push(laborRoutes.step1)}
      defaultValues={{
        firstName: labor?.step2?.firstName || user?.firstName || '',
        lastName: labor?.step2?.lastName || user?.lastName || '',
        email: labor?.step2?.email || user?.email || '',
        phone: labor?.step2?.phone || user?.phone || '',
        companyName: labor?.step2?.companyName || '',
        orderNumber: labor?.step2?.orderNumber || '',
        specialInstructions: labor?.step2?.specialInstructions || '',
        additionalNotes: labor?.step2?.additionalNotes || '',
      }}
      hasPrefilledData={!!labor?.step2}
      tipBoxData={{
        icon: 'h',
        text: (
          <>
            Did you know? HUNKS stands for <strong>H</strong>onest, <strong>U</strong>niformed, <strong>N</strong>ice,{' '}
            <strong>K</strong>nowledgeable <strong>S</strong>ervice – our promise to you!
          </>
        ),
      }}
    />
  );
};
