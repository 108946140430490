import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles(() => ({
  wrapper: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
  },
  textContent: {
    maxWidth: 489,
  },
  listItemsContainer: {
    display: 'flex',
  },
  icon: {
    fontSize: 24,
    marginRight: 17,
    color: colors.secondary.main,
  },
  buttonsGroup: {
    marginTop: 48,
  },
  loader: {
    marginTop: 24,
    maxWidth: 489,
  },
}));
