import React, { useMemo, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { AppRoute, laborRoutes } from 'common/routing/AppRoute';
import { TruckIcon } from 'common/assets/icons/TruckIcon';
import { junkRemovalRoutes, movingRoutes } from 'common/routing/AppRoute';
import { ReadyForRequestProcessingEnum } from 'common/context/form/formContext/FormContext.types';

import { useStyles } from './ProcessingRequest.styles';
import { ProcessingRequestProps } from './ProcessingRequest.types';

const serviceRouteForTypeMap: Record<ReadyForRequestProcessingEnum, string> = {
  [ReadyForRequestProcessingEnum.JunkRemoval]: junkRemovalRoutes.alldone,
  [ReadyForRequestProcessingEnum.Moving]: movingRoutes.alldone,
  [ReadyForRequestProcessingEnum.Labor]: laborRoutes.alldone,
  [ReadyForRequestProcessingEnum.CallMeNow]: '', // no processing for callMeModal
};

export const ProcessingRequest = ({ onProcess }: ProcessingRequestProps) => {
  const styles = useStyles();
  const formState = useFormState();

  const redirectionRoute = useMemo(() => {
    if (formState.readyForRequestProcessing === undefined) return AppRoute.home;
    return serviceRouteForTypeMap[formState.readyForRequestProcessing];
  }, [formState.readyForRequestProcessing]);

  useEffect(() => {
    formState.readyForRequestProcessing && onProcess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!formState.readyForRequestProcessing) {
    return <Redirect to={`${AppRoute.home}`} />;
  }

  if (formState.bookingDone) {
    return <Redirect to={redirectionRoute} />;
  }

  return (
    <div className={styles.bgWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.truckContainer}>
          <TruckIcon className={styles.truck} />
        </div>
        <Typography className={styles.text} variant="h2">
          Processing the request...
        </Typography>
      </div>
    </div>
  );
};
