import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: '24px 8px',
    display: 'flex',
    alignItems: 'center',

    '&:not(:last-child)': {
      borderBottom: `1px dashed ${colors.grey60}`,
    },

    [theme.breakpoints.up('sm')]: {
      padding: '24px 8px',
    },

    [theme.breakpoints.up('md')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      paddingLeft: 0,
      paddingRight: 0,

      '&:not(:last-child)': {
        borderBottom: 'none',
        borderRight: `1px dashed ${colors.grey60}`,
      },
    },
  },

  title: {
    marginLeft: 16,
    color: colors.grey160,
    fontWeight: 'bold',
    fontSize: 14,

    maxWidth: 120,

    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginTop: 16,
      textAlign: 'center',
    },
  },
  img: {
    width: 60,

    [theme.breakpoints.up('sm')]: {
      width: 80,
    },
    [theme.breakpoints.up('md')]: {
      width: 100,
    },
  },
  price: {
    marginLeft: 'auto',

    [theme.breakpoints.up('md')]: {
      marginLeft: 0,
      marginTop: 16,
    },
  },
}));
