import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  loader: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
  },
});
