import { CategoryIdEnum } from 'common/api/types';
import { ContactInformationFormValues } from 'common/app/contactInformationForm/ContactInformationForm.types';
import { AccountPayload, AccountTypeEnum, BillingAddress } from 'common/api/actions/account/accountActions.types';
import { PartnerDataContextType } from 'common/context/form/formContext/FormContext.types';

export type MapStateToAccountParams = {
  contactInformationData: ContactInformationFormValues;
  billingAddress: BillingAddress;
  categoryId: CategoryIdEnum;
};

type MapStateToAccountPayload = {
  partnerData?: PartnerDataContextType;
} & MapStateToAccountParams;

export const mapStateToAccountPayload = ({
  contactInformationData,
  billingAddress,
  categoryId,
  partnerData,
}: MapStateToAccountPayload): AccountPayload => {
  return {
    first_name: contactInformationData.firstName,
    last_name: contactInformationData.lastName,
    email: contactInformationData.email,
    phone: contactInformationData.phone,
    billing_address: billingAddress,
    category: { id: categoryId },
    company: {
      name: contactInformationData.companyName,
    },
    notes: {
      special_instructions: `${contactInformationData.specialInstructions}${
        contactInformationData.additionalNotes ? `. \nAdditional notes: ${contactInformationData.additionalNotes}` : ''
      }`,
      information: `${
        partnerData
          ? `Partner: ${partnerData.partnerName} - ${partnerData.subpartnerName}${
              contactInformationData.orderNumber
                ? `. \nPurchase order number: ${contactInformationData.orderNumber}`
                : ''
            }`
          : ''
      }`,
    },
    type: {
      id: contactInformationData.companyName ? AccountTypeEnum.Commercial : AccountTypeEnum.Residential,
    },
    ...(partnerData ? { subpartner: { id: partnerData.subpartnerId } } : {}),
  };
};
