import { MovingContextReducerState } from './MovingContext.types';

export const MOVING_KEY = 'OBT/MOVING_STATE';

export const movingDefaultState: MovingContextReducerState = {
  moving: {},
};

class MovingStorageClass {
  private _state: MovingContextReducerState = movingDefaultState;

  constructor() {
    try {
      const formStateString = sessionStorage.getItem(MOVING_KEY);

      if (formStateString) {
        this._state = JSON.parse(formStateString);
      }
    } catch {
      this._state = movingDefaultState;
    }
  }

  get state(): MovingContextReducerState {
    const formStateString = sessionStorage.getItem(MOVING_KEY);

    if (formStateString) {
      this._state = JSON.parse(formStateString);
    }

    return this._state;
  }

  set state(value: MovingContextReducerState) {
    this._state = value;

    try {
      sessionStorage.setItem(MOVING_KEY, JSON.stringify(value));
    } catch {}
  }

  clear() {
    this._state = movingDefaultState;
    sessionStorage.removeItem(MOVING_KEY);
  }
}

export const movingStorage = new MovingStorageClass();
