import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('xs')]: {
      marginTop: 16,
    },
  },
  gridItem: {
    padding: 6,
  },
}));
