import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    maxWidth: 702,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  boxesContainer: {
    marginBottom: -24,
    '& > *': {
      marginBottom: 24,
    },

    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      '& > *': {
        flexBasis: 'calc(50% - 12px)', // minus half of space 24px/2
      },
    },
  },
}));
