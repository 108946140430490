import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const EcoFriendlyIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M43.4 22.84C43.27 22.69 43.08 22.62 42.88 22.63C42.47 22.66 32.83 23.52 29.82 26.53C27.09 29.26 26.34 33.07 27.84 35.62L25.44 38.02V26.91C28.61 26.51 31.09 23.1 31.09 18.96C31.09 14.75 26.22 7.34 26.01 7.03C25.9 6.87 25.72 6.76 25.52 6.75C25.32 6.74 25.13 6.82 25.01 6.97C24.75 7.28 18.53 14.71 18.53 18.96C18.53 23.1 21.01 26.51 24.18 26.91V38.1L21.7 35.62C23.21 33.07 22.45 29.26 19.72 26.53C16.72 23.53 7.07 22.67 6.66 22.63C6.45 22.61 6.27 22.69 6.14 22.84C6.01 22.99 5.96 23.19 5.99 23.38C6.07 23.75 7.86 32.43 10.83 35.4C12.32 36.89 14.17 37.84 16.04 38.09C16.36 38.13 16.67 38.15 16.98 38.15C18.53 38.15 19.9 37.62 20.91 36.61L24.16 39.86V43.76C24.16 44.11 24.44 44.38 24.78 44.38C25.12 44.38 25.4 44.1 25.4 43.76V39.8L28.58 36.62C29.61 37.65 31.01 38.15 32.52 38.15C34.59 38.15 36.86 37.21 38.66 35.41C41.63 32.44 43.43 23.75 43.5 23.39C43.58 23.19 43.53 22.99 43.4 22.84Z"
        fill="#F1CCA5"
      />
      <path
        d="M23.81 16.3V41.02"
        stroke="#006A36"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.29 24.22C21.17 27.1 21.71 31.22 19.5 33.42C17.29 35.62 13.17 35.09 10.3 32.21C7.43 29.33 5.62 20.51 5.62 20.51C5.62 20.51 15.42 21.35 18.29 24.22Z"
        stroke="#EC7200"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.78 36.82L14.67 27.72"
        stroke="#006A36"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.26 24.22C26.38 27.1 25.84 31.22 28.05 33.42C30.26 35.62 34.38 35.09 37.25 32.21C40.12 29.33 41.92 20.5 41.92 20.5C41.92 20.5 32.14 21.35 29.26 24.22Z"
        stroke="#EC7200"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.78 36.82L32.88 27.72"
        stroke="#006A36"
        strokeWidth="1.25"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.16 16.21C18.16 20.28 20.69 23.57 23.81 23.57C26.93 23.57 29.46 20.27 29.46 16.21C29.46 12.15 24.49 4.62 24.49 4.62C24.49 4.62 18.16 12.14 18.16 16.21Z"
        stroke="#EC7200"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
