import { makeStyles } from '@material-ui/core';

export const pageMaxWidth = '1024px';

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: '0 16px',

    [theme.breakpoints.up('sm')]: {
      padding: '0 24px',
    },

    [theme.breakpoints.up('md')]: {
      padding: '0 40px',

      maxWidth: `calc(${pageMaxWidth} + 80px)`, // +80px cause of paddings
      margin: '0 auto',
    },
  },
}));
