import React, { useCallback } from 'react';
import { useAlert } from '@chhjpackages/components';
import { useMutation } from 'react-fetching-library';

import { mapPartnerResponseToStateData } from 'common/context/version/VersionContextController.utils';
import { getPartnerInfoAction } from 'common/api/actions/partner/partnerActions';
import { setPartnerData } from 'common/context/form/formActionCreators/formActionCreators';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';

import { CorporateCodeModalContainerProps } from './CorporateCodeModal.types';
import { CorporateCodeModal } from './CorporateCodeModal';

export const CorporateCodeModalContainer = ({ open, onClose }: CorporateCodeModalContainerProps) => {
  const formDispatch = useFormDispatch();
  const { showAlert } = useAlert();
  const { mutate: getPartnerInfoMutate, abort: abortGetPartnerInfo, loading: isGettingPartnerInfo } = useMutation(
    getPartnerInfoAction,
  );

  const getPartnerInfo = useCallback(
    async (partnerToken: string) => {
      abortGetPartnerInfo();
      const { payload } = await getPartnerInfoMutate({
        subpartner_token: partnerToken.replaceAll('-', '').toUpperCase(),
      });

      if (!payload?.subpartners?.length || payload.meta.errors?.length) {
        showAlert(`The corporate code "${partnerToken.toUpperCase()}" does not exist`, { variant: 'error' });
        return;
      }
      formDispatch(setPartnerData(mapPartnerResponseToStateData(payload.subpartners[0], partnerToken)));
    },
    [getPartnerInfoMutate, abortGetPartnerInfo, formDispatch, showAlert],
  );

  const handleSubmit = useCallback(
    async ({ corporateCode }: { corporateCode: string }) => {
      await getPartnerInfo(corporateCode);
      onClose();
    },
    [getPartnerInfo, onClose],
  );

  return <CorporateCodeModal onSubmit={handleSubmit} isLoading={isGettingPartnerInfo} open={open} onClose={onClose} />;
};
