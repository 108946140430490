import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';

import { rudderanalytics } from 'common/utils/rudderanalytics';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { createCallRequestAction } from 'common/api/actions/account/accountActions';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setBookingDoneAndContact } from 'common/context/form/formActionCreators/formActionCreators';
import { laborRoutes } from 'common/routing/AppRoute';
import { ProcessingRequest } from 'common/app/processingRequest/ProcessingRequest';
import { CategoryIdEnum } from 'common/api/types';
import { useLaborStateContext } from 'common/hooks/useLabor/useLabor';

export const LaborProcessingRequest = () => {
  const history = useHistory();
  const { labor } = useLaborStateContext();
  const { token, locationId, accountId, partnerData } = useFormState();
  const dispatch = useFormDispatch();
  const { mutate: createCallRequestMutate, abort: abortCallRequest } = useMutation(createCallRequestAction);

  const createCallRequest = useCallback(async () => {
    if (!labor?.step1 || !labor?.step2 || !token || !locationId || !accountId) {
      return;
    }

    abortCallRequest();

    const { payload: callRequestPayload } = await createCallRequestMutate({
      locationId,
      accountId,
      token,
      category: { id: CategoryIdEnum.labor },
      ...(partnerData ? { subpartner: { id: partnerData.subpartnerId } } : {}),
    });

    if (callRequestPayload?.meta.status.code === 200) {
      rudderanalytics.track(`Web Lead Call Scheduled`);

      dispatch(
        setBookingDoneAndContact({
          type: 'phone',
          contact: labor.step2.phone,
        }),
      );
      history.push(laborRoutes.alldone);
    }
  }, [createCallRequestMutate, abortCallRequest, dispatch, history, labor, token, locationId, accountId, partnerData]);

  return <ProcessingRequest onProcess={createCallRequest} />;
};
