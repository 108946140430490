import { FormStateContextType } from '../formContext/FormContext.types';

const FORM_STATE = 'OBT/FORM_STATE';

export const initialFormState: FormStateContextType = {
  isPartner: false,
};

class FormStorage {
  private _formState: FormStateContextType = initialFormState;

  constructor() {
    try {
      const formStateString = sessionStorage.getItem(FORM_STATE);

      if (formStateString) {
        this._formState = JSON.parse(formStateString);
      }
    } catch {
      this._formState = initialFormState;
    }
  }

  get formState(): FormStateContextType {
    return this._formState;
  }

  set formState(value: FormStateContextType) {
    this._formState = value;

    try {
      sessionStorage.setItem(FORM_STATE, JSON.stringify(value));
    } catch {}
  }
}

export const formStorage = new FormStorage();
