import { GetPricingResponse } from 'common/api/actions/booking/bookingActions.types';

import { JunkRemovalPricing } from './JunkRemovalContext.types';

const pricingIdMap: Record<number, keyof JunkRemovalPricing> = {
  3: 'Less than 1/8th truckload',
  4: '1/6 truckload',
  2: '1/4 truckload',
  36: '1/3 truckload',
  19: '3/8 truckload',
  20: '1/2 truckload',
  17: '5/8 truckload',
  32: '2/3 truckload',
  33: '5/6 truckload',
  6: '7/8 truckload',
  46: 'Full truckload',
};

export const mapPricingResponse = (pricingResponse: GetPricingResponse | undefined) => {
  /* API return a lot of pricing possibilities, but in design
   * only some of them are used. In pricingIdMap there are listed id's as keys that are used
   */
  if (!pricingResponse?.viewpricingbyproduct?.length) {
    return {};
  }

  const pricing = pricingResponse.viewpricingbyproduct.reduce((acc, curr) => {
    if (pricingIdMap[curr.id]) {
      const keyName = pricingIdMap[curr.id];

      acc[keyName] = curr.price;
    }

    return acc;
  }, {} as JunkRemovalPricing);

  return pricing;
};
