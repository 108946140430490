import React, { ReactNode, useReducer, useMemo, useEffect, useCallback } from 'react';
import { useParameterizedQuery } from 'react-fetching-library';
import moment from 'moment';

import { getPricingAction } from 'common/api/actions/booking/bookingActions';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { CategoryIdEnum } from 'common/api/types';
import { DateFormatForAPI } from 'common/utils/dateTime';

import { JunkRemovalDispatchContext, JunkRemovalStateContext } from './JunkRemovalContext';
import { JunkRemovalStateContextValue, JunkRemovalDispatchContextValue } from './JunkRemovalContext.types';
import { junkRemovalActions, junkRemovalReducer } from './JunkRemovalContextSlice';
import { junkRemovalStorage } from './JunkRemovalContextStorage';

export const JunkRemovalContextController = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(junkRemovalReducer, junkRemovalStorage.state);
  const { junkRemoval } = state;
  const { locationId, token } = useFormState();
  const { query: queryGetPricing, loading: isGettingPricing, abort: abortGettingPricing } = useParameterizedQuery(
    getPricingAction,
  );

  const getPricing = useCallback(async () => {
    if (!junkRemoval?.step1 || !junkRemoval?.step3 || !locationId || !token) return;

    abortGettingPricing();
    const { payload } = await queryGetPricing({
      token,
      locationId,
      postal: junkRemoval.step1.fullAddress.postal,
      job_date: moment(junkRemoval.step3.date).format(DateFormatForAPI),
      category_id: CategoryIdEnum.junk,
    });

    dispatch(junkRemovalActions.setPricing(payload));
  }, [junkRemoval?.step1, junkRemoval?.step3, locationId, token, queryGetPricing, abortGettingPricing]);

  useEffect(() => {
    (async () => {
      await getPricing();
    })();
  }, [getPricing]);

  useEffect(() => {
    junkRemovalStorage.state = state;
  }, [state]);

  useEffect(() => {
    return () => junkRemovalStorage.clear();
  }, []);

  const stateValue = useMemo<JunkRemovalStateContextValue>(
    () => ({
      ...state,
      isGettingPricing,
    }),
    [state, isGettingPricing],
  );
  const dispatchValue = useMemo<JunkRemovalDispatchContextValue>(
    () => ({
      junkRemovalDispatch: dispatch,
    }),
    [dispatch],
  );

  return (
    <JunkRemovalStateContext.Provider value={stateValue}>
      <JunkRemovalDispatchContext.Provider value={dispatchValue}>{children}</JunkRemovalDispatchContext.Provider>
    </JunkRemovalStateContext.Provider>
  );
};
