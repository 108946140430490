import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';
import { useIdleTimer } from 'react-idle-timer';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { pingAction } from 'common/api/actions/account/accountActions';
import { useResetState } from 'common/hooks/useResetState/useResetState';

import { PingControllerProps } from './PingController.types';

const pingInterval = 60000; // 60 seconds (1 minute)
const idleInterval = 299000; // 299 seconds (almost 5 minutes)

export const PingController = ({ children }: PingControllerProps) => {
  const [timerId, setTimerId] = useState<NodeJS.Timeout | null>(null);
  const { isReadyToPing, bookingDone, token, locationId, accountId, isPartner } = useFormState();
  const resetState = useResetState();
  const { mutate, abort } = useMutation(pingAction);

  const stopTimer = useCallback(() => {
    if (timerId) {
      clearInterval(timerId);
      setTimerId(null);
    }
  }, [timerId]);

  const dropBooking = useCallback(() => {
    if (!isReadyToPing) {
      return;
    }

    resetState();
    stopTimer();
  }, [resetState, stopTimer, isReadyToPing]);

  const { pause, resume, reset } = useIdleTimer({ stopOnIdle: true, timeout: idleInterval, onIdle: dropBooking });

  useEffect(() => {
    if (isReadyToPing) resume();
    else {
      reset();
      pause();
    }
  }, [isReadyToPing, pause, resume, reset]);

  const makePing = useCallback(async () => {
    if (!token || !locationId || !accountId) return;

    abort();
    await mutate({
      locationId,
      accountId,
      token,
      booking_complete: bookingDone ?? false,
    });
  }, [mutate, abort, locationId, accountId, token, bookingDone]);

  /**
   * if booking is done (user created appointment during processingRequest view)
   * then stop the timer and ping last one time with booking_complete: true
   */
  useEffect(() => {
    if (!bookingDone || !timerId || !isReadyToPing) return;

    makePing();
    stopTimer();
  }, [bookingDone, makePing, stopTimer, timerId, isReadyToPing]);

  const isReadyToSetInterval = useMemo(() => {
    // On partner version ping should NOT be runned
    if (isPartner) return false;

    return isReadyToPing && timerId === null && !bookingDone;
  }, [isReadyToPing, bookingDone, timerId, isPartner]);

  /**
   * when user finished filling CONTACT INFORMATION step and account created
   * then start pinging
   */
  useEffect(() => {
    if (!isReadyToSetInterval) return;

    makePing(); // first ping without delay
    const intervalId = setInterval(makePing, pingInterval);
    setTimerId(intervalId);
  }, [makePing, isReadyToSetInterval]);

  /**
   * On partner version ping should NOT be runned.
   * Stop it if interval was set before
   */
  useEffect(() => {
    if (isPartner && timerId !== null) {
      stopTimer();
    }
  }, [isPartner, timerId, stopTimer]);

  /**
   * if pinging should be stopped, pause is not enough
   * interval also should be stopped
   */
  useEffect(() => {
    if (!isReadyToPing) {
      stopTimer();
    }
  }, [isReadyToPing, stopTimer]);

  useEffect(() => {
    return () => stopTimer();
  }, [stopTimer]);

  return <>{children}</>;
};
