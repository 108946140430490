import React, { useCallback, useMemo } from 'react';
import { Typography } from '@material-ui/core';
import { Button } from '@chhjpackages/components';
import { useHistory, Redirect } from 'react-router-dom';

import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { SummaryTabs } from 'common/ui/summaryTabs/SummaryTabs';
import { setReadyToProcessing } from 'common/context/form/formActionCreators/formActionCreators';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { ReadyForRequestProcessingEnum } from 'common/context/form/formContext/FormContext.types';
import { useMovingStateContext } from 'bMove/hooks/useMoving/useMoving';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';

import { useStyles } from './MovingStep8.styles';
import { getMovingSummaryTabsData } from './MovingStep8.utils';

export const MovingStep8 = () => {
  const styles = useStyles();
  const history = useHistory();
  const [{ moving }, { movingDispatch }] = useMovingContext();
  const { isCallRequired } = useFormState();
  const { prepaymentData } = useMovingStateContext();
  const dispatch = useFormDispatch();
  const summaryTabsData = useMemo(
    () => getMovingSummaryTabsData({ moving, historyPush: history.push, isCallRequired, prepaymentData }),
    [moving, history, isCallRequired, prepaymentData],
  );

  const handleNextStep = useCallback(() => {
    movingDispatch(movingActions.setStep8({ visited: true }));
    dispatch(setReadyToProcessing({ readyForRequestProcessing: ReadyForRequestProcessingEnum.Moving }));
    history.push(movingRoutes.processing);
  }, [movingDispatch, dispatch, history]);

  if (!moving?.step7 && !isCallRequired) {
    return <Redirect to={movingRoutes.step7} />;
  }
  if (!moving?.step5 && isCallRequired) {
    return <Redirect to={movingRoutes.step5} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.summaryContent}>
        <div className={styles.textContent}>
          <Typography variant="h4" className={styles.title}>
            8. Summary
          </Typography>

          <Typography variant="body1" className={styles.subtitle}>
            Please, review your information below
          </Typography>
        </div>

        <SummaryTabs data={summaryTabsData} />
      </div>

      <ButtonsGroup className={styles.buttonsGroup}>
        <Button onClick={() => history.push(movingRoutes.step7)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>

        <Button onClick={handleNextStep} fullWidth size="large" buttonType="twoTone">
          {isCallRequired ? 'Call me' : ''}
          {!isCallRequired && (prepaymentData?.isPrepaymentAvailable ? 'Pay Deposit' : 'Book Now')}
        </Button>
      </ButtonsGroup>
    </div>
  );
};
