import Cookies from 'js-cookie';

import { VersionContextValue } from './VersionContext.types';

const VERSION_KEY = 'OBT_VERSION';
const EXP_DAYS = 10;

export const versionsDefaultState: VersionContextValue = {
  junkVer: 'A',
  moveVer: 'A',
};

export class VersionStorageClass {
  private _state: VersionContextValue = versionsDefaultState;

  constructor() {
    try {
      const formStateString = Cookies.get(VERSION_KEY);

      if (formStateString) {
        this._state = JSON.parse(formStateString);
      } else {
        this._state = versionsDefaultState;
        Cookies.set(VERSION_KEY, JSON.stringify(versionsDefaultState), { expires: EXP_DAYS });
      }
    } catch {
      this._state = versionsDefaultState;
    }
  }

  get state(): VersionContextValue {
    const formStateString = Cookies.get(VERSION_KEY);

    if (formStateString) {
      this._state = JSON.parse(formStateString);
    }

    return this._state;
  }

  set state(value: VersionContextValue) {
    this._state = value;

    try {
      Cookies.set(VERSION_KEY, JSON.stringify(value), { expires: EXP_DAYS });
    } catch {}
  }
}

export const versionStorage = new VersionStorageClass();
