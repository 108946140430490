import React from 'react';
import { colors } from '@chhjpackages/components';
import { SvgIcon } from '@material-ui/core';

export const MoveLaborIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <path
        d="M5.50261 38.6539L11.0733 43.0942C13.0109 44.6282 15.4329 45.5162 17.9356 45.5162H29.3191C31.7411 45.5162 33.6788 43.5786 33.6788 41.1566C33.6788 40.2685 33.4366 39.5419 33.0329 38.8153H44.2549C44.82 38.8153 45.2237 38.3309 45.2237 37.8465V5.71441C45.2237 5.14928 44.7393 4.74561 44.2549 4.74561H12.0421C11.4769 4.74561 11.0733 5.23001 11.0733 5.71441V26.8667H6.39068C5.82554 26.8667 5.42188 27.3511 5.42188 27.8355"
        fill={colors.primary.light}
      />
      <path
        d="M8.97461 24.6867V2.48486H41.1067V34.617H26.0095"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.873 18.4702H30.1263"
        stroke={colors.secondary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.2012 23.4759L30.2067 18.4703L25.2012 13.4648"
        stroke={colors.secondary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7617 2.48486V7.73257L22.8608 6.60229L25.0406 8.29771L26.9782 6.60229L29.3195 8.05551V2.48486"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 34.6172L8.57064 39.0576C10.3468 40.43 12.5266 41.2374 14.7872 41.2374H26.2514C28.1083 41.2374 29.5615 39.7842 29.5615 37.9273C29.5615 36.0704 28.1083 34.6172 26.2514 34.6172H17.9358"
        stroke={colors.secondary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.32227 24.6865H9.94245C11.8801 24.6865 13.8177 25.2517 15.4324 26.3819L19.2269 28.8847C19.9535 29.2884 20.4379 30.015 20.6801 30.8223C20.8415 31.6296 20.6801 32.5177 20.2764 33.2443C19.3883 34.5361 17.6929 35.0205 16.3204 34.2939L11.3149 31.3067"
        stroke={colors.secondary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
