import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: 702,

    '& > *:not(:last-child)': {
      marginBottom: 16,

      [theme.breakpoints.up('md')]: {
        marginBottom: 24,
      },
    },
  },
}));
