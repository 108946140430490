import React, { useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { movingRoutes } from 'common/routing/AppRoute';
import { ContactInformationForm } from 'common/app/contactInformationForm/ContactInformationForm';
import { ContactInformationFormValues } from 'common/app/contactInformationForm/ContactInformationForm.types';
import { CategoryIdEnum } from 'common/api/types';
import { useMovingContext } from 'aMove/hooks/useMoving/useMoving';
import { movingActions } from 'aMove/context/moving/MovingContextSlice';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { useFormState } from 'common/hooks/useFormState/useFormState';

export const MovingStep5 = () => {
  const history = useHistory();
  const [{ moving }, { movingDispatch }] = useMovingContext();
  const { createOrUpdateAccount, isCreatingOrUpdatingAccount } = useAccount();
  const { user } = useFormState();

  const handleSubmit = useCallback(
    async (values: ContactInformationFormValues, isDirty: boolean) => {
      if (!moving?.step1) return;
      if (!isDirty) {
        history.push(movingRoutes.step6);
        return;
      }

      const account = await createOrUpdateAccount({
        contactInformationData: values,
        billingAddress: moving.step1.fullAddress,
        categoryId: CategoryIdEnum.move,
      });

      if (!account) {
        return;
      }

      movingDispatch(movingActions.setStep5(values));
      history.push(movingRoutes.step6);
    },
    [createOrUpdateAccount, moving?.step1, movingDispatch, history],
  );

  if (!moving?.step3) {
    return <Redirect to={movingRoutes.step3} />;
  }

  return (
    <ContactInformationForm
      loading={isCreatingOrUpdatingAccount}
      onSubmit={handleSubmit}
      title="5. Contact information"
      onBackClick={() => history.push(movingRoutes.step4)}
      defaultValues={{
        firstName: moving?.step5?.firstName || user?.firstName || '',
        lastName: moving?.step5?.lastName || user?.lastName || '',
        email: moving?.step5?.email || user?.email || '',
        phone: moving?.step5?.phone || user?.phone || '',
        companyName: moving?.step5?.companyName || '',
        orderNumber: moving?.step5?.orderNumber || '',
        specialInstructions: moving?.step5?.specialInstructions || '',
        additionalNotes: moving?.step5?.additionalNotes || '',
      }}
      hasPrefilledData={!!moving?.step5}
      tipBoxData={{
        icon: 'threeStars',
        text: (
          <>
            100,000+ 5-Star Reviews <br /> We always strive to deliver 100% client satisfaction with every job.
          </>
        ),
      }}
    />
  );
};
