import React from 'react';
import { Typography, Hidden } from '@material-ui/core';

import { CarouselSlider } from 'common/ui/carouselSlider/CarouselSlider';
import { LoadedImage } from 'common/ui/loadedImage/LoadedImage';
import happyCustomersImg from 'common/assets/images/happyCustomers.jpg';
import { Container } from '../container/Container';

import { useStyles } from './HappyCustomers.styles';

const sliderData = [
  {
    text:
      'The HUNKS were so helpful ensuring everything was packed well and secured for the trip to Arkansas. It was so helpful having the furniture unpacked and put into place, sometimes twice when I changed my mind and wanted something in a different place or room. Thank u for your diligence!',
    author: 'Mark L.',
    info: 'Colorado Springs, CO',
  },
  {
    text:
      "Wished we'd done it sooner! Everyone was fantastic, did their jobs and did them well. Very helpful. Not a thing to complain about. Thanks for the perfect service.",
    author: 'Pamela D.',
    info: 'South Haven, MI',
  },
  {
    text: 'The team was flexible when needed and effective in getting the job done. They were very professional.',
    author: 'Jakki B.',
    info: 'Avondale, PA',
  },
  {
    text:
      "The whole team was EXTREMELY courteous, professional and efficient! Our family has moved multiple times and this was THE BEST moving experience we've ever had.",
    author: 'Andre B.',
    info: 'Warrington, PA',
  },
  {
    text:
      "Your guys really helped us out! Got us completely out of our large 3500 sq ft in one day. It was a huge job and they did it with a smile! Can't thank them enough!",
    author: 'Randee B.',
    info: 'Belfair, WA',
  },
];

export const HappyCustomers = () => {
  const styles = useStyles();

  return (
    <div className={styles.happyCustomerContainer}>
      <Container className={styles.happyCustomerInnerWrapper}>
        <Hidden smDown>
          <div>
            <LoadedImage src={happyCustomersImg} alt="Happy Customers" />
          </div>
        </Hidden>

        <div className={styles.wrapper}>
          <Typography variant="h3" className={styles.title}>
            Almost <span>100,000</span> happy customers
          </Typography>

          <div className={styles.innerWrapper}>
            <CarouselSlider settings={{ speed: 400 }}>
              {sliderData.map(({ text, author, info }, index) => (
                <div key={index} className={styles.sliderItem}>
                  <div className={styles.card}>
                    <Typography variant="h2" color="secondary" className={styles.quote}>
                      “
                    </Typography>
                    <Typography className={styles.text}>{text}</Typography>
                    <Typography color="secondary" variant="caption" className={styles.author}>
                      {author}
                    </Typography>
                    <Typography variant="caption">{info}</Typography>
                  </div>
                </div>
              ))}
            </CarouselSlider>
          </div>
        </div>
      </Container>
    </div>
  );
};
