import React from 'react';
import { colors } from '@chhjpackages/components';
import { SvgIcon } from '@material-ui/core';

export const ElevatorIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="44" height="48" viewBox="0 0 44 48" fill="none">
      <path
        d="M42.2209 48.0001H4.39785C3.4952 48.0001 2.76953 47.2744 2.76953 46.3718V4.32754C2.76953 3.42488 3.4952 2.69922 4.39785 2.69922H42.2209C43.1235 2.69922 43.8492 3.42488 43.8492 4.32754V46.3718C43.8492 47.2744 43.1235 48.0001 42.2209 48.0001Z"
        fill={colors.primary.light}
      />
      <path
        d="M4.1852 26.2825C4.55176 26.2825 4.84892 25.9854 4.84892 25.6188C4.84892 25.2522 4.55176 24.9551 4.1852 24.9551C3.81864 24.9551 3.52148 25.2522 3.52148 25.6188C3.52148 25.9854 3.81864 26.2825 4.1852 26.2825Z"
        fill={colors.secondary.main}
      />
      <path
        d="M4.1852 30.0345C4.55176 30.0345 4.84892 29.7373 4.84892 29.3708C4.84892 29.0042 4.55176 28.707 4.1852 28.707C3.81864 28.707 3.52148 29.0042 3.52148 29.3708C3.52148 29.7373 3.81864 30.0345 4.1852 30.0345Z"
        fill={colors.secondary.main}
      />
      <path
        d="M28.4336 11.6637H32.354C32.5133 11.6637 32.646 11.7965 32.646 11.9558V43.9823H21.2035V11.6637H25.7788H28.4336ZM0 12.5664V43.6726C0 44.5752 0.734513 45.3097 1.63717 45.3097H39.4425C40.3451 45.3097 41.0796 44.5752 41.0796 43.6726V1.63717C41.0796 0.734513 40.3451 0 39.4425 0H1.63717C0.734513 0 0 0.734513 0 1.63717V9.9115V12.5664ZM1.32743 9.9115V1.63717C1.32743 1.46903 1.46903 1.32743 1.63717 1.32743H39.4425C39.6106 1.32743 39.7522 1.46903 39.7522 1.63717V43.6726C39.7522 43.8407 39.6106 43.9823 39.4425 43.9823H33.9734V11.9558C33.9734 11.0619 33.2478 10.3363 32.354 10.3363H28.4336H25.7788H8.72566C7.83186 10.3363 7.10619 11.0619 7.10619 11.9558V22.2478H1.32743V12.5664V9.9115ZM7.10619 23.5752V32.2655H1.32743V23.5752H7.10619ZM1.32743 43.6726V33.5929H7.10619V43.9823H1.63717C1.46903 43.9823 1.32743 43.8407 1.32743 43.6726ZM8.43363 11.9558C8.43363 11.7965 8.56637 11.6637 8.72566 11.6637H19.8761V43.9823H8.43363V11.9558Z"
        fill={colors.primary.main}
      />
      <path
        d="M15.3004 8.47802H19.7606C19.9996 8.47802 20.2208 8.35412 20.3358 8.14173C20.3978 8.03554 20.4243 7.92049 20.4243 7.80545C20.4243 7.6904 20.3978 7.57536 20.3358 7.46917L18.1057 3.60191C17.9907 3.39837 17.7695 3.26562 17.5305 3.26562C17.2916 3.26562 17.0703 3.38952 16.9553 3.60191L14.7252 7.46917C14.6633 7.57536 14.6367 7.6904 14.6367 7.80545C14.6367 7.92049 14.6633 8.03554 14.7252 8.14173C14.8403 8.35412 15.0615 8.47802 15.3004 8.47802ZM17.5305 5.27447L18.6102 7.15058H16.442L17.5305 5.27447Z"
        fill={colors.secondary.main}
      />
      <path
        d="M23.5481 8.47802C23.787 8.47802 24.0083 8.35412 24.1233 8.14173L26.3534 4.27447C26.4154 4.16828 26.4419 4.05324 26.4419 3.93819C26.4419 3.82315 26.4154 3.7081 26.3534 3.60191C26.2384 3.39837 26.0171 3.26562 25.7782 3.26562H21.318C21.0791 3.26562 20.8578 3.38952 20.7428 3.60191C20.6808 3.7081 20.6543 3.82315 20.6543 3.93819C20.6543 4.05324 20.6808 4.16828 20.7428 4.27447L22.9729 8.14173C23.0968 8.35412 23.3092 8.47802 23.5481 8.47802ZM24.6277 4.61076L23.5481 6.48686L22.4685 4.61076"
        fill={colors.secondary.main}
      />
      <path
        d="M23.248 25.6188V28.5126C23.248 28.8754 23.5489 29.1763 23.9118 29.1763C24.2746 29.1763 24.5755 28.8754 24.5755 28.5126V25.6188C24.5755 25.256 24.2746 24.9551 23.9118 24.9551C23.5489 24.9551 23.248 25.2471 23.248 25.6188Z"
        fill={colors.secondary.main}
      />
      <path
        d="M17.1676 24.9551C16.8048 24.9551 16.5039 25.256 16.5039 25.6188V28.5126C16.5039 28.8754 16.8048 29.1763 17.1676 29.1763C17.5305 29.1763 17.8313 28.8754 17.8313 28.5126V25.6188C17.8313 25.2471 17.5305 24.9551 17.1676 24.9551Z"
        fill={colors.secondary.main}
      />
    </SvgIcon>
  );
};
