import React, { HTMLAttributes } from 'react';
import clsx from 'clsx';

import { useStyles } from './Container.styles';

export const Container = ({ children, className, ...restProps }: HTMLAttributes<HTMLDivElement>) => {
  const styles = useStyles();

  return (
    <div {...restProps} className={clsx([className, styles.container])}>
      {children}
    </div>
  );
};
