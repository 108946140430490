import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 596, // 2x button width + desktop break between them

    '& > *': {
      maxWidth: 274,

      '&:nth-child(1)': {
        flexBasis: 'calc(50% - 9px)', // -9px cause right button twoTone is 8px+1px(border) wider
      },

      '&:nth-child(2)': {
        flexBasis: 'calc(50%)',
        marginRight: -8,

        '& .MuiButton-label div': {
          padding: 11,
        },
      },
    },
  },
});
