import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ThreeStarsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="48" height="49" viewBox="0 0 48 49" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.5374 8.50005L29.0374 13.6C29.1374 13.9 29.4374 14 29.7374 14.1L35.3374 14.9C36.0374 15 36.3374 15.9 35.8374 16.4L31.8374 20.3C31.6374 20.5 31.5374 20.8 31.5374 21.1L32.5374 26.7001C32.6374 27.4001 31.9374 28 31.2374 27.6L26.2374 25C25.9374 24.9 25.6374 24.9 25.4374 25L20.4374 27.6C19.8374 27.9 19.0374 27.4001 19.1374 26.7001L20.1374 21.1C20.1374 20.8 20.1374 20.5 19.8374 20.3L15.8374 16.4C15.3374 15.9 15.6374 15 16.3374 14.9L21.9374 14.1C22.2374 14.1 22.4374 13.9 22.6374 13.6L25.1374 8.50005C25.2374 7.90005 26.2374 7.90005 26.5374 8.50005Z"
        fill="#F0CBA7"
      />
      <path
        d="M17.9374 26.3C17.6374 26.3 17.3374 26.2 17.0374 26C16.5374 25.7 16.3374 25.1 16.4374 24.5L17.4374 18.9C17.4374 18.8 17.4374 18.7 17.3374 18.7L13.3374 14.8C12.8374 14.4 12.7374 13.8 12.9374 13.3C13.1374 12.8 13.5374 12.4 14.1374 12.3L19.7374 11.5C19.8374 11.5 19.8374 11.4 19.9374 11.4L22.4374 6.3C22.7374 5.8 23.2374 5.5 23.7374 5.5C24.3374 5.5 24.8374 5.8 25.0374 6.3L27.5374 11.4C27.5374 11.5 27.6374 11.5 27.7374 11.5L33.3374 12.3C33.9374 12.4 34.3374 12.8 34.5374 13.3C34.7374 13.8 34.5374 14.4 34.1374 14.8L30.1374 18.7C30.0374 18.8 30.0374 18.8 30.0374 18.9L31.0374 24.5C31.1374 25.1 30.9374 25.6 30.4374 26C29.9374 26.4 29.3374 26.4 28.8374 26.1L23.8374 23.5C23.7374 23.5 23.6374 23.5 23.6374 23.5L18.6374 26.1C18.4374 26.2 18.1374 26.3 17.9374 26.3ZM23.7374 6.7C23.6374 6.7 23.5374 6.7 23.5374 6.8L21.0374 11.9C20.8374 12.3 20.4374 12.6 19.9374 12.7L14.3374 13.5C14.1374 13.5 14.1374 13.6 14.1374 13.7C14.1374 13.8 14.1374 13.9 14.2374 14L18.2374 17.9C18.6374 18.2 18.7374 18.7 18.6374 19.2L17.6374 24.8C17.6374 24.9 17.7374 25 17.7374 25C17.7374 25 17.8374 25.1 18.0374 25L23.0374 22.4C23.4374 22.2 24.0374 22.2 24.4374 22.4L29.4374 25C29.5374 25.1 29.6374 25 29.7374 25C29.7374 25 29.8374 24.9 29.8374 24.8L28.8374 19.2C28.7374 18.7 28.9374 18.2 29.2374 17.9L33.2374 14C33.3374 13.9 33.3374 13.8 33.3374 13.7C33.3374 13.6 33.2374 13.6 33.1374 13.5L27.5374 12.7C27.0374 12.6 26.6374 12.3 26.4374 11.9L23.9374 6.8C23.9374 6.7 23.7374 6.7 23.7374 6.7Z"
        fill="#EA7200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.6376 30.8L39.2376 34.1C39.3376 34.3 39.4376 34.4 39.6376 34.4L43.2376 34.9C43.7376 35 43.8376 35.5 43.5376 35.9L40.9376 38.5C40.8376 38.6 40.7376 38.8 40.7376 39L41.3376 42.6C41.4376 43.1 40.9376 43.4 40.5376 43.2L37.3376 41.5C37.1376 41.4 36.9376 41.4 36.8376 41.5L33.6376 43.2C33.2376 43.4 32.7376 43.1 32.8376 42.6L33.4376 39C33.4376 38.8 33.4376 38.6 33.2376 38.5L30.6376 35.9C30.3376 35.6 30.5376 35 30.9376 34.9L34.5376 34.4C34.7376 34.4 34.8376 34.3 34.9376 34.1L36.5376 30.8C36.8376 30.4 37.4376 30.4 37.6376 30.8Z"
        fill="#F0CBA7"
      />
      <path
        d="M31.3375 41.8C31.1375 41.8 30.8375 41.7 30.6375 41.6C30.2375 41.3 30.1375 40.9 30.1375 40.4L30.7375 36.8L28.1375 34.3C27.8375 34 27.7375 33.5 27.8375 33.1C27.9375 32.7 28.3375 32.4 28.8375 32.3L32.4375 31.8L34.0375 28.6C34.2375 28.2 34.6375 27.9 35.1375 27.9C35.6375 27.9 36.0375 28.2 36.2375 28.6L37.8375 31.9L41.4375 32.4C41.9375 32.5 42.2375 32.8 42.4375 33.2C42.5375 33.6 42.4375 34.1 42.1375 34.4L39.5375 37L40.1375 40.5C40.2375 40.9 40.0375 41.4 39.6375 41.7C39.2375 42 38.8375 42 38.3375 41.8L35.1375 40.1L31.9375 41.6C31.7375 41.7 31.5375 41.8 31.3375 41.8ZM35.1375 38.7C35.3375 38.7 35.5375 38.7 35.7375 38.8L38.8375 40.4L38.2376 36.9C38.1376 36.5 38.3376 36.1 38.5375 35.8L41.0375 33.3L37.5375 32.8C37.1376 32.7 36.8375 32.5 36.6375 32.2L35.0375 29L33.5375 32.2C33.3375 32.6 33.0375 32.8 32.6375 32.9L29.1375 33.4L31.6375 35.9C31.9375 36.2 32.0375 36.6 31.9375 37L31.3375 40.5L34.4375 38.9C34.7375 38.7 34.9375 38.7 35.1375 38.7Z"
        fill="#EA7200"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8376 30.8L16.4376 34.1C16.5376 34.3 16.6376 34.4 16.8376 34.4L20.4376 34.9C20.9376 35 21.0376 35.5 20.7376 35.9L18.1376 38.5C18.0376 38.6 17.9376 38.8 17.9376 39L18.5376 42.6C18.6376 43.1 18.1376 43.4 17.7376 43.2L14.5376 41.5C14.3376 41.4 14.1376 41.4 14.0376 41.5L10.8376 43.2C10.4376 43.4 9.93756 43.1 10.0376 42.6L10.6376 39C10.6376 38.8 10.6376 38.6 10.4376 38.5L7.83756 35.9C7.53756 35.6 7.73756 35 8.13756 34.9L11.7376 34.4C11.9376 34.4 12.0376 34.3 12.1376 34.1L13.7376 30.8C14.0376 30.4 14.6376 30.4 14.8376 30.8Z"
        fill="#F0CBA7"
      />
      <path
        d="M8.5375 41.8C8.3375 41.8 8.0375 41.7 7.8375 41.6C7.4375 41.3 7.2375 40.9 7.3375 40.4L7.9375 36.8L5.3375 34.3C5.0375 34 4.9375 33.5 5.0375 33.1C5.1375 32.7 5.5375 32.4 6.0375 32.3L9.6375 31.8L11.2375 28.6C11.4375 28.2 11.8375 27.9 12.3375 27.9C12.8375 27.9 13.2375 28.2 13.4375 28.6L15.0375 31.9L18.6375 32.4C19.1375 32.5 19.4375 32.8 19.6375 33.2C19.7375 33.6 19.6375 34.1 19.3375 34.4L16.7375 37L17.3375 40.5C17.4375 40.9 17.2375 41.4 16.8375 41.7C16.4375 42 16.0375 42 15.5375 41.8L12.3375 40.1L9.1375 41.6C8.9375 41.7 8.7375 41.8 8.5375 41.8ZM12.3375 38.7C12.5375 38.7 12.7375 38.7 12.9375 38.8L16.0375 40.4L15.4375 36.9C15.3375 36.5 15.5375 36.1 15.7375 35.8L18.2375 33.3L14.7375 33C14.3375 32.9 14.0375 32.7 13.8375 32.3L12.2375 29.1L10.7375 32.3C10.5375 32.7 10.2375 32.9 9.8375 33L6.3375 33.5L8.8375 36C9.1375 36.3 9.2375 36.7 9.1375 37.1L8.5375 40.6L11.7375 38.8C11.9375 38.7 12.1375 38.7 12.3375 38.7Z"
        fill="#EA7200"
      />
    </SvgIcon>
  );
};
