import React, { ReactNode, useReducer, useMemo, useEffect } from 'react';

import { MovingDispatchContext, MovingStateContext } from './MovingContext';
import { MovingStateContextValue, MovingDispatchContextValue } from './MovingContext.types';
import { movingReducer } from './MovingContextSlice';
import { movingStorage } from './MovingContextStorage';

export const MovingContextController = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(movingReducer, movingStorage.state);

  useEffect(() => {
    movingStorage.state = state;
  }, [state]);

  useEffect(() => {
    return () => movingStorage.clear();
  }, []);

  const stateValue = useMemo<MovingStateContextValue>(() => state, [state]);
  const dispatchValue = useMemo<MovingDispatchContextValue>(
    () => ({
      movingDispatch: dispatch,
    }),
    [dispatch],
  );

  return (
    <MovingStateContext.Provider value={stateValue}>
      <MovingDispatchContext.Provider value={dispatchValue}>{children}</MovingDispatchContext.Provider>
    </MovingStateContext.Provider>
  );
};
