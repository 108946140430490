import React, { useMemo } from 'react';
import { Typography } from '@material-ui/core';
import clsx from 'clsx';

import { EcoFriendlyIcon } from 'common/assets/icons/EcoFriendlyIcon';
import { ThreeStarsIcon } from 'common/assets/icons/ThreeStarsIcon';
import { RecyclingIcon } from 'common/assets/icons/RecyclingIcon';
import { HIcon } from 'common/assets/icons/HIcon';
import { DiamondIcon } from 'common/assets/icons/DiamondIcon';
import { MuscleIcon } from 'common/assets/icons/MuscleIcon';
import { LaborIcon } from 'common/assets/icons/LaborIcon';
import { MoveLaborIcon } from 'common/assets/icons/MoveLaborIcon';
import { JunkRemovalIcon } from 'common/assets/icons/JunkRemovalIcon';

import { TipBoxProps } from './TipBox.types';
import { useStyles } from './TipBox.styles';

const iconList = {
  ecoFriendly: EcoFriendlyIcon,
  recycling: RecyclingIcon,
  threeStars: ThreeStarsIcon,
  h: HIcon,
  diamond: DiamondIcon,
  muscle: MuscleIcon,
  labor: LaborIcon,
  moveLabor: MoveLaborIcon,
  junkRemoval: JunkRemovalIcon,
};

export const TipBox = ({ iconName, className, fullWidth, children }: TipBoxProps) => {
  const styles = useStyles();

  const Icon = useMemo(() => iconList[iconName], [iconName]);

  return (
    <div className={clsx([className, fullWidth && styles.fullWidth, styles.wrapper])}>
      <Icon className={styles.icon} />
      <Typography variant="caption">{children}</Typography>
    </div>
  );
};
