import { Action, BasicResponseStructure } from 'common/api/types';

import { CreatePaymentPayload } from './paymentActions.types';

export const createNewPayment = (values: CreatePaymentPayload): Action<BasicResponseStructure> => {
  const { locationId, accountId, appointmentId, token, ...restValues } = values;

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/accounts/${accountId}/appointments/${appointmentId}/payments/square?token=${token}`,
    body: { ...restValues },
    config: { disableInterceptErrors: true },
  };
};
