import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    position: 'relative',
    '& .MuiDialog-paper': {
      width: '100vw',
      margin: 0,
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        maxWidth: 488,
      },
    },
  },
  content: {
    padding: '8px 16px 16px',
  },
  input: {
    '& input': {
      textTransform: 'uppercase',
    },
  },
  inputError: {
    margin: '4px 0 6px',
    lineHeight: '14px',
    fontSize: 14,
    height: 14,
  },
  headerAndCloseWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  closeButton: {
    marginRight: -8,
    padding: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    cursor: 'pointer',

    '& svg': {
      fontSize: 16,
    },
  },
  buttonsGroup: {
    justifyContent: 'flex-end',

    '& > *:nth-child(1)': {
      maxWidth: 80,
      marginRight: 8,
    },

    '& > *:nth-child(2)': {
      maxWidth: 144,
    },
  },
}));
