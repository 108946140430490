import React, { useCallback, useEffect, useState } from 'react';
import { Box, FormControl, FormHelperText, Typography } from '@material-ui/core';
import { Button, Input } from '@chhjpackages/components';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-fetching-library';

import { TipBox } from 'common/ui/tipBox/TipBox';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { AddressAutocomplete } from 'common/ui/addressAutocomplete/AddressAutocomplete';
import { validateAddress } from 'common/utils/validations';
import { GoogleLocation } from 'common/ui/addressAutocomplete/AddressAutocomplete.types';
import { GoogleMaps } from 'common/ui/googleMaps/GoogleMaps';
import { BillingAddress } from 'common/api/actions/account/accountActions.types';
import { setGlobalData } from 'common/context/form/formActionCreators/formActionCreators';
import { CategoryIdEnum } from 'common/api/types';
import { checkPostalCodeAction } from 'common/api/actions/booking/bookingActions';
import { findNearestLocationId } from 'common/utils/findNearestLocationId';
import { AppRoute, laborRoutes } from 'common/routing/AppRoute';
import { useLaborContext } from 'common/hooks/useLabor/useLabor';
import { laborActions } from 'common/context/labor/LaborContextContextSlice';
import { useResetState } from 'common/hooks/useResetState/useResetState';
import { useAccount } from 'common/hooks/useAccount/useAccount';

import { useStyles } from './LaborStep1.styles';

export const LaborStep1 = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useFormDispatch();
  const resetState = useResetState();
  const { mutate, loading } = useMutation(checkPostalCodeAction);
  const { recreateAccountWithNewLocationId, isRecreatingAccount } = useAccount();
  const [{ labor }, { laborDispatch }] = useLaborContext();
  const [googleMapsLocation, setGoogleMapsLocation] = useState<GoogleLocation | undefined>(
    labor?.step1?.googleMapsLocation,
  );
  const { handleSubmit, register, errors, control, formState, watch, setValue } = useForm({
    mode: 'onBlur',
    defaultValues: {
      fullAddress: labor?.step1?.fullAddress || null,
      address: labor?.step1?.address || '',
      apartment: labor?.step1?.fullAddress.address2 || '',
    },
  });
  const { isDirty, isValid } = formState;

  useEffect(() => {
    register('address'); //field without physical input
  }, [register]);

  const shortAddress = watch('address');

  const handleSubmitCallback = useCallback(
    async ({
      fullAddress,
      address,
      apartment,
    }: {
      fullAddress: BillingAddress;
      address: string;
      apartment: string;
    }) => {
      if (!isDirty) history.push(laborRoutes.step2);

      const { payload } = await mutate({
        postal: fullAddress.postal,
        job_category_id: CategoryIdEnum.labor,
      });
      // if (payload?.meta.errors?.length) {
      //   resetState();
      //   history.push(laborRoutes.unserviced);
      //   return;
      // }

      if (payload?.locations?.length) {
        const addressForApi = {
          ...fullAddress,
          address2: apartment,
        };

        const nearestLocationId = findNearestLocationId(payload.locations);
        const zoneId = payload.locations[0].zone.id;

        if (labor?.step2) {
          await recreateAccountWithNewLocationId(
            {
              contactInformationData: labor.step2,
              billingAddress: addressForApi,
              categoryId: CategoryIdEnum.labor,
            },
            nearestLocationId,
          );
        }

        if (googleMapsLocation) {
          laborDispatch(
            laborActions.setStep1({
              address,
              googleMapsLocation,
              fullAddress: addressForApi,
            }),
          );

          dispatch(setGlobalData({ locationId: nearestLocationId, zoneId }));

          history.push(laborRoutes.step2);
        }
      } else {
        resetState();
        history.push(laborRoutes.unserviced);
        return;
      }
    },
    [
      googleMapsLocation,
      dispatch,
      resetState,
      history,
      laborDispatch,
      mutate,
      isDirty,
      recreateAccountWithNewLocationId,
      labor?.step2,
    ],
  );

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className={styles.form}>
      <div className={styles.content}>
        <Typography variant="h4">1. Your Location</Typography>

        <Typography variant="body1">Let's make sure we can help you. Where are you located?</Typography>

        <FormControl fullWidth error={!!errors.fullAddress}>
          <Controller
            control={control}
            rules={{
              validate: (fullAddr) => {
                const addressError = validateAddress(fullAddr);
                return addressError || undefined;
              },
            }}
            name="fullAddress"
            render={({ onChange, onBlur }) => {
              return (
                <AddressAutocomplete
                  label="Address"
                  initialValue={shortAddress}
                  onSelect={(values) => {
                    onChange(values?.fullAddress || null);
                    setValue('address', values?.inputValue || '');
                    if (values?.location) setGoogleMapsLocation(values?.location);
                    onBlur();
                  }}
                  hasError={!!errors?.fullAddress}
                />
              );
            }}
          />
          <FormHelperText className={styles.inputError}>{errors.fullAddress?.message || '*required'}</FormHelperText>
        </FormControl>

        <FormControl fullWidth error={!!errors.apartment}>
          <Input
            name="apartment"
            label="Apartment/suite"
            size="medium"
            variant="outlined"
            error={!!errors.apartment}
            inputRef={register}
          />
          <FormHelperText className={styles.inputError}>{errors.apartment?.message}</FormHelperText>
        </FormControl>

        {googleMapsLocation && <GoogleMaps location={googleMapsLocation} />}
        <Box mt={2} />

        <TipBox iconName="ecoFriendly" className={styles.tipBox}>
          We’ll do everything we can to give your items a second life. We donate or recycle <b>up to 70%</b> of the
          items we remove.
        </TipBox>
      </div>

      <Box mt={2} />
      <ButtonsGroup>
        <Button onClick={() => history.push(AppRoute.home)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>
        <Button
          type="submit"
          disabled={!isValid}
          isLoading={loading || isRecreatingAccount}
          fullWidth
          size="large"
          buttonType="twoTone"
        >
          Next
        </Button>
      </ButtonsGroup>
    </form>
  );
};
