import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';

import { USADateFormat } from 'common/utils/dateTime';

import {
  SetPricingValues,
  SetMovingStep1,
  SetMovingStep2,
  SetMovingStep3,
  SetMovingStep4,
  SetMovingStep5,
  SetMovingStep6,
  SetMovingStep7,
  SetMovingStep8,
  ResetStep,
} from './MovingContext.types';
import { movingDefaultState, movingStorage } from './MovingContextStorage';

export const { actions: movingActions, reducer: movingReducer } = createSlice({
  name: 'movingSlice',
  initialState: movingDefaultState,
  reducers: {
    setPricingValues: (state, { payload }: PayloadAction<SetPricingValues>) => {
      state.pricingTableData = {
        numberOfHunks: payload.quote.hunks,
        truckAndTravel: payload.quote.truck_and_travel,
        hourlyRate: payload.quote.hourly_rate,
        time: payload.quote.labor_hours,
        totalPrice: payload.quote.total_price,
      };

      state.jobDuration = payload.quote.labor_hours.high;
    },
    setPrepaymentData: (state, { payload: { quote } }: PayloadAction<SetPricingValues>) => {
      const isPrepaymentDisabled =
        quote.pre_payment.collect_online <= 0 ||
        quote.pre_payment.amount <= 0 ||
        quote.pre_payment.square_location_id === '';

      const isRefundDateInPast = moment(quote.pre_payment.refundable_by, USADateFormat).isBefore(new Date());
      const isRefundable = quote.pre_payment.refundable || !isRefundDateInPast;

      state.prepaymentData = {
        isPrepaymentAvailable: !isPrepaymentDisabled,
        prepaymentRefundable: isRefundable,
        amount: quote.pre_payment.amount,
        refundDate: quote.pre_payment.refundable_by,
        squareLocationId: quote.pre_payment.square_location_id,
      };
    },
    setStep1: (state, { payload }: PayloadAction<SetMovingStep1>) => {
      state.moving.step1 = payload;
    },
    setStep2: (state, { payload }: PayloadAction<SetMovingStep2>) => {
      state.moving.step2 = payload;
    },
    setStep3: (state, { payload }: PayloadAction<SetMovingStep3>) => {
      state.moving.step3 = payload;
    },
    setStep4: (state, { payload }: PayloadAction<SetMovingStep4>) => {
      state.moving.step4 = payload;
    },
    setStep5: (state, { payload }: PayloadAction<SetMovingStep5>) => {
      state.moving.step5 = payload;
    },
    setStep6: (state, { payload }: PayloadAction<SetMovingStep6>) => {
      state.moving.step6 = payload;
    },
    setStep7: (state, { payload }: PayloadAction<SetMovingStep7>) => {
      state.moving.step7 = payload;
    },
    setStep8: (state, { payload }: PayloadAction<SetMovingStep8>) => {
      state.moving.step8 = payload;
    },
    resetStep: (state, { payload }: PayloadAction<ResetStep>) => {
      delete state.moving[payload.step];
    },
    reset: () => {
      movingStorage.clear();
      return movingDefaultState;
    },
  },
});
