import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CalendarIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="80" height="80" viewBox="0 0 80 80" fill="none">
      <path
        d="M67.6492 25.7076H14.7794V19.7503C14.7794 17.3674 16.708 15.4388 19.0909 15.4388H63.3377C65.7206 15.4388 67.6492 17.3674 67.6492 19.7503V25.7076Z"
        fill="#F1CCA5"
      />
      <path
        d="M63.3377 71H16.6224C13.9137 71 11.7108 68.7971 11.7108 66.0885V17.8388C11.7108 15.1302 13.9137 12.9273 16.6224 12.9273H63.3377C66.0463 12.9273 68.2492 15.1302 68.2492 17.8388V66.0885C68.2492 68.7971 66.0463 71 63.3377 71ZM16.6224 14.1273C14.5737 14.1273 12.9108 15.7902 12.9108 17.8388V66.0885C12.9108 68.1371 14.5737 69.8 16.6224 69.8H63.3377C65.3863 69.8 67.0492 68.1371 67.0492 66.0885V17.8388C67.0492 15.7902 65.3863 14.1273 63.3377 14.1273H16.6224Z"
        fill="#EB7200"
      />
      <path d="M67.6492 25.1075H12.3108V26.3076H67.6492V25.1075Z" fill="#EB7200" />
      <path
        d="M25.8025 42.7393H21.5595C20.6167 42.7393 19.8452 41.9679 19.8452 41.025V36.7821C19.8452 35.8392 20.6167 35.0677 21.5595 35.0677H25.8025C26.7454 35.0677 27.5168 35.8392 27.5168 36.7821V41.025C27.5168 41.9679 26.7539 42.7393 25.8025 42.7393Z"
        fill="#F1CCA5"
      />
      <path
        d="M37.0314 42.7393H32.7884C31.8455 42.7393 31.0741 41.9679 31.0741 41.025V36.7821C31.0741 35.8392 31.8455 35.0677 32.7884 35.0677H37.0314C37.9743 35.0677 38.7457 35.8392 38.7457 36.7821V41.025C38.7457 41.9679 37.9743 42.7393 37.0314 42.7393Z"
        fill="#F1CCA5"
      />
      <path
        d="M48.2516 42.7393H44.0086C43.0658 42.7393 42.2943 41.9679 42.2943 41.025V36.7821C42.2943 35.8392 43.0658 35.0677 44.0086 35.0677H48.2516C49.1945 35.0677 49.9659 35.8392 49.9659 36.7821V41.025C49.9659 41.9679 49.203 42.7393 48.2516 42.7393Z"
        fill="#F1CCA5"
      />
      <path
        d="M59.4805 42.7393H55.2375C54.2946 42.7393 53.5232 41.9679 53.5232 41.025V36.7821C53.5232 35.8392 54.2946 35.0677 55.2375 35.0677H59.4805C60.4233 35.0677 61.1948 35.8392 61.1948 36.7821V41.025C61.1948 41.9679 60.4233 42.7393 59.4805 42.7393Z"
        fill="#F1CCA5"
      />
      <path
        d="M25.8025 53.9682H21.5595C20.6167 53.9682 19.8452 53.1967 19.8452 52.2538V48.0109C19.8452 47.068 20.6167 46.2966 21.5595 46.2966H25.8025C26.7454 46.2966 27.5168 47.068 27.5168 48.0109V52.2538C27.5168 53.1967 26.7539 53.9682 25.8025 53.9682Z"
        fill="#F1CCA5"
      />
      <path
        d="M37.0314 53.9682H32.7884C31.8455 53.9682 31.0741 53.1967 31.0741 52.2538V48.0109C31.0741 47.068 31.8455 46.2966 32.7884 46.2966H37.0314C37.9743 46.2966 38.7457 47.068 38.7457 48.0109V52.2538C38.7457 53.1967 37.9743 53.9682 37.0314 53.9682Z"
        fill="#F1CCA5"
      />
      <path
        d="M48.2516 53.9682H44.0086C43.0658 53.9682 42.2943 53.1967 42.2943 52.2538V48.0109C42.2943 47.068 43.0658 46.2966 44.0086 46.2966H48.2516C49.1945 46.2966 49.9659 47.068 49.9659 48.0109V52.2538C49.9659 53.1967 49.203 53.9682 48.2516 53.9682Z"
        fill="#F1CCA5"
      />
      <path
        d="M59.4805 53.9682H55.2375C54.2946 53.9682 53.5232 53.1967 53.5232 52.2538V48.0109C53.5232 47.068 54.2946 46.2966 55.2375 46.2966H59.4805C60.4233 46.2966 61.1948 47.068 61.1948 48.0109V52.2538C61.1948 53.1967 60.4233 53.9682 59.4805 53.9682Z"
        fill="#F1CCA5"
      />
      <path
        d="M25.8025 65.1884H21.5595C20.6167 65.1884 19.8452 64.417 19.8452 63.4741V59.2312C19.8452 58.2883 20.6167 57.5168 21.5595 57.5168H25.8025C26.7454 57.5168 27.5168 58.2883 27.5168 59.2312V63.4741C27.5168 64.4256 26.7539 65.1884 25.8025 65.1884Z"
        fill="#F1CCA5"
      />
      <path
        d="M37.0314 65.1884H32.7884C31.8455 65.1884 31.0741 64.417 31.0741 63.4741V59.2312C31.0741 58.2883 31.8455 57.5168 32.7884 57.5168H37.0314C37.9743 57.5168 38.7457 58.2883 38.7457 59.2312V63.4741C38.7457 64.4256 37.9743 65.1884 37.0314 65.1884Z"
        fill="#F1CCA5"
      />
      <path
        d="M48.2516 65.1884H44.0086C43.0658 65.1884 42.2943 64.417 42.2943 63.4741V59.2312C42.2943 58.2883 43.0658 57.5168 44.0086 57.5168H48.2516C49.1945 57.5168 49.9659 58.2883 49.9659 59.2312V63.4741C49.9659 64.4256 49.203 65.1884 48.2516 65.1884Z"
        fill="#F1CCA5"
      />
      <path
        d="M59.4805 65.1884H55.2375C54.2946 65.1884 53.5232 64.417 53.5232 63.4741V59.2312C53.5232 58.2883 54.2946 57.5168 55.2375 57.5168H59.4805C60.4233 57.5168 61.1948 58.2883 61.1948 59.2312V63.4741C61.1948 64.4256 60.4233 65.1884 59.4805 65.1884Z"
        fill="#F1CCA5"
      />
      <path
        d="M24.3711 42.1393H20.1282C18.851 42.1393 17.8138 41.1022 17.8138 39.825V35.582C17.8138 34.3049 18.851 33.2677 20.1282 33.2677H24.3711C25.6483 33.2677 26.6855 34.3049 26.6855 35.582V39.825C26.6855 41.1022 25.6483 42.1393 24.3711 42.1393ZM20.1282 34.4677C19.511 34.4677 19.0139 34.9649 19.0139 35.582V39.825C19.0139 40.4421 19.511 40.9393 20.1282 40.9393H24.3711C24.9883 40.9393 25.4854 40.4421 25.4854 39.825V35.582C25.4854 34.9649 24.9883 34.4677 24.3711 34.4677H20.1282Z"
        fill="#EB7200"
      />
      <path
        d="M35.5999 42.1393H31.3569C30.0798 42.1393 29.0426 41.1022 29.0426 39.825V35.582C29.0426 34.3049 30.0798 33.2677 31.3569 33.2677H35.5999C36.8771 33.2677 37.9142 34.3049 37.9142 35.582V39.825C37.9142 41.1022 36.8685 42.1393 35.5999 42.1393ZM31.3569 34.4677C30.7398 34.4677 30.2426 34.9649 30.2426 35.582V39.825C30.2426 40.4421 30.7398 40.9393 31.3569 40.9393H35.5999C36.2171 40.9393 36.7142 40.4421 36.7142 39.825V35.582C36.7142 34.9649 36.2171 34.4677 35.5999 34.4677H31.3569Z"
        fill="#EB7200"
      />
      <path
        d="M46.8202 42.1393H42.5773C41.3001 42.1393 40.2629 41.1022 40.2629 39.825V35.582C40.2629 34.3049 41.3001 33.2677 42.5773 33.2677H46.8202C48.0974 33.2677 49.1346 34.3049 49.1346 35.582V39.825C49.1346 41.1022 48.0974 42.1393 46.8202 42.1393ZM42.5773 34.4677C41.9601 34.4677 41.463 34.9649 41.463 35.582V39.825C41.463 40.4421 41.9601 40.9393 42.5773 40.9393H46.8202C47.4374 40.9393 47.9345 40.4421 47.9345 39.825V35.582C47.9345 34.9649 47.4374 34.4677 46.8202 34.4677H42.5773Z"
        fill="#EB7200"
      />
      <path
        d="M58.049 42.1393H53.806C52.5289 42.1393 51.4917 41.1022 51.4917 39.825V35.582C51.4917 34.3049 52.5289 33.2677 53.806 33.2677H58.049C59.3262 33.2677 60.3633 34.3049 60.3633 35.582V39.825C60.3633 41.1022 59.3262 42.1393 58.049 42.1393ZM53.806 34.4677C53.1889 34.4677 52.6917 34.9649 52.6917 35.582V39.825C52.6917 40.4421 53.1889 40.9393 53.806 40.9393H58.049C58.6661 40.9393 59.1633 40.4421 59.1633 39.825V35.582C59.1633 34.9649 58.6661 34.4677 58.049 34.4677H53.806Z"
        fill="#EB7200"
      />
      <path
        d="M24.3711 53.3682H20.1282C18.851 53.3682 17.8138 52.331 17.8138 51.0538V46.8109C17.8138 45.5337 18.851 44.4966 20.1282 44.4966H24.3711C25.6483 44.4966 26.6855 45.5337 26.6855 46.8109V51.0538C26.6855 52.331 25.6483 53.3682 24.3711 53.3682ZM20.1282 45.6966C19.511 45.6966 19.0139 46.1937 19.0139 46.8109V51.0538C19.0139 51.671 19.511 52.1681 20.1282 52.1681H24.3711C24.9883 52.1681 25.4854 51.671 25.4854 51.0538V46.8109C25.4854 46.1937 24.9883 45.6966 24.3711 45.6966H20.1282Z"
        fill="#EB7200"
      />
      <path
        d="M35.5999 53.3682H31.3569C30.0798 53.3682 29.0426 52.331 29.0426 51.0538V46.8109C29.0426 45.5337 30.0798 44.4966 31.3569 44.4966H35.5999C36.8771 44.4966 37.9142 45.5337 37.9142 46.8109V51.0538C37.9142 52.331 36.8685 53.3682 35.5999 53.3682ZM31.3569 45.6966C30.7398 45.6966 30.2426 46.1937 30.2426 46.8109V51.0538C30.2426 51.671 30.7398 52.1681 31.3569 52.1681H35.5999C36.2171 52.1681 36.7142 51.671 36.7142 51.0538V46.8109C36.7142 46.1937 36.2171 45.6966 35.5999 45.6966H31.3569Z"
        fill="#EB7200"
      />
      <path
        d="M46.8202 53.3682H42.5773C41.3001 53.3682 40.2629 52.331 40.2629 51.0538V46.8109C40.2629 45.5337 41.3001 44.4966 42.5773 44.4966H46.8202C48.0974 44.4966 49.1346 45.5337 49.1346 46.8109V51.0538C49.1346 52.331 48.0974 53.3682 46.8202 53.3682ZM42.5773 45.6966C41.9601 45.6966 41.463 46.1937 41.463 46.8109V51.0538C41.463 51.671 41.9601 52.1681 42.5773 52.1681H46.8202C47.4374 52.1681 47.9345 51.671 47.9345 51.0538V46.8109C47.9345 46.1937 47.4374 45.6966 46.8202 45.6966H42.5773Z"
        fill="#EB7200"
      />
      <path
        d="M58.049 53.3682H53.806C52.5289 53.3682 51.4917 52.331 51.4917 51.0538V46.8109C51.4917 45.5337 52.5289 44.4966 53.806 44.4966H58.049C59.3262 44.4966 60.3633 45.5337 60.3633 46.8109V51.0538C60.3633 52.331 59.3262 53.3682 58.049 53.3682ZM53.806 45.6966C53.1889 45.6966 52.6917 46.1937 52.6917 46.8109V51.0538C52.6917 51.671 53.1889 52.1681 53.806 52.1681H58.049C58.6661 52.1681 59.1633 51.671 59.1633 51.0538V46.8109C59.1633 46.1937 58.6661 45.6966 58.049 45.6966H53.806Z"
        fill="#EB7200"
      />
      <path
        d="M24.3711 64.5884H20.1282C18.851 64.5884 17.8138 63.5513 17.8138 62.2741V58.0311C17.8138 56.754 18.851 55.7168 20.1282 55.7168H24.3711C25.6483 55.7168 26.6855 56.754 26.6855 58.0311V62.2741C26.6855 63.5513 25.6483 64.5884 24.3711 64.5884ZM20.1282 56.9168C19.511 56.9168 19.0139 57.414 19.0139 58.0311V62.2741C19.0139 62.8912 19.511 63.3884 20.1282 63.3884H24.3711C24.9883 63.3884 25.4854 62.8912 25.4854 62.2741V58.0311C25.4854 57.414 24.9883 56.9168 24.3711 56.9168H20.1282Z"
        fill="#EB7200"
      />
      <path
        d="M35.5999 64.5884H31.3569C30.0798 64.5884 29.0426 63.5513 29.0426 62.2741V58.0311C29.0426 56.754 30.0798 55.7168 31.3569 55.7168H35.5999C36.8771 55.7168 37.9142 56.754 37.9142 58.0311V62.2741C37.9142 63.5513 36.8685 64.5884 35.5999 64.5884ZM31.3569 56.9168C30.7398 56.9168 30.2426 57.414 30.2426 58.0311V62.2741C30.2426 62.8912 30.7398 63.3884 31.3569 63.3884H35.5999C36.2171 63.3884 36.7142 62.8912 36.7142 62.2741V58.0311C36.7142 57.414 36.2171 56.9168 35.5999 56.9168H31.3569Z"
        fill="#EB7200"
      />
      <path
        d="M46.8202 64.5884H42.5773C41.3001 64.5884 40.2629 63.5513 40.2629 62.2741V58.0311C40.2629 56.754 41.3001 55.7168 42.5773 55.7168H46.8202C48.0974 55.7168 49.1346 56.754 49.1346 58.0311V62.2741C49.1346 63.5513 48.0974 64.5884 46.8202 64.5884ZM42.5773 56.9168C41.9601 56.9168 41.463 57.414 41.463 58.0311V62.2741C41.463 62.8912 41.9601 63.3884 42.5773 63.3884H46.8202C47.4374 63.3884 47.9345 62.8912 47.9345 62.2741V58.0311C47.9345 57.414 47.4374 56.9168 46.8202 56.9168H42.5773Z"
        fill="#EB7200"
      />
      <path
        d="M58.049 64.5884H53.806C52.5289 64.5884 51.4917 63.5513 51.4917 62.2741V58.0311C51.4917 56.754 52.5289 55.7168 53.806 55.7168H58.049C59.3262 55.7168 60.3633 56.754 60.3633 58.0311V62.2741C60.3633 63.5513 59.3262 64.5884 58.049 64.5884ZM53.806 56.9168C53.1889 56.9168 52.6917 57.414 52.6917 58.0311V62.2741C52.6917 62.8912 53.1889 63.3884 53.806 63.3884H58.049C58.6661 63.3884 59.1633 62.8912 59.1633 62.2741V58.0311C59.1633 57.414 58.6661 56.9168 58.049 56.9168H53.806Z"
        fill="#EB7200"
      />
      <path
        d="M23.8483 39.8422C23.7112 39.8422 23.574 39.7907 23.4712 39.6879L20.2654 36.4907C20.0597 36.2849 20.0597 35.9421 20.2654 35.7364C20.4711 35.5306 20.814 35.5306 21.0197 35.7364L24.2255 38.9336C24.4312 39.1393 24.4312 39.4821 24.2255 39.6879C24.1226 39.7907 23.9855 39.8422 23.8483 39.8422Z"
        fill="#006A36"
      />
      <path
        d="M20.6514 39.8422C20.5143 39.8422 20.3771 39.7907 20.2743 39.6879C20.0686 39.4821 20.0686 39.1393 20.2743 38.9336L23.4801 35.7364C23.6858 35.5306 24.0287 35.5306 24.2344 35.7364C24.4401 35.9421 24.4401 36.2849 24.2344 36.4907L21.0286 39.6879C20.9257 39.7907 20.7886 39.8422 20.6514 39.8422Z"
        fill="#006A36"
      />
      <path
        d="M35.0773 39.8422C34.9401 39.8422 34.803 39.7907 34.7001 39.6879L31.5029 36.4907C31.2972 36.2849 31.2972 35.9421 31.5029 35.7364C31.7086 35.5306 32.0515 35.5306 32.2572 35.7364L35.4544 38.9336C35.6602 39.1393 35.6602 39.4821 35.4544 39.6879C35.3516 39.7907 35.2144 39.8422 35.0773 39.8422Z"
        fill="#006A36"
      />
      <path
        d="M31.8713 39.8422C31.7341 39.8422 31.597 39.7907 31.4941 39.6879C31.2884 39.4821 31.2884 39.1393 31.4941 38.9336L34.6913 35.7364C34.8971 35.5306 35.2399 35.5306 35.4457 35.7364C35.6514 35.9421 35.6514 36.2849 35.4457 36.4907L32.2484 39.6879C32.1456 39.7907 32.0084 39.8422 31.8713 39.8422Z"
        fill="#006A36"
      />
      <path
        d="M22.2453 20.5817C20.9767 20.5817 19.9396 19.5531 19.9396 18.276V11.1358C19.9396 9.86721 20.9682 8.83005 22.2453 8.83005C23.5139 8.83005 24.5511 9.85864 24.5511 11.1358V18.2845C24.5511 19.5531 23.5225 20.5817 22.2453 20.5817Z"
        fill="white"
      />
      <path
        d="M22.2454 21.1817C20.6425 21.1817 19.3396 19.8788 19.3396 18.276V11.1358C19.3482 9.5329 20.6511 8.23001 22.2454 8.23001C23.8397 8.23001 25.1512 9.5329 25.1512 11.1358V18.2845C25.1512 19.8788 23.8483 21.1817 22.2454 21.1817ZM22.2454 9.43004C21.3111 9.43004 20.5396 10.1929 20.5396 11.1358V18.2845C20.5396 19.2188 21.3025 19.9903 22.2454 19.9903C23.1883 19.9903 23.9511 19.2274 23.9511 18.2845V11.1358C23.9511 10.1929 23.1883 9.43004 22.2454 9.43004Z"
        fill="#EB7200"
      />
      <path
        d="M55.9232 20.5817C54.6546 20.5817 53.6174 19.5531 53.6174 18.276V11.1358C53.6174 9.86721 54.646 8.83005 55.9232 8.83005C57.1918 8.83005 58.229 9.85864 58.229 11.1358V18.2845C58.229 19.5531 57.2004 20.5817 55.9232 20.5817Z"
        fill="white"
      />
      <path
        d="M55.9232 21.1817C54.3203 21.1817 53.0175 19.8788 53.0175 18.276V11.1358C53.026 9.5329 54.3289 8.23001 55.9232 8.23001C57.5176 8.23001 58.829 9.5329 58.829 11.1358V18.2845C58.829 19.8788 57.5261 21.1817 55.9232 21.1817ZM55.9232 9.43004C54.9889 9.43004 54.2175 10.1929 54.2175 11.1358V18.2845C54.2175 19.2188 54.9804 19.9903 55.9232 19.9903C56.8661 19.9903 57.629 19.2274 57.629 18.2845V11.1358C57.629 10.1929 56.8661 9.43004 55.9232 9.43004Z"
        fill="#EB7200"
      />
    </SvgIcon>
  );
};
