import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { movingRoutes } from 'common/routing/AppRoute';
import { LayoutRoute } from 'common/ui/layoutRoute/LayoutRoute';

import { MovingStep1 } from './pages/movingStep1/MovingStep1';
import { MovingStep2 } from './pages/movingStep2/MovingStep2';
import { MovingStep3 } from './pages/movingStep3/MovingStep3';
import { MovingStep4 } from './pages/movingStep4/MovingStep4';
import { MovingStep5 } from './pages/movingStep5/MovingStep5';
import { MovingStep6 } from './pages/movingStep6/MovingStep6';
import { MovingProcessingRequest } from './MovingProcessingRequest';
import { MovingAllDone } from './MovingAllDone';
import { MovingUnserviced } from './MovingUnserviced';

export const MovingRoutes = () => {
  return (
    <Switch>
      <LayoutRoute exact type="moving" path={movingRoutes.step1} component={MovingStep1} />
      <LayoutRoute exact type="moving" path={movingRoutes.step2} component={MovingStep2} />
      <LayoutRoute exact type="moving" path={movingRoutes.step3} component={MovingStep3} />
      <LayoutRoute exact type="moving" path={movingRoutes.step4} component={MovingStep4} />
      <LayoutRoute exact type="moving" path={movingRoutes.step5} component={MovingStep5} />
      <LayoutRoute exact type="moving" path={movingRoutes.step6} component={MovingStep6} />
      <Route exact path={movingRoutes.unserviced} component={MovingUnserviced} />
      <Route exact path={movingRoutes.processing} component={MovingProcessingRequest} />
      <Route exact path={movingRoutes.alldone} component={MovingAllDone} />
      <Redirect to={movingRoutes.step1} />
    </Switch>
  );
};
