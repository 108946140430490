type GoogleApiConfig = {
  googleMapsApiKey: string;
  libraries?: ['drawing' | 'geometry' | 'localContext' | 'places' | 'visualization'];
};

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

/*
 * @react-google-maps/api requires that have config outside of any component,
 * thanks that it prevent the google script from being attached multiple times
 */
export const googleApiConfig: GoogleApiConfig = {
  libraries: ['places'],
  googleMapsApiKey: GOOGLE_API_KEY,
};
