import React, { useCallback, useMemo, useRef } from 'react';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { TimePicker, TimePickerSchedule, Loader } from '@chhjpackages/components';
import Slider from 'react-slick';
import clsx from 'clsx';

import { CarouselSlider } from 'common/ui/carouselSlider/CarouselSlider';
import { ShortArrowIcon } from 'common/assets/icons/ShortArrowIcon';
import { isSameSchedule } from 'common/utils/form';
import { Schedules } from 'common/hooks/useAvailability/useAvailability.types';

import { useStyles } from './SliderSchedulePicker.styles';
import { SliderSchedulePickerProps } from './SliderSchedulePicker.types';

const slidesMap = {
  morning: 0,
  afternoon: 1,
  evening: 2,
};

const initialSchedules = {
  morning: [],
  afternoon: [],
  evening: [],
} as Schedules;

export const SliderSchedulePicker = ({
  isLoading,
  schedules = initialSchedules,
  value,
  onChange,
  initialPeriod,
}: SliderSchedulePickerProps) => {
  const styles = useStyles();
  const sliderRef = useRef<Slider>(null);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const findSelectedPeriod = useCallback((schedule: TimePickerSchedule[], value: TimePickerSchedule | null) => {
    if (!value) return schedule;

    const newSchedule = schedule.map((period) => ({
      ...period,
      selected: isSameSchedule(period, value),
    }));
    return newSchedule;
  }, []);

  const initialSlide = useMemo(() => (initialPeriod ? slidesMap[initialPeriod] : slidesMap.morning), [initialPeriod]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.sliderContainer}>
        {!isDesktop && !isLoading && (
          <>
            <ShortArrowIcon onClick={() => sliderRef.current?.slickPrev()} className={styles.sliderArrow} />
            <ShortArrowIcon
              onClick={() => sliderRef.current?.slickNext()}
              className={clsx([styles.sliderArrowRight, styles.sliderArrow])}
            />
          </>
        )}

        {isLoading ? (
          <div className={styles.timePickerContainer}>
            <Loader />
          </div>
        ) : (
          <CarouselSlider ref={sliderRef} settings={{ arrows: false, slidesToShow: isDesktop ? 3 : 1, initialSlide }}>
            {Object.entries(schedules).map(([key, schedulePeriod]) => (
              <div key={key} className={styles.sliderItem}>
                <TimePicker
                  period={key}
                  schedule={findSelectedPeriod(schedulePeriod, value)}
                  onTimeSelected={onChange}
                  alwaysShowScrollbar
                  className={styles.timePickerContainer}
                />
              </div>
            ))}
          </CarouselSlider>
        )}
      </div>
    </div>
  );
};
