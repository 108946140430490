import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { Home } from 'common/app/home/Home';
import { JunkRemovalRoutes } from 'common/app/junkRemoval/JunkRemovalRoutes';
import { MovingRoutes } from 'aMove/app/moving/MovingRoutes';
import { MovingRoutes as MovingRoutesVerB } from 'bMove/app/moving/MovingRoutes';
import { LaborRoutes } from 'common/app/labor/LaborRoutes';
// import { CallMeNowModalAllDone } from 'common/app/callMeNowModal/CallMeNowModalAllDone';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';

import { AppRoute } from './AppRoute';

export const AppRoutes = () => {
  const { moveVer } = useVersionContext();

  return (
    <Switch>
      <Route path={AppRoute.home} exact component={Home} />
      <Route path={AppRoute.junkRemoval} component={JunkRemovalRoutes} />
      {moveVer === 'A' && <Route path={AppRoute.moving} component={MovingRoutes} />}
      {moveVer === 'B' && <Route path={AppRoute.moving} component={MovingRoutesVerB} />}
      <Route path={AppRoute.labor} component={LaborRoutes} />
      {/* TODO CALLMNOW: modal hidden for now */}
      {/* <Route path={`${AppRoute.callMe}${CommonRoute.allDone}`} component={CallMeNowModalAllDone} /> */}

      <Redirect to={AppRoute.home} />
    </Switch>
  );
};
