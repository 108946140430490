import React, { ReactNode, useReducer, useMemo, useEffect, useCallback } from 'react';
import { useMutation } from 'react-fetching-library';
import useDeepCompareEffect from 'use-deep-compare-effect';

import { calculateQuoteAction } from 'common/api/actions/account/accountActions';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setGlobalData } from 'common/context/form/formActionCreators/formActionCreators';
import { QuoteRequestParams } from 'common/api/actions/account/accountActions.types';

import { MovingDispatchContext, MovingStateContext } from './MovingContext';
import { MovingStateContextValue, MovingDispatchContextValue } from './MovingContext.types';
import { movingActions, movingReducer } from './MovingContextSlice';
import { movingStorage } from './MovingContextStorage';
import { mapMovingStateToCalculateQuotePayload } from './MovingContextController.utils';

export const MovingContextController = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(movingReducer, movingStorage.state);
  const { moving } = state;
  const { locationId, accountId, token, bookingDone } = useFormState();
  const formDispatch = useFormDispatch();
  const { mutate: calculateQuoteMutate, abort: abortCalculateQuote, loading: isCalculatingQuote } = useMutation(
    calculateQuoteAction,
  );

  const calculateQuote = useCallback(
    async (params: QuoteRequestParams) => {
      abortCalculateQuote();
      const { payload: quotePayload } = await calculateQuoteMutate(params);

      if (quotePayload?.quote?.length) {
        dispatch(movingActions.setPricingValues({ quote: quotePayload.quote[0] }));
        dispatch(movingActions.setPrepaymentData({ quote: quotePayload.quote[0] }));

        formDispatch(setGlobalData({ isCallRequired: quotePayload.quote[0].call_required }));

        return quotePayload?.quote[0];
      } else {
        // if calculate a quote fails (because ex. no road connection)
        // then just setCallRequired
        formDispatch(setGlobalData({ isCallRequired: true }));
      }
    },
    [abortCalculateQuote, calculateQuoteMutate, formDispatch],
  );

  useDeepCompareEffect(() => {
    if (bookingDone) return;

    (async () => {
      const params = mapMovingStateToCalculateQuotePayload({
        movingState: {
          step1: moving?.step1,
          step2: moving?.step2,
          step3: moving?.step3,
          step4: moving?.step4,
          step5: moving?.step5,
        },
        locationId,
        accountId,
        token,
      });
      if (!params) return;

      await calculateQuote(params);
    })();
  }, [
    calculateQuote,
    locationId,
    accountId,
    token,
    moving?.step1,
    moving?.step2,
    moving?.step3,
    moving?.step4,
    moving?.step5,
    bookingDone,
  ]);

  useEffect(() => {
    movingStorage.state = state;
  }, [state]);

  useEffect(() => {
    return () => movingStorage.clear();
  }, []);

  const stateValue = useMemo<MovingStateContextValue>(
    () => ({
      ...state,
      isCalculatingQuote,
    }),
    [state, isCalculatingQuote],
  );
  const dispatchValue = useMemo<MovingDispatchContextValue>(
    () => ({
      movingDispatch: dispatch,
      calculateQuote,
    }),
    [calculateQuote, dispatch],
  );

  return (
    <MovingStateContext.Provider value={stateValue}>
      <MovingDispatchContext.Provider value={dispatchValue}>{children}</MovingDispatchContext.Provider>
    </MovingStateContext.Provider>
  );
};
