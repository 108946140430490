import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 489,

    '& > *:not(:last-child)': {
      marginBottom: 16,
    },
  },
  tipBox: {
    marginTop: 'auto',
  },
  inputError: {
    margin: '4px 0 6px',
    lineHeight: '14px',
    fontSize: 14,
    height: 14,
  },
}));
