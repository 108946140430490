import React from 'react';
import { SelectOptionType, TileToggleItem } from '@chhjpackages/components';

import { TypeOfDwellingEnum, AccessTypeEnum } from 'common/api/types/index';
import { StairsIcon } from 'common/assets/icons/StairsIcon';
import { ElevatorIcon } from 'common/assets/icons/ElevatorIcon';
import { Bedroom1Icon } from 'common/assets/icons/Bedroom1Icon';
import { Bedroom2Icon } from 'common/assets/icons/Bedroom2Icon';
import { Bedroom3Icon } from 'common/assets/icons/Bedroom3Icon';
import { Bedroom4Icon } from 'common/assets/icons/Bedroom4Icon';

// Note: type of dweeling is not used in API for this moment.
// But select should still exist.
export const typeOfDwellingOptions: SelectOptionType<number>[] = [
  { value: TypeOfDwellingEnum.Apartment, label: 'Apartment / Condo' },
  { value: TypeOfDwellingEnum.House, label: 'House' },
  { value: TypeOfDwellingEnum.Business, label: 'Business' },
  { value: TypeOfDwellingEnum.StorageUnit, label: 'Storage Unit' },
];

const enUsRules = new Intl.PluralRules('en-US', { type: 'ordinal' });
const suffixes: Record<string, string> = {
  one: 'st',
  two: 'nd',
  few: 'rd',
  other: 'th',
};

export const floorOptions: SelectOptionType<number>[] = Array.from(Array(99).keys()).map((floorNo) => {
  const floorNoLabel = floorNo + 1;
  const suffix = suffixes[enUsRules.select(floorNoLabel)];

  return {
    value: floorNo,
    label: `${floorNoLabel}${suffix} floor`,
  };
});

export const accessTypeTiles: TileToggleItem<number>[] = [
  { icon: <ElevatorIcon />, label: 'Elevator', value: AccessTypeEnum.Elevator },
  { icon: <StairsIcon />, label: 'Stairs', value: AccessTypeEnum.Stairs },
];

export enum TruckParkingSelectEnum {
  Driveway = 0,
  StreetCloseWalk = 1,
  StreetSomewhatWalk = 2,
  ParkingCloseWalk = 3,
  ParkingSomewhatWalk = 4,
  ParkingFarWalk = 5,
}

export const truckParkingOptions: SelectOptionType<number>[] = [
  { value: TruckParkingSelectEnum.Driveway, label: 'Driveway' },
  { value: TruckParkingSelectEnum.StreetCloseWalk, label: 'On the Street - Close walk' },
  { value: TruckParkingSelectEnum.StreetSomewhatWalk, label: 'On the Street - Somewhat close walk' },
  { value: TruckParkingSelectEnum.ParkingCloseWalk, label: 'Parking Lot / Loading Dock - Close walk' },
  { value: TruckParkingSelectEnum.ParkingSomewhatWalk, label: 'Parking Lot / Loading Dock - Somewhat close walk' },
  { value: TruckParkingSelectEnum.ParkingFarWalk, label: 'Parking Lot / Loading Dock - Far walk' },
];

export const bedroomsTiles: TileToggleItem<string>[] = [
  { icon: <Bedroom1Icon />, label: '1 bedroom', value: '1' },
  { icon: <Bedroom2Icon />, label: '2 bedrooms', value: '2' },
  { icon: <Bedroom3Icon />, label: '3 bedrooms', value: '3' },
  { icon: <Bedroom4Icon />, label: '4+ bedrooms', value: '4+' },
];
