import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
  },
  date: {
    fontWeight: 'bold',
    margin: '0 8px',
  },
  buttonsGroup: {
    paddingTop: 48,
    marginTop: 'auto',
  },
}));
