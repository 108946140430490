import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Typography, FormControl, Collapse } from '@material-ui/core';
import { Checkbox, Button, validateEmail, validatePhone } from '@chhjpackages/components';
import { useForm, Controller } from 'react-hook-form';

import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { TipBox } from 'common/ui/tipBox/TipBox';
import { formatPhoneForApi } from 'common/utils/form';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { CorporateCodeModalContainer } from 'common/ui/corporateCodeModal/CorporateCodeModalContainer';

import { ContactInformationInput } from './contactInformationInput/ContactInformationInput';
import { useStyles } from './ContactInformationForm.styles';
import {
  ContactInformationFormValues,
  ContactInfoOptionalPhoneValues,
  ContactInformationFormProps,
} from './ContactInformationForm.types';

export const ContactInformationForm = ({
  onBackClick,
  defaultValues,
  loading,
  title,
  tipBoxData,
  hasPrefilledData,
  onSubmit,
}: ContactInformationFormProps) => {
  const styles = useStyles();
  const partnersSectionRef = useRef<HTMLDivElement | null>(null);
  const { isPartner, partnerData } = useFormState();
  const [isCorporateCodeModalOpen, setCorporateCodeModalOpen] = useState(false);
  const {
    handleSubmit,
    register,
    formState,
    errors,
    trigger,
    control,
    watch,
    setValue,
  } = useForm<ContactInformationFormValues>({
    defaultValues: {
      ...defaultValues,
      hasCorporateCode: isPartner,
    },
    mode: 'onBlur',
  });
  const { isValid, isDirty, touched } = formState;
  const isHasCorporateCodeChecked = watch('hasCorporateCode');

  const scrollOnNewFieldsAppear = () => {
    setTimeout(() => {
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
      // timeout=201, because it must be runned after <Collapse/> animation
    }, 201);
  };

  const onSubmitCallback = useCallback(
    (values: ContactInfoOptionalPhoneValues) => {
      const correctedValues: ContactInformationFormValues = {
        ...values,
        phone: formatPhoneForApi(values?.phone || ''), // phone optional in ver B
        companyName: values.companyName || '',
        orderNumber: values.orderNumber || '',
        specialInstructions: values.specialInstructions || '',
        additionalNotes: values.additionalNotes || '',
      };
      onSubmit(correctedValues, isDirty || isValid);
    },
    [isDirty, onSubmit, isValid],
  );

  const handleCheckboxChange = useCallback(
    (onChange: (val: unknown) => void) => (newValue: boolean) => {
      if (!isPartner) {
        setCorporateCodeModalOpen(true);
        return;
      }
      onChange(newValue);

      if (newValue) {
        scrollOnNewFieldsAppear();
      }
    },
    [isPartner],
  );

  useEffect(() => {
    if (hasPrefilledData) trigger();
  }, [trigger, hasPrefilledData]);

  useEffect(() => {
    if (isPartner && !isHasCorporateCodeChecked) {
      setValue('hasCorporateCode', true);
      scrollOnNewFieldsAppear();
    }
  }, [setValue, isPartner, isHasCorporateCodeChecked]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitCallback)} className={styles.form}>
        <div className={styles.content}>
          <Typography variant="h4" className={styles.margin}>
            {title}
          </Typography>

          <Typography variant="body1" className={styles.margin}>
            Please provide your contact information so we can get in touch on the day of your service. We will also
            follow up to confirm availability and lock-in your appointment!
            <br />
            <br />
            <small>
              *We respect your privacy and will never sell, rent, lease or give away your information (name, address,
              email, etc.) to any third party.
            </small>
          </Typography>

          <ContactInformationInput
            name="firstName"
            label={isPartner ? 'Contact first name' : 'First name'}
            touched={hasPrefilledData || !!touched.firstName}
            error={errors.firstName}
            inputRef={register({
              required: '*required',
              maxLength: { value: 50, message: '*max 50 characters' },
            })}
            helperText="*required"
          />

          <ContactInformationInput
            name="lastName"
            label={isPartner ? 'Contact last name' : 'Last name'}
            touched={hasPrefilledData || !!touched.lastName}
            error={errors.lastName}
            inputRef={register({
              required: '*required',
              maxLength: { value: 50, message: '*max 50 characters' },
            })}
            helperText="*required"
          />

          <ContactInformationInput
            name="email"
            label="Email"
            touched={hasPrefilledData || !!touched.email}
            error={errors.email}
            inputMode="email"
            inputRef={register({
              required: '*required',
              validate: (value) =>
                validateEmail(value) || 'Please provide proper e-mail format, like: example@domain.com',
            })}
            helperText="*required"
          />

          {/* without phone input on junkRemoval (so when phone defaultValue is not provided) */}
          {defaultValues.phone !== undefined && (
            <ContactInformationInput
              name="phone"
              label="Contact phone number"
              touched={hasPrefilledData || !!touched.phone}
              error={errors.phone}
              mask="phone"
              inputMode="numeric"
              inputRef={register({
                required: '*required',
                validate: (value) => validatePhone(value) || 'Please provide proper phone number',
              })}
              helperText="*required"
            />
          )}

          {(!isPartner || (isPartner && partnerData?.shouldDisplayPartnersFields)) && (
            <FormControl ref={partnersSectionRef}>
              <Controller
                name="hasCorporateCode"
                control={control}
                render={({ onChange, onBlur, value, ref, name }) => (
                  <Checkbox
                    onBlur={onBlur}
                    onChange={handleCheckboxChange(onChange)}
                    inputRef={ref}
                    value={value}
                    checked={value}
                    name={name}
                    disabled={isPartner}
                    variant="outline"
                    label="I have a corporate code"
                  />
                )}
              />
            </FormControl>
          )}

          {isPartner && partnerData?.shouldDisplayPartnersFields && (
            <Collapse timeout={200} in={isHasCorporateCodeChecked}>
              <div>
                <div className={styles.divider} />
                <ContactInformationInput
                  name="companyName"
                  label="Company name (if applicable)"
                  error={errors.companyName}
                  inputRef={register}
                />
                <ContactInformationInput
                  name="orderNumber"
                  label="Purchase order number (if applicable)"
                  error={errors.orderNumber}
                  inputRef={register}
                />
                <Box mb={1} />
                <ContactInformationInput
                  name="specialInstructions"
                  label="Special instructions"
                  error={errors.specialInstructions}
                  inputRef={register}
                  rows={2}
                />
                <Box mb={1} />
                <ContactInformationInput
                  name="additionalNotes"
                  label="Additional notes"
                  error={errors.additionalNotes}
                  inputRef={register}
                  rows={2}
                />
              </div>
            </Collapse>
          )}
        </div>

        <Box mt="auto">
          <TipBox iconName={tipBoxData.icon} fullWidth className={styles.tipBox}>
            {tipBoxData.text}
          </TipBox>

          <ButtonsGroup>
            <Button onClick={onBackClick} fullWidth size="large" buttonType="outlined">
              Back
            </Button>
            <Button disabled={!isValid} isLoading={loading} type="submit" fullWidth size="large" buttonType="twoTone">
              Next
            </Button>
          </ButtonsGroup>
        </Box>
      </form>
      <CorporateCodeModalContainer open={isCorporateCodeModalOpen} onClose={() => setCorporateCodeModalOpen(false)} />
    </>
  );
};
