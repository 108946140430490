import React, { useMemo } from 'react';
import { Typography, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import clsx from 'clsx';

import { getPriceRange } from 'common/utils/currencyFormatter';

import { useStyles } from './PricingTable.styles';
import { PricingTableProps } from './PricingTable.types';

export const PricingTable = ({ data }: PricingTableProps) => {
  const styles = useStyles();

  const tableContent = useMemo(() => {
    const { numberOfHunks, truckAndTravel, hourlyRate, time } = data;

    return [
      {
        title: 'HUNKS',
        value: numberOfHunks,
      },
      {
        title: 'Truck & travel fee',
        value: `$${truckAndTravel}`,
      },
      {
        title: `Hourly rate for ${numberOfHunks} HUNKS`,
        value: `$${hourlyRate}`,
      },
      {
        title: 'Approximate time',
        value: `${time.low}-${time.high} hours`,
      },
    ];
  }, [data]);

  const approxPrice = useMemo(() => {
    const { totalPrice } = data;

    return getPriceRange(totalPrice.low, totalPrice.high);
  }, [data]);

  return (
    <TableContainer className={styles.contentWrapper}>
      <Table>
        <TableBody>
          {tableContent.map(({ title, value }) => (
            <TableRow key={title} className={styles.row}>
              <TableCell component="th" scope="row" className={styles.cell}>
                <Typography className={styles.cellTitle}>{title}</Typography>
              </TableCell>
              <TableCell component="th" scope="row" className={clsx(styles.cell, styles.rightCell)}>
                <Typography>{value}</Typography>
              </TableCell>
            </TableRow>
          ))}

          {/* Trick for correct, uniform border dashed */}
          <TableRow className={styles.divider}>
            <td style={{ height: 0 }} colSpan={2}></td>
          </TableRow>

          <TableRow className={clsx(styles.row, styles.lastRow)}>
            <TableCell colSpan={2} component="th" scope="row" className={clsx(styles.cell, styles.rightCell)}>
              <Typography variant="caption" className={styles.yourEstimatePrice}>
                Your estimate: <span>{approxPrice}</span>
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
