import React from 'react';
import { Route } from 'react-router';

import { MainLayout } from 'common/ui/mainLayout/MainLayout';

import { LayoutRouteProps } from './LayoutRoute.types';

export const LayoutRoute = ({ type, ...routeProps }: LayoutRouteProps) => {
  return (
    <MainLayout type={type}>
      <Route {...routeProps} />
    </MainLayout>
  );
};
