import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const MovingIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="56" height="57" viewBox="0 0 56 57" style={{ fill: 'none' }} fill="none">
      <path
        d="M53.6667 12.0501C53.6667 11.5835 53.3167 11.2335 52.85 11.2335C52.15 11.2335 51.5667 11.4668 50.9833 11.8168L34.7667 23.7168C34.4167 23.9501 34.3 24.5335 34.5333 24.8835C34.65 25.1168 35 25.2335 35.2333 25.2335C35.35 25.2335 35.5833 25.2335 35.7 25.1168L39.0833 22.6668V43.3168C39.0833 45.0668 40.4833 46.4668 42.2333 46.4668H52.85C53.3167 46.4668 53.6667 46.1168 53.6667 45.6501"
        fill={colors.primary.light}
      />
      <path
        d="M3.73334 45.5335C3.73334 46.0001 4.08334 46.3501 4.55 46.3501H15.1667C16.9167 46.3501 18.3167 44.9501 18.3167 43.2001V22.5501L21.7 25.0001C21.8167 25.1168 22.05 25.1168 22.1667 25.1168C22.4 25.1168 22.6333 25.0001 22.8667 24.7668C23.1 24.4168 23.1 23.8335 22.6333 23.6001L6.41667 11.8168C5.83334 11.4668 5.25 11.2335 4.55 11.2335C4.08334 11.2335 3.73334 11.5835 3.73334 12.0501"
        fill={colors.primary.light}
      />
      <path
        d="M33.7167 36.0835C33.7167 35.9668 33.6 35.8502 33.4833 35.8502L28.4667 30.8335C28.1167 30.4835 27.65 30.4835 27.3 30.8335C26.95 31.1835 26.95 31.6502 27.3 32.0002L30.9167 35.6168H22.6333C22.1667 35.6168 21.8167 35.9668 21.8167 36.4335C21.8167 36.9001 22.1667 37.2502 22.6333 37.2502H30.9167L27.3 40.8668C26.95 41.2168 26.95 41.6835 27.3 42.0335C27.4167 42.1502 27.65 42.2668 27.8833 42.2668C28.1167 42.2668 28.35 42.1502 28.4667 42.0335L33.4833 37.0168C33.6 36.9001 33.6 36.9002 33.7167 36.7835C33.7167 36.5502 33.7167 36.3168 33.7167 36.0835Z"
        fill={colors.primary.light}
      />
      <path
        d="M50.6334 43.3167H40.0167C38.7333 43.3167 37.6833 42.2667 37.6833 40.9834V18.9334"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M33.0167 22.2001L49.2333 10.3001C49.7 9.9501 50.1667 9.83344 50.6333 9.83344"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M47.1334 43.3168V34.2168H50.6334" stroke={colors.primary.main} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M15.2833 18.9334V40.9834C15.2833 42.2667 14.2333 43.3167 12.95 43.3167H2.33334"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M2.33334 9.83344C2.80001 9.83344 3.26668 9.9501 3.73334 10.3001L19.95 22.2001"
        stroke={colors.primary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M2.33334 34.2168H5.83334V43.3168" stroke={colors.primary.main} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M20.4167 34.2167H30.6834"
        stroke={colors.secondary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.7834 39.2334L30.8 34.2167L25.7834 29.2001"
        stroke={colors.secondary.main}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
