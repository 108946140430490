import { useCallback, useMemo } from 'react';
import { useMutation } from 'react-fetching-library';

import { mapAccountToSegmentTraits, rudderanalytics } from 'common/utils/rudderanalytics';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { setGlobalData } from 'common/context/form/formActionCreators/formActionCreators';
import { createNewAccountAction, pingAction, updateAccountAction } from 'common/api/actions/account/accountActions';
import { AccountResponse } from 'common/api/actions/account/accountActions.types';

import { MapStateToAccountParams, mapStateToAccountPayload } from './useAccount.utils';

export const useAccount = () => {
  const { locationId, accountId, token, partnerData } = useFormState();
  const dispatch = useFormDispatch();
  const { mutate: createAccountMutate, loading: isCreatingAccount } = useMutation(createNewAccountAction);
  const { mutate: updateAccountMutate, loading: isUpdatingAccount } = useMutation(updateAccountAction);
  const { mutate: sendPingMutate, loading: isSendingPing } = useMutation(pingAction);
  const isRecreatingAccount = useMemo(() => isCreatingAccount || isSendingPing, [isCreatingAccount, isSendingPing]);
  const isCreatingOrUpdatingAccount = useMemo(() => isCreatingAccount || isUpdatingAccount, [
    isCreatingAccount,
    isUpdatingAccount,
  ]);

  const createOrUpdateAccount = useCallback(
    async (mapStateToAccountParams: MapStateToAccountParams) => {
      if (!locationId) return;
      const accountPayload = mapStateToAccountPayload({ ...mapStateToAccountParams, partnerData });

      let account: AccountResponse | undefined;

      if (accountId && token) {
        const { payload } = await updateAccountMutate({
          accountId,
          token,
          locationId,
          payload: accountPayload,
        });

        account = payload?.accounts?.[0];
      } else {
        const { payload } = await createAccountMutate({
          locationId,
          payload: accountPayload,
        });
        account = payload?.tokens?.[0].account;

        if (!payload?.tokens?.length) {
          return;
        }

        dispatch(
          setGlobalData({
            token: payload.tokens[0].token,
            accountId: payload.tokens[0].account.id,
            isReadyToPing: true,
          }),
        );
      }

      if (!account) {
        return;
      }

      const segmentTraits = mapAccountToSegmentTraits(account);
      rudderanalytics.identify(`account-${account.id}`, segmentTraits);

      const segmentFormEventTraits = {
        firstName: account.first_name,
        lastName: account.last_name,
        email: account.email,
        phone: account.phone,
      };
      rudderanalytics.track(`Contact Form Fill`, segmentFormEventTraits);

      return account;
    },
    [createAccountMutate, updateAccountMutate, locationId, dispatch, accountId, token, partnerData],
  );

  const recreateAccountWithNewLocationId = useCallback(
    async (mapStateToAccountParams: MapStateToAccountParams, newLocationId: number) => {
      if (!token || !accountId || !locationId) return;

      const accountPayload = mapStateToAccountPayload({ ...mapStateToAccountParams, partnerData });

      const { payload: pingPayload } = await sendPingMutate({
        locationId,
        accountId,
        token,
        booking_complete: true,
      });

      if (pingPayload?.meta.status.code !== 200) return;

      dispatch(setGlobalData({ isReadyToPing: false }));

      const { payload } = await createAccountMutate({
        locationId: newLocationId,
        payload: accountPayload,
      });

      if (!payload?.tokens?.length) return;

      dispatch(
        setGlobalData({
          token: payload.tokens[0].token,
          accountId: payload.tokens[0].account.id,
          locationId: newLocationId,
          isReadyToPing: true,
        }),
      );
    },
    [dispatch, sendPingMutate, createAccountMutate, token, accountId, locationId, partnerData],
  );

  return {
    createOrUpdateAccount,
    recreateAccountWithNewLocationId,
    isCreatingOrUpdatingAccount,
    isRecreatingAccount,
  };
};
