import { useContext } from 'react';

import { VersionContext } from 'common/context/version/VersionContext';

export const useVersionContext = () => {
  const context = useContext(VersionContext);

  if (context === undefined) {
    throw new Error('useVersionContext must be used within a VersionContextController');
  }

  return context;
};
