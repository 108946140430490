import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { useJunkRemovalStateContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { useLaborStateContext } from 'common/hooks/useLabor/useLabor';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';
import { useMovingOptionalStateContext } from 'aMove/hooks/useMoving/useMoving';
import { useMovingVerBOptionalStateContext } from 'bMove/hooks/useMoving/useMoving';

import { AsideNavBarItem, AsideNavbarProps } from './AsideNavbar.types';
import { useStyles } from './AsideNavbar.styles';
import { AsideNavbarItem } from './asideNavbarItem/AsideNavbarItem';
import { getNavItemsWithState } from './AsideNavbar.utils';
import { asideNavbarData, asideNavbarVerBData } from './AsideNavbar.data';

export const AsideNavbar = ({ type, mobileVersionType }: AsideNavbarProps) => {
  const styles = useStyles();
  const { isCallRequired } = useFormState();
  const { junkRemoval } = useJunkRemovalStateContext();
  const { labor } = useLaborStateContext();
  const movingState = useMovingOptionalStateContext();
  const movingVerBState = useMovingVerBOptionalStateContext();
  const { moveVer } = useVersionContext();
  const { location } = useHistory();

  const asideNavbarDataCorrectVersion = useMemo(() => {
    return moveVer === 'A' ? asideNavbarData : asideNavbarVerBData;
  }, [moveVer]);

  // if key is present in formState (ex. formState.junkRemoval?.step1) then it means step is filled correctly
  const filledStepsNames = useMemo(() => {
    if (type === 'junkRemoval') return Object.keys(junkRemoval);
    if (type === 'moving' && moveVer === 'A' && movingState) return Object.keys(movingState.moving);
    if (type === 'moving' && moveVer === 'B' && movingVerBState) return Object.keys(movingVerBState.moving);
    if (type === 'labor') return Object.keys(labor);
    return [];
  }, [junkRemoval, movingState, movingVerBState, labor, type, moveVer]);

  const mappedData: AsideNavBarItem[] = useMemo(() => {
    const data = getNavItemsWithState({
      data: asideNavbarDataCorrectVersion,
      filledStepsNames,
      serviceType: type,
      isCallRequired,
    });

    if (!mobileVersionType) return data;

    const activeItemId = data.find((item) => item.route === location.pathname)?.id;

    /**
     * partOfNavData explanation:
     * on mobiles AsideNavbar is split to 2 navbars, on first one (mobileVersionType === 'prevItems') should be displayed nav-items before
     * the current-active one, the second one (mobileVersionType === 'nextItems') should display nav-items after the current-active one
     */
    const partOfNavData = data.filter(({ id }) => {
      if (!activeItemId) return false;
      return mobileVersionType === 'prevItems' ? id < activeItemId : id >= activeItemId;
    });

    return partOfNavData;
  }, [asideNavbarDataCorrectVersion, isCallRequired, type, mobileVersionType, location.pathname, filledStepsNames]);

  return (
    <Grid
      container
      direction="column"
      spacing={1}
      className={clsx(mobileVersionType !== 'nextItems' && styles.container)}
    >
      {mappedData.map((item) => (
        <Grid item key={`${item.id}-${item.title}`} className={styles.gridItem}>
          <AsideNavbarItem item={item} isMobile={!!mobileVersionType} />
        </Grid>
      ))}
    </Grid>
  );
};
