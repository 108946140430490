import { getCookie } from 'common/utils/getCookies';

import { TrackingData } from './accountActions.types';

export const getTrackingData: () => TrackingData | undefined = () => {
  const payload: { [key: string]: unknown } = {};

  const firstSessionCookie = getCookie('FirstSession');
  if (firstSessionCookie !== null) {
    const sessionInfo = JSON.parse(
      '{"' + decodeURIComponent(firstSessionCookie).replace(/&/g, '","').replace(/=/g, '":"') + '"}',
      (key, value) => {
        return key === '' ? value : decodeURIComponent(value);
      },
    );

    payload.tracking = {
      source: sessionInfo.source,
      medium: sessionInfo.medium,
      campaign: sessionInfo.campaign,
      content: sessionInfo.content,
      term: sessionInfo.term,
    };
  }

  const _ga = getCookie('_ga');
  if (_ga !== null) {
    payload.google = {
      id: _ga,
    };
  }

  const CHHJSessionId = getCookie('CHHJSessionId');
  if (CHHJSessionId !== null) {
    payload.website_session = {
      id: CHHJSessionId,
    };
  }

  return payload;
};
