import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const FewItemsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="80" height="80" viewBox="0 0 80 80" style={{ fill: 'none' }} fill="none">
      <g clipPath="url(#clip9)">
        <path
          d="M43.9535 29.9535H60.9303C62.2094 29.9535 63.2559 28.7907 63.1396 27.5117L61.2791 10.4186C61.1629 9.25585 60.2326 8.44189 59.0698 8.44189H45.814C44.6512 8.44189 43.721 9.25585 43.6047 10.4186L41.7442 27.5117C41.628 28.7907 42.6745 29.9535 43.9535 29.9535Z"
          fill="#F1CCA5"
        />
        <path
          d="M42.3265 63.9072H62.5591C63.0242 63.9072 63.373 64.2561 63.373 64.7212V66.1165C63.373 66.5816 63.0242 66.9305 62.5591 66.9305H42.3265C41.8614 66.9305 41.5126 66.5816 41.5126 66.1165V64.7212C41.5126 64.2561 41.8614 63.9072 42.3265 63.9072Z"
          fill="#F1CCA5"
        />
        <path
          d="M45.8137 28.2094H63.2556C64.4184 28.2094 65.3486 27.1629 65.2324 26.0001L63.3719 8.44194C63.2556 7.39543 62.4417 6.69775 61.3951 6.69775H47.7905C46.744 6.69775 45.93 7.51171 45.8137 8.44194L43.9533 26.0001C43.7207 27.1629 44.651 28.2094 45.8137 28.2094Z"
          stroke="#EB7200"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M54.5352 62.0467V28.2095"
          stroke="#226937"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M50.3496 62.0464H64.5357C65.0008 62.0464 65.3496 62.3952 65.3496 62.8603V64.2557C65.3496 64.7208 65.0008 65.0696 64.5357 65.0696H44.3031C43.838 65.0696 43.4891 64.7208 43.4891 64.2557V62.9766C43.4891 62.5115 43.838 62.1627 44.3031 62.1627"
          stroke="#EB7200"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M56.7445 48.2095H76.9771C77.4422 48.2095 77.791 48.5583 77.791 49.0234V50.4188C77.791 50.8839 77.4422 51.2327 76.9771 51.2327H56.7445C56.2794 51.2327 55.9305 50.8839 55.9305 50.4188V49.0234C55.9305 48.5583 56.2794 48.2095 56.7445 48.2095Z"
          fill="#F1CCA5"
        />
        <path
          d="M58.9531 51.4653H74.8833C75.3484 51.4653 75.6973 51.8142 75.6973 52.2793V59.9537C75.6973 60.4188 75.3484 60.7677 74.8833 60.7677H58.9531C58.488 60.7677 58.1391 60.4188 58.1391 59.9537V52.2793C58.0228 51.8142 58.488 51.4653 58.9531 51.4653Z"
          fill="#F1CCA5"
        />
        <path
          d="M3.95444 47.2793V40.4188C3.95444 38.2095 5.69863 36.4653 7.90793 36.4653H40.001C42.2103 36.4653 43.9544 38.2095 43.9544 40.4188V47.2793C46.3963 47.2793 48.373 49.256 48.373 51.6979V59.8374V67.5118H0.000953674V59.8374V51.6979C-0.115326 49.3723 1.74514 47.5118 3.95444 47.2793Z"
          fill="#F1CCA5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.9762 64.4883C46.6273 64.4883 46.2785 64.8371 46.2785 65.186V71.6976C46.2785 72.0464 46.6273 72.3953 46.9762 72.3953C47.325 72.3953 47.6738 72.0464 47.6738 71.6976V65.186C47.6738 64.7208 47.325 64.4883 46.9762 64.4883Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.46638 64.4883C5.11755 64.4883 4.76871 64.8371 4.76871 65.186V71.6976C4.76871 72.0464 5.11755 72.3953 5.46638 72.3953C5.81522 72.3953 6.16406 72.0464 6.16406 71.6976V65.186C6.16406 64.7208 5.9315 64.4883 5.46638 64.4883Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.3262 51.349C42.3262 51.0001 42.0936 50.7676 41.7448 50.7676H10.9308C10.582 50.7676 10.3494 51.0001 10.3494 51.349C10.3494 51.6978 10.582 51.9304 10.9308 51.9304H41.7448C41.9773 51.9304 42.3262 51.6978 42.3262 51.349Z"
          fill="#006A36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.512 49.3721C11.512 46.5814 9.30268 44.2559 6.3957 44.2559C3.605 44.2559 1.27943 46.5814 1.27943 49.3721V65.8838H51.0469V49.3721C51.0469 46.5814 48.8376 44.2559 45.9306 44.2559C43.1399 44.2559 40.8143 46.4652 40.8143 49.3721V56.814H11.3957V49.3721H11.512ZM6.3957 45.6512C8.48873 45.6512 10.1166 47.2791 10.1166 49.3721V58.0931H42.3259V49.3721C42.3259 47.2791 43.9538 45.6512 46.0469 45.6512C48.1399 45.6512 49.7678 47.2791 49.7678 49.3721V64.4884H2.79105V49.3721C2.67477 47.2791 4.41896 45.6512 6.3957 45.6512Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.99995 33.4419C7.44181 33.4419 5.34879 35.5349 5.34879 38.0931V44.9535H6.74414V38.0931C6.74414 36.2326 8.25576 34.8372 9.99995 34.8372H42.093C43.9534 34.8372 45.3488 36.3489 45.3488 38.0931V44.9535H46.7441V38.0931C46.7441 35.5349 44.6511 33.4419 42.093 33.4419H9.99995Z"
          fill="#EA7200"
        />
        <path
          d="M58.2562 46.5815H78.4888C78.9539 46.5815 79.3027 46.9304 79.3027 47.3955V48.7908C79.3027 49.256 78.9539 49.6048 78.4888 49.6048H58.2562C57.7911 49.6048 57.4423 49.256 57.4423 48.7908V47.3955C57.4423 46.9304 57.7911 46.5815 58.2562 46.5815Z"
          stroke="#EB7200"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M60.3496 49.8374H76.2798C76.7449 49.8374 77.0938 50.1862 77.0938 50.6514V58.3258C77.0938 58.7909 76.7449 59.1397 76.2798 59.1397H60.3496C59.8844 59.1397 59.5356 58.7909 59.5356 58.3258V50.6514C59.5356 50.1862 59.8844 49.8374 60.3496 49.8374Z"
          stroke="#EB7200"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M61.9769 72.3955V59.1396H74.7676V72.3955"
          stroke="#EB7200"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M70.6973 52.9766H65.9298"
          stroke="#226937"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip9">
          <rect width="80" height="67.093" fill="white" transform="matrix(-1 0 0 1 80 6)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
