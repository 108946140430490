export enum AppRoute {
  home = '/get-started/',
  junkRemoval = '/get-started/junk-removal',
  moving = '/get-started/moving',
  labor = '/get-started/labor',
  callMe = '/get-started/call-me',
}

export enum CommonRoute {
  unserviced = '/not-serviced/',
  processingRequest = '/processing/',
  allDone = '/confirmation/',
}

export enum JunkRemovalRoute {
  step1 = '/your-pick-up-location/',
  step2 = '/your-contact-information/',
  step3 = '/calendar-windows/',
  step4 = '/kind-of-items/',
  step5 = '/truck-loads/',
  step6 = '/summary/',
}

export enum MovingRoute {
  step1 = '/starting-address/',
  step2 = '/ending-address/',
  step3 = '/special-items/',
  step4 = '/calendar-windows/',
  step5 = '/your-contact-information/',
  step6 = '/summary/',
}

export enum LaborRoute {
  step1 = '/your-pick-up-location/',
  step2 = '/your-contact-information/',
  step3 = '/summary/',
}

export const junkRemovalRoutes = {
  step1: `${AppRoute.junkRemoval}${JunkRemovalRoute.step1}`,
  step2: `${AppRoute.junkRemoval}${JunkRemovalRoute.step2}`,
  step3: `${AppRoute.junkRemoval}${JunkRemovalRoute.step3}`,
  step4: `${AppRoute.junkRemoval}${JunkRemovalRoute.step4}`,
  step5: `${AppRoute.junkRemoval}${JunkRemovalRoute.step5}`,
  step6: `${AppRoute.junkRemoval}${JunkRemovalRoute.step6}`,
  unserviced: `${AppRoute.junkRemoval}${CommonRoute.unserviced}`,
  processing: `${AppRoute.junkRemoval}${CommonRoute.processingRequest}`,
  alldone: `${AppRoute.junkRemoval}${CommonRoute.allDone}`,
};

export const movingRoutes = {
  step1: `${AppRoute.moving}${MovingRoute.step1}`,
  step2: `${AppRoute.moving}${MovingRoute.step2}`,
  step3: `${AppRoute.moving}${MovingRoute.step3}`,
  step4: `${AppRoute.moving}${MovingRoute.step4}`,
  step5: `${AppRoute.moving}${MovingRoute.step5}`,
  step6: `${AppRoute.moving}${MovingRoute.step6}`,
  unserviced: `${AppRoute.moving}${CommonRoute.unserviced}`,
  processing: `${AppRoute.moving}${CommonRoute.processingRequest}`,
  alldone: `${AppRoute.moving}${CommonRoute.allDone}`,
};

export const laborRoutes = {
  step1: `${AppRoute.labor}${LaborRoute.step1}`,
  step2: `${AppRoute.labor}${LaborRoute.step2}`,
  step3: `${AppRoute.labor}${LaborRoute.step3}`,
  unserviced: `${AppRoute.labor}${CommonRoute.unserviced}`,
  processing: `${AppRoute.labor}${CommonRoute.processingRequest}`,
  alldone: `${AppRoute.labor}${CommonRoute.allDone}`,
};
