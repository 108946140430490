import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const RecyclingIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="49" height="49" viewBox="0 0 49 49" fill="none">
      <g clipPath="url(#clip3)">
        <path
          d="M11.6787 11.5604L15.3311 4.83435C15.9418 3.69592 17.117 2.9873 18.4075 2.9873L28.1204 3.057L20.7234 16.8111L11.6787 11.5604Z"
          fill="#F1CCA5"
        />
        <path
          d="M37.5912 17.5547L42.1423 7.88963L39.2158 9.4695L36.7616 4.84607C36.151 3.70764 34.9757 2.99902 33.6853 2.99902L23.9839 3.08034L30.0098 14.4414L27.0833 16.0213L37.5912 17.5547Z"
          fill="#F1CCA5"
        />
        <path
          d="M21.0922 44.0988L13.4878 44.273C12.2088 44.3195 10.9991 43.6341 10.3654 42.5073L5.57227 33.9923L21.0807 33.5741V44.0988H21.0922Z"
          fill="#F1CCA5"
        />
        <path
          d="M13.2918 18.4724L2.7147 19.332L5.52603 21.0978L2.78383 25.547C2.10404 26.6505 2.09252 28.0329 2.73774 29.1481L7.65757 37.5934L14.3979 26.6505L17.2092 28.4163L13.2918 18.4724Z"
          fill="#F1CCA5"
        />
        <path
          d="M43.0295 19.0416L46.97 25.5934C47.6498 26.697 47.6613 28.0794 47.0161 29.1946L42.0962 37.6399L33.9849 24.304L43.0295 19.0416Z"
          fill="#F1CCA5"
        />
        <path
          d="M24.9287 38.662L30.9546 47.4675L31.0583 44.1219L36.2547 44.2961C37.5336 44.3426 38.7434 43.6572 39.3771 42.5304L44.1702 34.0154L31.404 33.5972L31.5077 30.2516L24.9287 38.662Z"
          fill="#F1CCA5"
        />
        <path
          d="M10.6392 9.7933L14.2916 3.06726C14.9022 1.92883 16.0775 1.22021 17.3679 1.22021L27.0808 1.28991H22.956L25.0069 5.15826L19.6953 15.044L10.6392 9.7933Z"
          stroke="#EA7200"
          strokeWidth="1.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M36.5519 15.7875L41.1031 6.12244L38.1765 7.69069L35.7224 3.06726C35.1117 1.92883 33.9365 1.22021 32.646 1.22021L22.9331 1.28991L28.959 12.651L26.0325 14.2309L36.5519 15.7875Z"
          stroke="#EA7200"
          strokeWidth="1.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.0523 42.32L12.4479 42.4942C11.169 42.5407 9.9592 41.8553 9.3255 40.7285L6.57178 35.8379L8.91071 32.0973L20.0639 31.7953V42.32H20.0523Z"
          stroke="#EA7200"
          strokeWidth="1.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.2522 16.6936L1.67515 17.5532L4.48648 19.319L1.74428 23.7681C1.06449 24.8717 1.05297 26.2541 1.69819 27.3693L6.61802 35.8146L13.3583 24.8717L16.1696 26.6375L12.2522 16.6936Z"
          stroke="#EA7200"
          strokeWidth="1.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.9902 17.2628L45.9306 23.8146C46.6104 24.9182 46.6219 26.3006 45.9767 27.4158L43.1539 32.2715L38.7871 32.1089L32.957 22.5252L41.9902 17.2628Z"
          stroke="#EA7200"
          strokeWidth="1.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.8892 36.8832L29.9151 45.6886L30.0188 42.343L35.2151 42.5173C36.4941 42.5638 37.7039 41.8784 38.3376 40.7516L43.1306 32.2482L30.3644 31.83L30.4681 28.4844L23.8892 36.8832Z"
          stroke="#EA7200"
          strokeWidth="1.1"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip3">
          <rect width="48" height="48" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
