import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { laborRoutes } from 'common/routing/AppRoute';
import { LayoutRoute } from 'common/ui/layoutRoute/LayoutRoute';

import { LaborStep1 } from './pages/laborStep1/LaborStep1';
import { LaborStep2 } from './pages/laborStep2/LaborStep2';
import { LaborStep3 } from './pages/laborStep3/LaborStep3';
import { LaborProcessingRequest } from './LaborProcessingRequest';
import { LaborAllDone } from './LaborAllDone';
import { LaborUnserviced } from './LaborUnserviced';

export const LaborRoutes = () => {
  return (
    <Switch>
      <LayoutRoute exact type="labor" path={laborRoutes.step1} component={LaborStep1} />
      <LayoutRoute exact type="labor" path={laborRoutes.step2} component={LaborStep2} />
      <LayoutRoute exact type="labor" path={laborRoutes.step3} component={LaborStep3} />
      <Route exact path={laborRoutes.unserviced} component={LaborUnserviced} />
      <Route exact path={laborRoutes.processing} component={LaborProcessingRequest} />
      <Route exact path={laborRoutes.alldone} component={LaborAllDone} />
      <Redirect to={laborRoutes.step1} />
    </Switch>
  );
};
