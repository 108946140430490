import React, { useMemo } from 'react';
import { Typography, Box, Link } from '@material-ui/core';

import { LoadedImage } from 'common/ui/loadedImage/LoadedImage';
import { currencyFormatter } from 'common/utils/currencyFormatter';
import { applyDiscountToPrice } from '../JunkRemovalStep5.utils';

import { useStyles } from './JunkRemovalStep5ItemForPartners.styles';
import { JunkRemovalStep5ItemForPartnersProps } from './JunkRemovalStep5ItemForPartners.types';

const formatCurrency = currencyFormatter({ minimumFractionDigits: 0 });

export const JunkRemovalStep5ItemForPartners = ({
  price,
  title,
  imgSrc,
  discountData,
}: JunkRemovalStep5ItemForPartnersProps) => {
  const styles = useStyles();

  const newPrice = useMemo(() => {
    if (!discountData || !price) {
      return;
    }

    return applyDiscountToPrice(price, discountData.discount);
  }, [discountData, price]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.imgAndTitleWrapper}>
        {imgSrc && <LoadedImage src={imgSrc} alt={title} className={styles.img} />}

        <Typography className={styles.title}>{title}</Typography>
      </div>
      <Box ml="auto" />

      {newPrice && (
        <>
          <Typography variant="h5" className={styles.crossedText}>{`Approx. ${
            price ? formatCurrency.format(price) : '-'
          }`}</Typography>

          <Typography color="primary" variant="h5" className={styles.price}>
            {formatCurrency.format(newPrice)}
          </Typography>
        </>
      )}
      {!newPrice && discountData?.discountDetails?.type === 'url' && (
        <Link target="_blank" rel="noreferrer" href={discountData?.discountDetails.details} className={styles.price}>
          <Typography color="primary" variant="h5">
            Check price
          </Typography>
        </Link>
      )}

      {!newPrice && discountData?.discountDetails?.type === 'html' && (
        <Typography color="primary" variant="h5" className={styles.price}>
          <span dangerouslySetInnerHTML={{ __html: discountData?.discountDetails.details }} />
        </Typography>
      )}

      {!newPrice && !discountData?.discountDetails && (
        <Typography color="primary" variant="h5" className={styles.price}>
          {`Approx. ${price ? formatCurrency.format(price) : '-'}`}
        </Typography>
      )}
    </div>
  );
};
