import React from 'react';
import { FormControl, FormHelperText } from '@material-ui/core';
import { Input } from '@chhjpackages/components';

import { CheckIcon } from 'common/assets/icons/CheckIcon';

import { useStyles } from './ContactInformationInput.styles';
import { ContactInformationInputProps } from './ContactInformationInput.types';

export const ContactInformationInput = ({
  error,
  inputRef,
  name,
  label,
  touched,
  mask,
  inputMode,
  rows,
  helperText,
}: ContactInformationInputProps) => {
  const styles = useStyles();

  return (
    <FormControl fullWidth error={!!error} className={styles.margin}>
      <Input
        endAdornment={touched && !error && <CheckIcon className={styles.checkIcon} />}
        name={name}
        label={label}
        size="medium"
        variant="outlined"
        error={!!error}
        mask={mask}
        rows={rows}
        multiline={!!rows}
        inputRef={inputRef}
        inputProps={{ inputMode }}
        className={styles.input}
      />
      <FormHelperText className={styles.errorText}>{error?.message || helperText}</FormHelperText>
    </FormControl>
  );
};
