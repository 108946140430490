import moment, { Moment } from 'moment';

import { USADateFormat } from 'common/utils/dateTime';

const createDateAndText = (text: string, newDate: Moment) => {
  return {
    date: newDate.toDate(),
    text: `${text} (${newDate.format(USADateFormat)})`,
  };
};

export const dropdownData = [
  {
    key: 'today',
    data: createDateAndText('today', moment()),
  },
  {
    key: 'tomorrow',
    data: createDateAndText('tomorrow', moment().add(1, 'day')),
  },
  {
    key: '2 days',
    data: createDateAndText('in 2 days', moment().add(2, 'day')),
  },
  {
    key: 'calendar',
    data: {
      date: null,
      text: 'pick date from the calendar',
    },
  },
];
