import React from 'react';
import { colors } from '@chhjpackages/components';
import { SvgIcon } from '@material-ui/core';

export const StairsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="49" height="42" viewBox="0 0 49 42" fill="none">
      <path
        d="M30.6947 6.68146V13.6689H20.6455C20.2711 13.6689 19.964 13.976 19.964 14.3503V21.3665H9.89562C9.5213 21.3665 9.21416 21.6737 9.21416 22.048V29.045H0.681464C0.307139 29.045 0 29.3521 0 29.7265V38.0576C0 38.4319 0.307139 38.739 0.681464 38.739H44.4391C44.8134 38.739 45.1206 38.4319 45.1206 38.0576V30.2927V29.045V22.6047V21.3665V14.9358V13.6785V6.68146C45.1206 6.30714 44.8134 6 44.4391 6H31.3761C31.0018 6 30.6947 6.29754 30.6947 6.68146Z"
        fill={colors.primary.light}
      />
      <path
        d="M3.5031 41.2046C3.15757 41.2046 2.87923 40.9262 2.87923 40.5807V32.1344C2.87923 31.7888 3.15757 31.5105 3.5031 31.5105H5.95061V22.3635L1.47791 25.6749C1.34353 25.7804 1.19956 25.7996 1.11318 25.7996C0.911619 25.7996 0.729256 25.7037 0.614079 25.5501C0.412519 25.2717 0.470107 24.8782 0.748452 24.6767L33.881 0.124775C33.9578 0.0671866 34.0826 0 34.2554 0C37.6243 0.00959808 40.1486 0.00959808 42.0298 0.00959808C45.8691 0.00959808 47.2608 0.0191962 47.5391 0.0479904C47.5583 0.0575885 47.5679 0.0575885 47.5967 0.0671866C47.8271 0.153569 47.9806 0.364727 47.9998 0.604679C48.0094 1.52609 47.9998 37.5477 47.9998 40.5807C47.9998 40.9262 47.7215 41.2046 47.376 41.2046H3.5031ZM4.12698 39.9568H28.986V24.4271C28.986 24.0816 29.2644 23.8032 29.6099 23.8032H38.8241C39.1696 23.8032 39.4479 24.0816 39.4479 24.4271V39.9568H46.7425V9.72286H34.8601V16.7774C34.8601 17.123 34.5817 17.4013 34.2362 17.4013H24.091V24.4559C24.091 24.8014 23.8127 25.0798 23.4671 25.0798H13.3411V32.1344C13.3411 32.4799 13.0628 32.7582 12.7173 32.7582H4.12698V39.9568ZM38.2098 32.2495V25.051H30.2434V39.928H38.2098V33.4973V32.2495ZM7.19837 21.4421V31.5105H12.0934V24.4559C12.0934 24.1104 12.3717 23.832 12.7173 23.832H15.1648V15.5393L7.19837 21.4421ZM16.4125 14.6083V23.832H22.8432V16.7774C22.8432 16.4319 23.1216 16.1536 23.4671 16.1536H25.9146V7.57289L16.4125 14.6083ZM27.1624 6.65147V16.1536H33.6027V9.09898C33.6027 8.75345 33.881 8.47511 34.2266 8.47511H36.6645V1.24775H34.4569L27.1624 6.65147ZM37.9122 8.47511H46.7425V1.27654L37.9122 1.25735V8.47511Z"
        fill={colors.primary.main}
      />
      <path
        d="M36 34.1641C36.5523 34.1641 37 33.7164 37 33.1641C37 32.6118 36.5523 32.1641 36 32.1641C35.4477 32.1641 35 32.6118 35 33.1641C35 33.7164 35.4477 34.1641 36 34.1641Z"
        fill={colors.secondary.main}
      />
    </SvgIcon>
  );
};
