import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: colors.basic.white,
    height: 72,
    padding: '8px 0 11px',
    boxShadow: '0px 0px 24px rgba(134, 145, 166, 0.32)',
  },
  logo: {
    height: '100%',
    marginLeft: 24,

    '&:hover': {
      cursor: 'pointer',
    },

    [theme.breakpoints.up('sm')]: {
      marginLeft: 48,
    },
  },
  partnerLogo: {
    height: '80%',
    marginLeft: 16,

    [theme.breakpoints.up('sm')]: {
      height: '100%',
      marginLeft: 24,
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: 48,
    },
  },
  img: {
    height: '100%',
  },
  icon: {
    color: 'inherit',
    marginRight: 4,
    fontSize: 24,
  },
  phoneNumber: {
    marginRight: 24,
    color: colors.secondary.main,

    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',

    [theme.breakpoints.up('sm')]: {
      marginRight: 48,
    },
  },
  partnerPhoneNumber: {
    marginRight: 16,

    [theme.breakpoints.up('sm')]: {
      marginRight: 24,
    },

    [theme.breakpoints.up('md')]: {
      marginRight: 48,
    },
  },
}));
