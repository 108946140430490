import { Action as BaseAction } from 'react-fetching-library';

export type AdditionalProperties = {
  disableInterceptErrors?: boolean;
};

export type Action<R = unknown> = BaseAction<R, AdditionalProperties>;

export type BasicResponseStructure = {
  meta: {
    series: string;
    instance: string;
    run_time: number;
    status: {
      id: number;
      code: number;
      name: string;
      description: string;
    };
    errors?: Array<{
      message: string;
    }>;
  };
};

export enum CategoryIdEnum {
  junk = 1,
  labor = 4,
  move = 5,
}

export enum AccessTypeEnum {
  Unset = 0,
  'Front door' = 1,
  Stairs = 2,
  Elevator = 3,
}

export enum TypeOfDwellingEnum {
  Unknown = 0,
  House = 1,
  Apartment = 2,
  Condo = 3,
  Townhome = 4,
  StorageUnit = 5,
  Business = 6,
}

export enum LongWalksEnum {
  zero = 0,
  one = 1,
  two = 2,
}
