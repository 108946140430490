import queryString from 'query-string';

import { Action } from 'common/api/types';

import {
  CheckPostalCodePayload,
  CheckPostalCodeResponse,
  CheckAvailabilityPayload,
  CheckAvailabilityResponse,
  GetPricingPayload,
  GetPricingResponse,
} from './bookingActions.types';

export const checkPostalCodeAction = (values: CheckPostalCodePayload): Action<CheckPostalCodeResponse> => {
  return {
    method: 'POST',
    endpoint: '/public/website/servicecheck',
    body: values,
    config: {
      disableInterceptErrors: true,
    },
  };
};

export const checkAvailabilityAction = (values: CheckAvailabilityPayload): Action<CheckAvailabilityResponse> => {
  const { locationId, ...restValues } = values;

  return {
    method: 'POST',
    endpoint: `/public/website/locations/${locationId}/availability`,
    body: { ...restValues, type: 'JOB', duration: restValues.duration ?? 2, number_of_days: 1 }, // added values are constant
  };
};

export const getPricingAction = (values: GetPricingPayload): Action<GetPricingResponse> => {
  const { locationId, ...restValues } = values;

  const urlParams = queryString.stringify({ ...restValues });

  return {
    method: 'GET',
    endpoint: `/public/website/locations/${locationId}/viewpricingbyproduct?${urlParams}`,
  };
};
