import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    border: `1px solid ${colors.grey30}`,
    backgroundColor: colors.grey20,
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: 16,
    maxWidth: 343,
    margin: '32px 0 48px 0',

    [theme.breakpoints.up('sm')]: {
      marginBottom: 56,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 64,
      maxWidth: 376,
    },
  },
  fullWidth: {
    maxWidth: 'none',
  },
  icon: {
    fontSize: 48,
    fill: 'none',
    marginRight: 16,
  },
}));
