import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    backgroundColor: colors.grey10,
    paddingTop: 40,
    paddingBottom: 60,

    [theme.breakpoints.up('sm')]: {
      paddingTop: 60,
    },

    [theme.breakpoints.up('md')]: {
      paddingTop: 48,
      paddingBottom: 48,
    },
  },
  innerWrapper: {
    margin: '0 26.5px',
  },
  sliderItem: {
    padding: '0 31px',
    outline: 'none',
  },
  mainTitle: {
    textAlign: 'center',
    marginBottom: 28,

    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 48,
      marginLeft: 32,
    },
  },
  icon: {
    fontSize: 80,
    display: 'block',
    margin: '0 auto 7px',

    [theme.breakpoints.up('sm')]: {
      marginBottom: 16,
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: 6,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 16,
    },
  },
  subTitle: {
    textAlign: 'center',
    maxWidth: 240,
    margin: '0 auto',
  },
}));
