import * as rudderanalytics from 'rudder-sdk-js';

import { AccountResponse } from 'common/api/actions/account/accountActions.types';

rudderanalytics.load('1oMRILB1RD2ENmXXIj3W5pZQckc', 'https://analytics.collegehunkshaulingjunk.com');
// rudderanalytics.ready(() => {console.log("we are all set!!!")})
export { rudderanalytics };

export const mapAccountToSegmentTraits = (account: AccountResponse) => ({
  firstName: account.first_name,
  lastName: account.last_name,
  address: {
    street: `${account.billing_address.address} ${account.billing_address.address2}`.trim(),
    city: account.billing_address.city,
    postalCode: account.billing_address.postal,
    state: account.billing_address.state,
    country: account.billing_address.country,
  },
  email: account.email,
  id: account.id,
  phone: account.phone,
  locationId: account.location.id,
  categoryId: account.category.id,
});
