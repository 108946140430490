import React, { useCallback } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { Button, CalendarPicker } from '@chhjpackages/components';
import { useForm, Controller } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import moment, { Moment } from 'moment';

import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { TipBox } from 'common/ui/tipBox/TipBox';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';

import { useStyles } from './MovingStep4.styles';

export const MovingStep4 = () => {
  const styles = useStyles();
  const history = useHistory();
  const [{ moving }, { movingDispatch }] = useMovingContext();
  const { handleSubmit, control } = useForm({
    defaultValues: { date: moment(moving?.step4?.date) },
  });

  const handleSubmitCallback = useCallback(
    (values: { date: Moment }) => {
      movingDispatch(movingActions.setStep4({ date: values.date.toDate() }));
      history.push(movingRoutes.step5);
    },
    [movingDispatch, history],
  );

  if (!moving?.step3) {
    return <Redirect to={movingRoutes.step3} />;
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className={styles.form}>
      <Grid container direction="column" className={styles.content}>
        <Grid item>
          <Typography variant="h4">4. job date</Typography>
        </Grid>

        <Box mb={3} />

        <Grid item>
          <Typography variant="body1">Select the day that works best for you for our HUNKS to arrive</Typography>
        </Grid>

        <Grid item>
          <Controller
            name="date"
            control={control}
            rules={{ required: '*required' }}
            render={({ onChange, value }) => (
              <CalendarPicker
                onChange={onChange}
                date={value}
                minDate={moment().toDate()}
                className={styles.calendar}
              />
            )}
          />
        </Grid>
      </Grid>

      <TipBox iconName="labor">No job is too big or too small for College HUNKS Hauling Junk & Moving.</TipBox>

      <ButtonsGroup>
        <Button onClick={() => history.push(movingRoutes.step3)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>

        <Button type="submit" fullWidth size="large" buttonType="twoTone">
          Next
        </Button>
      </ButtonsGroup>
    </form>
  );
};
