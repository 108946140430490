import React, { useReducer, useEffect } from 'react';

import { FormDispatchContext, FormStateContext } from '../formContext/FormContext';
import { formReducer } from 'common/context/form/formReducer/formReducer';
import { formStorage } from '../formStorage/FormStorage';

import { FormContextControllerProps } from './FormContextController.types';

export const FormContextController = ({ children }: FormContextControllerProps) => {
  const [state, dispatch] = useReducer(formReducer, formStorage.formState);

  useEffect(() => {
    formStorage.formState = state;
  }, [state]);

  return (
    <FormStateContext.Provider value={state}>
      <FormDispatchContext.Provider value={dispatch}>{children}</FormDispatchContext.Provider>
    </FormStateContext.Provider>
  );
};
