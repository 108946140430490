import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { junkRemovalRoutes } from 'common/routing/AppRoute';
import { LayoutRoute } from 'common/ui/layoutRoute/LayoutRoute';

import { JunkRemovalStep1 } from './pages/junkRemovalStep1/JunkRemovalStep1';
import { JunkRemovalStep2 } from './pages/junkRemovalStep2/JunkRemovalStep2';
import { JunkRemovalStep3 } from './pages/junkRemovalStep3/JunkRemovalStep3';
import { JunkRemovalStep4 } from './pages/junkRemovalStep4/JunkRemovalStep4';
import { JunkRemovalStep5 } from './pages/junkRemovalStep5/JunkRemovalStep5';
// import { JunkRemovalStep6 } from './pages/junkRemovalStep5/JunkRemovalStep6';
import { JunkRemovalProcessingRequest } from './JunkRemovalProcessingRequest';
import { JunkRemovalAllDone } from './JunkRemovalAllDone';
import { JunkRemovalUnserviced } from './JunkRemovalUnserviced';

// TODO SUMMARY: commented summary step in junk removal for now
export const JunkRemovalRoutes = () => {
  return (
    <Switch>
      <LayoutRoute exact type="junkRemoval" path={junkRemovalRoutes.step1} component={JunkRemovalStep1} />
      <LayoutRoute exact type="junkRemoval" path={junkRemovalRoutes.step2} component={JunkRemovalStep2} />
      <LayoutRoute exact type="junkRemoval" path={junkRemovalRoutes.step3} component={JunkRemovalStep3} />
      <LayoutRoute exact type="junkRemoval" path={junkRemovalRoutes.step4} component={JunkRemovalStep4} />
      <LayoutRoute exact type="junkRemoval" path={junkRemovalRoutes.step5} component={JunkRemovalStep5} />
      {/* <LayoutRoute exact type="junkRemoval" path={junkRemovalRoutes.step6} component={JunkRemovalStep6} /> */}
      <Route exact path={junkRemovalRoutes.unserviced} component={JunkRemovalUnserviced} />
      <Route exact path={junkRemovalRoutes.processing} component={JunkRemovalProcessingRequest} />
      <Route exact path={junkRemovalRoutes.alldone} component={JunkRemovalAllDone} />

      <Redirect to={junkRemovalRoutes.step1} />
    </Switch>
  );
};
