import React from 'react';
import { Dialog, Box, Typography, Grid, FormControl, FormHelperText, useTheme, useMediaQuery } from '@material-ui/core';
import { Input, Button, validatePhone } from '@chhjpackages/components';
import { useForm } from 'react-hook-form';

import { ModalMobileTransition } from 'common/ui/modalMobileTransition/ModalMobileTransition';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { useJunkRemovalStateContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';

import { useStyles } from './WeWillTextModal.styles';
import { WeWillTextModalProps } from './WeWillTextModal.types';

// B: Junk Removal version B component
export const WeWillTextModal = ({ onSubmit, open, onClose, isLoading }: WeWillTextModalProps) => {
  const styles = useStyles();
  const { junkRemoval } = useJunkRemovalStateContext();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const { handleSubmit, register, errors } = useForm({
    mode: 'onBlur',
    defaultValues: { phone: junkRemoval?.step2?.phone || '' },
  });

  return (
    <Dialog
      fullScreen={isMobile}
      open={open}
      onClose={onClose}
      TransitionComponent={isMobile ? ModalMobileTransition : undefined}
      className={styles.dialog}
    >
      <Grid
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        className={styles.form}
        container
        spacing={2}
        direction="column"
      >
        <Grid item>
          <Typography variant="h2">
            We'll text/call
            <br /> you when we are <br /> on our way
          </Typography>
        </Grid>
        <Grid item>
          <Typography>
            We need a way to contact you in order to do our job. We respect your privacy and will never sell or share
            your data.
          </Typography>
        </Grid>

        <Box mb={1} />

        <Grid item>
          <FormControl fullWidth error={!!errors.phone}>
            <Input
              name="phone"
              label="Phone number"
              size="medium"
              variant="outlined"
              mask="phone"
              inputProps={{ inputMode: 'numeric' }}
              error={!!errors.phone}
              inputRef={register({
                required: '*required',
                validate: (value) => validatePhone(value) || '*please provide proper phone number',
              })}
            />
            <FormHelperText className={styles.inputError}>{errors.phone?.message}</FormHelperText>
          </FormControl>
        </Grid>

        <Grid item>
          <ButtonsGroup className={styles.buttonsGroup}>
            <Button onClick={onClose} fullWidth size="large" buttonType="outlined">
              Back
            </Button>
            <Button type="submit" isLoading={isLoading} fullWidth size="large" buttonType="twoTone">
              Book appointment
            </Button>
          </ButtonsGroup>
        </Grid>
      </Grid>
    </Dialog>
  );
};
