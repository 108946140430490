import React, { useEffect, useRef, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Typography, Link, Grid, Hidden } from '@material-ui/core';
import { Button } from '@chhjpackages/components';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { AppRoute } from 'common/routing/AppRoute';
import { HandsIcon } from 'common/assets/icons/HandsIcon';
import { applyPhoneMask } from 'common/utils/form';
import { useResetState } from 'common/hooks/useResetState/useResetState';

import { useStyles } from './AllDone.styles';
import { AllDoneProps } from './AllDone.types';

export const AllDone = ({ title, subtitle, reschedule, altTitle, isAnotherServiceBtnVisible }: AllDoneProps) => {
  const styles = useStyles();
  const history = useHistory();
  const formState = useFormState();
  const resetState = useResetState();
  const { contactData, bookingDone } = useRef(formState).current;

  useEffect(() => {
    resetState();
  }, [resetState]);

  const formattedContact = useMemo(() => {
    if (!contactData) {
      return '';
    }
    if (contactData.type === 'phone') {
      return applyPhoneMask(contactData.contact);
    }
    return contactData.contact;
  }, [contactData]);

  if (!bookingDone) {
    return <Redirect to={`${AppRoute.home}`} />;
  }

  return (
    <div className={styles.bgWrapper}>
      <Grid container spacing={2} className={styles.wrapper}>
        <Grid item>
          <HandsIcon className={styles.icon} />
        </Grid>

        <Grid item>
          <Typography variant="h2" className={styles.title}>
            {altTitle ? 'Almost Done!' : 'All done!'}{' '}
            <Hidden smUp>
              <br />
            </Hidden>
            {title}
          </Typography>
        </Grid>

        <Grid item>
          <Typography>{subtitle}</Typography>
        </Grid>

        <Grid item>
          <Typography color="secondary" className={styles.contact}>
            {formattedContact}
          </Typography>
        </Grid>

        {reschedule && (
          <Grid item>
            <Typography className={styles.text}>
              If your plans have changed, you can reschedule the appointment{' '}
              <Link color="secondary" href="https://customer.collegehunkshaulingjunk.com" className={styles.link}>
                here
              </Link>
            </Typography>
          </Grid>
        )}

        <Grid item className={styles.buttonsWrapper}>
          {isAnotherServiceBtnVisible && (
            <Button onClick={() => history.push(AppRoute.home)} fullWidth size="large" buttonType="twoTone">
              book another service
            </Button>
          )}

          <Button
            href="https://www.collegehunkshaulingjunk.com"
            fullWidth
            size="large"
            buttonType={isAnotherServiceBtnVisible ? 'outlined' : 'twoTone'}
          >
            back to main site
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
