import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { movingDefaultState, movingStorage } from './MovingContextStorage';
import { SetMovingStep1, SetMovingStep2, SetMovingStep3, SetMovingStep4, SetMovingStep5 } from './MovingContext.types';

export const { actions: movingActions, reducer: movingReducer } = createSlice({
  name: 'movingSlice',
  initialState: movingDefaultState,
  reducers: {
    setStep1: (state, { payload }: PayloadAction<SetMovingStep1>) => {
      state.moving.step1 = payload;
    },
    setStep2: (state, { payload }: PayloadAction<SetMovingStep2>) => {
      state.moving.step2 = payload;
    },
    setStep3: (state, { payload }: PayloadAction<SetMovingStep3>) => {
      state.moving.step3 = payload;
    },
    setStep4: (state, { payload }: PayloadAction<SetMovingStep4>) => {
      state.moving.step4 = payload;
    },
    setStep5: (state, { payload }: PayloadAction<SetMovingStep5>) => {
      state.moving.step5 = payload;
    },
    reset: () => {
      movingStorage.clear();
      return movingDefaultState;
    },
  },
});
