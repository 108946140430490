import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DropdownIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M7.66667 12L3.91389 5.5L11.4194 5.5L7.66667 12Z" fill="currentColor" />
    </SvgIcon>
  );
};
