import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  map: {
    width: '100%',
    height: 90,

    [theme.breakpoints.up('md')]: {
      height: 114,
    },
  },
}));
