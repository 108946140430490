import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  dialog: {
    position: 'relative',

    '& .MuiDialog-paper': {
      width: '100vw',
      margin: 0,
      overflow: 'hidden',

      [theme.breakpoints.up('sm')]: {
        maxWidth: 376,
      },
    },
  },
  form: {
    padding: 24,
    overflow: 'hidden',
  },
  inputError: {
    margin: '4px 0 6px',
    lineHeight: '14px',
    fontSize: 14,
    height: 14,
  },
  buttonsGroup: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'space-between',

      '& > *:nth-child(1)': {
        flexBasis: 'calc(50%) - 4px', // minus 4px: half of 8px space
      },
      '& > *:nth-child(2)': {
        flexBasis: 'calc(50% + 9px - 4px)', // 9px cause of primary button shadow (7px) + border(2x1px),
        // minus 4px: half of 8px space
      },
    },
  },
}));
