import { useContext } from 'react';

import { FormStateContextType } from 'common/context/form/formContext/FormContext.types';
import { FormStateContext } from 'common/context/form/formContext/FormContext';

type UseFormState = () => FormStateContextType;

export const useFormState: UseFormState = () => {
  const context = useContext(FormStateContext);

  if (context === undefined) {
    throw new Error('useFormState must be used within an FormContextController');
  }

  return context;
};
