import { AppRoute, CommonRoute } from 'common/routing/AppRoute';

export enum MovingRoute {
  step1 = '/starting-address/',
  step2 = '/ending-address/',
  step3 = '/special-items/',
  step4 = '/calendar-windows/',
  step5 = '/your-contact-information/',
  step6 = '/job-time/',
  step7 = '/pricing/',
  step8 = '/summary/',
  prepayment = '/prepayment/',
  alldonePrepayment = '/prepayment-confirmation/',
  alldonePrepaymentError = '/prepayment-declined/',
}

export const movingRoutes = {
  step1: `${AppRoute.moving}${MovingRoute.step1}`,
  step2: `${AppRoute.moving}${MovingRoute.step2}`,
  step3: `${AppRoute.moving}${MovingRoute.step3}`,
  step4: `${AppRoute.moving}${MovingRoute.step4}`,
  step5: `${AppRoute.moving}${MovingRoute.step5}`,
  step6: `${AppRoute.moving}${MovingRoute.step6}`,
  step7: `${AppRoute.moving}${MovingRoute.step7}`,
  step8: `${AppRoute.moving}${MovingRoute.step8}`,
  prepayment: `${AppRoute.moving}${MovingRoute.prepayment}`,
  unserviced: `${AppRoute.moving}${CommonRoute.unserviced}`,
  processing: `${AppRoute.moving}${CommonRoute.processingRequest}`,
  alldone: `${AppRoute.moving}${CommonRoute.allDone}`,
  alldonePrepayment: `${AppRoute.moving}${MovingRoute.alldonePrepayment}`,
  alldonePrepaymentError: `${AppRoute.moving}${MovingRoute.alldonePrepaymentError}`,
};
