import { Typography } from '@material-ui/core';
import React from 'react';

import { LoadedImage } from 'common/ui/loadedImage/LoadedImage';
import { currencyFormatter } from 'common/utils/currencyFormatter';

import { useStyles } from './JunkRemovalStep5Item.styles';
import { JunkRemovalStep5ItemProps } from './JunkRemovalStep5Item.types';

const formatCurrency = currencyFormatter({ minimumFractionDigits: 0 });

export const JunkRemovalStep5Item = ({ price, title, imgSrc }: JunkRemovalStep5ItemProps) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      {imgSrc && <LoadedImage src={imgSrc} alt={title} className={styles.img} />}

      <Typography className={styles.title}>{title}</Typography>

      <Typography variant="h5" className={styles.price}>
        {`Approx. ${price ? formatCurrency.format(price) : '-'}`}
      </Typography>
    </div>
  );
};
