import { Box, Typography } from '@material-ui/core';
import { RadioButtonIcon } from '@chhjpackages/components';
import React from 'react';
import clsx from 'clsx';

import { useStyles } from './JunkRemovalStep4Card.styles';
import { JunkRemovalStep4CardProps } from './JunkRemovalStep4Card.types';

export const JunkRemovalStep4Card = ({ onClick, isSelected, title, icon }: JunkRemovalStep4CardProps) => {
  const styles = useStyles();

  return (
    <div className={clsx(styles.wrapper, isSelected && styles.selected)} onClick={onClick}>
      <Box display="flex" alignItems="center">
        <RadioButtonIcon checked={isSelected} />
        <Typography variant="h5" className={styles.title}>
          {title}
        </Typography>
      </Box>
      <div className={styles.iconContainer}>{icon}</div>
    </div>
  );
};
