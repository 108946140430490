import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SetLaborStep1, SetLaborStep2 } from './LaborContext.types';
import { laborDefaultState, laborStorage } from './LaborContextStorage';

export const { actions: laborActions, reducer: laborReducer } = createSlice({
  name: 'laborSlice',
  initialState: laborDefaultState,
  reducers: {
    setStep1: (state, { payload }: PayloadAction<SetLaborStep1>) => {
      state.labor.step1 = payload;
    },
    setStep2: (state, { payload }: PayloadAction<SetLaborStep2>) => {
      state.labor.step2 = payload;
    },
    reset: () => {
      laborStorage.clear();
      return laborDefaultState;
    },
  },
});
