import React from 'react';
import { Loader } from '@chhjpackages/components';
import { useLoadScript } from '@react-google-maps/api';

import { googleApiConfig } from 'common/config/googleApi';

import { GoogleScriptsControllerProps } from './GoogleScriptsController.types';
import { useStyles } from './GoogleScriptsController.styles';

export const GoogleScriptsController = ({ children }: GoogleScriptsControllerProps) => {
  const styles = useStyles();
  const { isLoaded, loadError } = useLoadScript(googleApiConfig);

  if (loadError) {
    throw new Error('Google scripts not loaded correctly in GoogleScriptsController');
  }

  return isLoaded ? (
    <>{children}</>
  ) : (
    <div className={styles.loader}>
      <Loader />
    </div>
  );
};
