import React from 'react';

import { SummaryTabsProps } from './SummaryTabs.types';
import { useStyles } from './SummaryTabs.styles';
import { SingleTab } from './singleTab/SingleTab';

export const SummaryTabs = ({ data }: SummaryTabsProps) => {
  const styles = useStyles();

  return (
    <div className={styles.wrapper}>
      {data.map((tab) => (
        <SingleTab key={tab.title} data={tab} />
      ))}
    </div>
  );
};
