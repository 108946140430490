import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const BedroomIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="81" height="80" viewBox="0 0 81 80" fill="none">
      <g clipPath="url(#clip8)">
        <path
          d="M74.5163 35.8017H74.3104V18.1375C74.3104 14.8298 72.0732 12.1396 69.3007 12.1396H17.859C15.1002 12.1396 12.8493 14.8298 12.8493 18.1375V35.8017H12.8768C9.56903 35.8017 6.87891 38.4919 6.87891 41.7996V53.6032C6.87891 55.2639 8.22397 56.5953 9.87098 56.5953H77.5083C79.1691 56.5953 80.5004 55.2502 80.5004 53.6032V41.7996C80.5141 38.4781 77.824 35.8017 74.5163 35.8017Z"
          fill={colors.primary.light}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0293 28.5685C15.0293 24.7301 18.1496 21.6099 21.9879 21.6099H31.1289C34.9672 21.6099 38.0875 24.7301 38.0875 28.5685V32.1233C38.0875 32.6539 37.6573 33.0841 37.1267 33.0841C36.5961 33.0841 36.166 32.6539 36.166 32.1233V28.5685C36.166 25.7914 33.906 23.5314 31.1289 23.5314H21.9879C19.2108 23.5314 16.9508 25.7914 16.9508 28.5685V32.1233C16.9508 32.6539 16.5207 33.0841 15.9901 33.0841C15.4594 33.0841 15.0293 32.6539 15.0293 32.1233V28.5685Z"
          fill={colors.secondary.main}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.6465 28.5685C42.6465 24.7301 45.7668 21.6099 49.6051 21.6099H58.746C62.5844 21.6099 65.7047 24.7301 65.7047 28.5685V32.1233C65.7047 32.6539 65.2745 33.0841 64.7439 33.0841C64.2133 33.0841 63.7832 32.6539 63.7832 32.1233V28.5685C63.7832 25.7914 61.5232 23.5314 58.746 23.5314H49.6051C46.828 23.5314 44.568 25.7914 44.568 28.5685V32.1233C44.568 32.6539 44.1379 33.0841 43.6072 33.0841C43.0766 33.0841 42.6465 32.6539 42.6465 32.1233V28.5685Z"
          fill={colors.secondary.main}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 52.9158C2.5 52.3852 2.93015 51.9551 3.46076 51.9551H77.096C77.6266 51.9551 78.0567 52.3852 78.0567 52.9158C78.0567 53.4464 77.6266 53.8766 77.096 53.8766H3.46076C2.93015 53.8766 2.5 53.4464 2.5 52.9158Z"
          fill={colors.primary.main}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.45863 33.0831C6.68149 33.0831 4.42152 35.3431 4.42152 38.1203V65.0901C4.42152 65.6207 3.99137 66.0509 3.46076 66.0509C2.93015 66.0509 2.5 65.6207 2.5 65.0901V38.1203C2.5 34.2819 5.62027 31.1616 9.45863 31.1616H71.0981C74.9365 31.1616 78.0567 34.2819 78.0567 38.1203V65.0901C78.0567 65.6207 77.6266 66.0509 77.096 66.0509C76.5654 66.0509 76.1352 65.6207 76.1352 65.0901V38.1203C76.1352 35.3431 73.8753 33.0831 71.0981 33.0831H9.45863Z"
          fill={colors.primary.main}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4392 9.42152C12.3531 9.42152 10.3903 11.511 10.3903 14.4586V32.1228C10.3903 32.6535 9.96012 33.0836 9.42951 33.0836C8.8989 33.0836 8.46875 32.6535 8.46875 32.1228V14.4586C8.46875 10.7908 10.9803 7.5 14.4392 7.5H65.8809C69.3245 7.5 71.8513 10.789 71.8513 14.4586V32.1228C71.8513 32.6535 71.4212 33.0836 70.8906 33.0836C70.3599 33.0836 69.9298 32.6535 69.9298 32.1228V14.4586C69.9298 11.5128 67.9547 9.42152 65.8809 9.42152H14.4392Z"
          fill={colors.primary.main}
        />
      </g>
      <defs>
        <clipPath id="clip8">
          <rect width="80" height="80" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
