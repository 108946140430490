import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { ContactInformationForm } from 'common/app/contactInformationForm/ContactInformationForm';
import { ContactInformationFormValues } from 'common/app/contactInformationForm/ContactInformationForm.types';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { CategoryIdEnum } from 'common/api/types';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';

export const MovingStep5 = () => {
  const history = useHistory();
  const [{ moving, isCalculatingQuote }, { movingDispatch }] = useMovingContext();
  const { isCallRequired, user } = useFormState();
  const { createOrUpdateAccount, isCreatingOrUpdatingAccount } = useAccount();
  const [isWaitingForQuote, setWaitingForQuote] = useState(false);

  const redirectToNextStep = useCallback(() => {
    if (isCallRequired) {
      history.push(movingRoutes.step8);
    } else {
      history.push(movingRoutes.step6);
    }
  }, [history, isCallRequired]);

  const handleSubmit = useCallback(
    async (values: ContactInformationFormValues, isDirty: boolean) => {
      if (!moving?.step1) return;
      if (!isDirty) {
        redirectToNextStep();
        return;
      }

      const account = await createOrUpdateAccount({
        contactInformationData: values,
        billingAddress: moving.step1.fullAddress,
        categoryId: CategoryIdEnum.move,
      });

      if (!account) {
        return;
      }

      movingDispatch(movingActions.setStep5(values));
      /*
      createAccount will createOrUpdateAccount and save accountId & token to state,
      after that useEffect (in Controller) is runned for calculatingQuote,
      then i set waiting state for that quote to check if callIsRequired
      */
      setWaitingForQuote(true);
    },
    [createOrUpdateAccount, movingDispatch, moving?.step1, redirectToNextStep],
  );

  useEffect(() => {
    if (!isWaitingForQuote || isCallRequired === undefined || (isCallRequired !== undefined && isCalculatingQuote))
      return;

    // if isCallRequired it NOT undefined, it means that quote has been fetched and it's ready to proceed
    setWaitingForQuote(false);
    redirectToNextStep();
  }, [redirectToNextStep, isWaitingForQuote, isCallRequired, isCalculatingQuote]);

  if (!moving?.step3) {
    return <Redirect to={movingRoutes.step3} />;
  }

  return (
    <ContactInformationForm
      loading={isCreatingOrUpdatingAccount || isCalculatingQuote || isWaitingForQuote}
      onSubmit={handleSubmit}
      title="5. Contact information"
      onBackClick={() => history.push(movingRoutes.step4)}
      defaultValues={{
        firstName: moving?.step5?.firstName || user?.firstName || '',
        lastName: moving?.step5?.lastName || user?.lastName || '',
        email: moving?.step5?.email || user?.email || '',
        phone: moving?.step5?.phone || user?.phone || '',
        companyName: moving?.step5?.companyName || '',
        orderNumber: moving?.step5?.orderNumber || '',
        specialInstructions: moving?.step5?.specialInstructions || '',
        additionalNotes: moving?.step5?.additionalNotes || '',
      }}
      hasPrefilledData={!!moving?.step5}
      tipBoxData={{
        icon: 'threeStars',
        text: (
          <>
            100,000+ 5-Star Reviews <br /> We always strive to deliver 100% client satisfaction with every job.
          </>
        ),
      }}
    />
  );
};
