import React, { useMemo, forwardRef } from 'react';
import Slider, { Settings } from 'react-slick';

import { ArrowRightIcon } from 'common/assets/icons/ArrowRightIcon';
import { ArrowLeftIcon } from 'common/assets/icons/ArrowLeftIcon';

import { CarouselSliderProps } from './CarouselSlider.types';

export const CarouselSlider = forwardRef<Slider, CarouselSliderProps>(({ children, settings }, ref) => {
  const allSettings: Settings = useMemo(
    () => ({
      dots: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <ArrowLeftIcon />,
      nextArrow: <ArrowRightIcon />,
      ...settings,
    }),
    [settings],
  );

  return (
    <Slider ref={ref} {...allSettings}>
      {children}
    </Slider>
  );
});
