import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    borderRadius: 4,
    padding: '8px 16px',
    cursor: 'pointer',
    border: `2px solid ${colors.grey60}`,
    backgroundColor: colors.basic.white,
    transition: 'background-color .2s ease-in-out, border-color .2s ease-in-out',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    '& > *:nth-child(1)': {
      flexBasis: '70%',
    },

    [theme.breakpoints.up('md')]: {
      '&:hover': {
        backgroundColor: colors.primary.superlight,
        borderColor: colors.primary.main,
      },
    },
  },
  selected: {
    borderColor: colors.primary.main,

    '& $title': {
      color: colors.secondary.main,
    },
  },
  title: {
    marginLeft: 16,
    color: colors.grey170,
  },
  iconContainer: {
    display: 'flex',

    '& svg': {
      fontSize: 80,
    },
  },
}));
