import { useContext } from 'react';

import { LaborDispatchContext, LaborStateContext } from 'common/context/labor/LaborContext';
import { LaborDispatchContextValue, LaborStateContextValue } from 'common/context/labor/LaborContext.types';

export const useLaborStateContext = () => {
  const context = useContext(LaborStateContext);

  if (context === undefined) {
    throw new Error('useLaborStateContext must be used within a LaborContextController');
  }

  return context;
};

export const useLaborDispatchContext = () => {
  const context = useContext(LaborDispatchContext);

  if (context === undefined) {
    throw new Error('useLaborDispatchContext must be used within a LaborContextController');
  }

  return context;
};

export const useLaborContext: () => [LaborStateContextValue, LaborDispatchContextValue] = () => [
  useLaborStateContext(),
  useLaborDispatchContext(),
];
