import { AsideNavBarItem, CheckIfNavItemIsDisabled, GetNavItemsWithState } from './AsideNavbar.types';

const checkIfNavItemIsDisabled = ({ navItems, currentItem, isDone, isCallRequired }: CheckIfNavItemIsDisabled) => {
  if (navItems.length === 0) return false; // first navbar item -> always NOT disabled
  const previousItems = navItems.filter((_, i) => i < navItems.length);

  if (currentItem.isOptional) {
    // CASE FOR OPTIONAL STEPS (step6&7 moving): if call is NOT isCallRequired, then it's normal case,
    // if not, step should be always disabled
    return isCallRequired !== false ? true : !!previousItems.find((item) => !item.isDone) && !isDone;
  } else {
    // EDGE CASE FOR MOVING: for Summary step (id=8) if callRequired is true, then step 6&7 disabled
    // and Summary step is only NOT disabled, if step5 (contact information) isDone
    if (currentItem.id === 8 && isCallRequired !== false) {
      return !navItems.find((navItem) => navItem.id === 5)?.isDone;
    }
    // NORMAL CASE: if previous step is NOT done, then current state is disabled
    return !!previousItems.find((item) => !item.isDone) && !isDone;
  }
};

export const getNavItemsWithState = ({
  data,
  serviceType,
  filledStepsNames,
  isCallRequired,
}: GetNavItemsWithState): AsideNavBarItem[] => {
  return data[serviceType].reduce((reduced, currentItem) => {
    const isDone = filledStepsNames.includes(currentItem.step); // if key is present in formState (ex. formState.junkRemoval?.step1) then it means step is filled correctly
    const navBarItemWithState = {
      ...currentItem,
      isDone: isDone,
      isDisabled: checkIfNavItemIsDisabled({ navItems: reduced, currentItem, isDone, isCallRequired }),
    };

    return [...reduced, navBarItemWithState];
  }, [] as AsideNavBarItem[]);
};
