import React, { useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';

import { junkRemovalRoutes } from 'common/routing/AppRoute';
import { useJunkRemovalContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';
import { CategoryIdEnum } from 'common/api/types';
import { ContactInformationForm } from 'common/app/contactInformationForm/ContactInformationForm';
import { ContactInformationFormValues } from 'common/app/contactInformationForm/ContactInformationForm.types';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';
import { useAccount } from 'common/hooks/useAccount/useAccount';
import { useFormState } from 'common/hooks/useFormState/useFormState';

export const JunkRemovalStep2 = () => {
  const history = useHistory();
  const [{ junkRemoval }, { junkRemovalDispatch }] = useJunkRemovalContext();
  const { createOrUpdateAccount, isCreatingOrUpdatingAccount } = useAccount();
  const { junkVer } = useVersionContext();
  const { user } = useFormState();

  const handleSubmit = useCallback(
    async (values: ContactInformationFormValues, isDirty) => {
      if (!junkRemoval?.step1) return;
      if (!isDirty) {
        history.push(junkRemovalRoutes.step4);
        return;
      }

      const account = await createOrUpdateAccount({
        contactInformationData: values,
        billingAddress: junkRemoval.step1.fullAddress,
        categoryId: CategoryIdEnum.junk,
      });

      if (!account) {
        return;
      }

      junkRemovalDispatch(junkRemovalActions.setStep2(values));
      history.push(junkRemovalRoutes.step4);
    },
    [junkRemoval?.step1, createOrUpdateAccount, junkRemovalDispatch, history],
  );

  if (!junkRemoval?.step1) {
    return <Redirect to={junkRemovalRoutes.step1} />;
  }

  return (
    <ContactInformationForm
      loading={isCreatingOrUpdatingAccount}
      onSubmit={handleSubmit}
      title="2. Contact information"
      onBackClick={() => history.push(junkRemovalRoutes.step1)}
      defaultValues={{
        firstName: junkRemoval?.step2?.firstName || user?.firstName || '',
        lastName: junkRemoval?.step2?.lastName || user?.lastName || '',
        email: junkRemoval?.step2?.email || user?.email || '',
        phone: junkVer === 'A' ? junkRemoval?.step2?.phone || user?.phone || '' : undefined,
        companyName: junkRemoval?.step2?.companyName || '',
        orderNumber: junkRemoval?.step2?.orderNumber || '',
        specialInstructions: junkRemoval?.step2?.specialInstructions || '',
        additionalNotes: junkRemoval?.step2?.additionalNotes || '',
      }}
      hasPrefilledData={!!junkRemoval?.step2}
      tipBoxData={{
        icon: 'threeStars',
        text: <>Our team will text/call you on the day of your service to let you know we are on the way.</>,
      }}
    />
  );
};
