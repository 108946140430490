import moment from 'moment';

import { QuoteRequestParams } from 'common/api/actions/account/accountActions.types';
import { CategoryIdEnum } from 'common/api/types';
import { FormStateContextType } from 'common/context/form/formContext/FormContext.types';
import { DateFormatForAPI } from 'common/utils/dateTime';
import { mapTruckParkingToLongWalks } from 'common/utils/form';

import { MovingState } from './MovingContext.types';

type MapMovingStateToCalculateQuotePayload = {
  movingState: {
    step1: MovingState['step1'];
    step2: MovingState['step2'];
    step3: MovingState['step3'];
    step4: MovingState['step4'];
    step5: MovingState['step5'];
  };
  locationId: FormStateContextType['locationId'];
  accountId: FormStateContextType['accountId'];
  token: FormStateContextType['token'];
};

export const mapMovingStateToCalculateQuotePayload = ({
  movingState,
  locationId,
  accountId,
  token,
}: MapMovingStateToCalculateQuotePayload): QuoteRequestParams | null => {
  if (
    !movingState?.step1 ||
    !movingState?.step2 ||
    !movingState?.step3 ||
    !movingState?.step4 ||
    !movingState?.step5 ||
    !locationId ||
    !accountId ||
    !token
  ) {
    return null;
  }

  return {
    locationId,
    accountId,
    token,
    payload: {
      hunks: 2, // default value 2
      email_client: false,
      rooms: movingState.step1.rooms,
      appliances: movingState.step3.appliances,
      assembly_items: movingState.step3.assemblyItems,
      heavy_items: movingState.step3.heavyItems,
      date: moment(movingState.step4.date).format(DateFormatForAPI),
      location: {
        id: locationId,
      },
      account: {
        id: accountId,
        first_name: movingState.step5.firstName,
        last_name: movingState.step5.lastName,
        email: movingState.step5.email,
        phone: movingState.step5.phone,
      },
      origin: {
        ...movingState.step1.fullAddress,
        floor: movingState.step1.floor ?? 0,
        access_type: movingState.step1.accessType,
        long_walks: mapTruckParkingToLongWalks(movingState.step1.truckParking),
      },
      destination: {
        ...movingState.step2.fullAddress,
        floor: movingState.step2.floor ?? 0,
        access_type: movingState.step2.accessType,
        long_walks: mapTruckParkingToLongWalks(movingState.step2.truckParking),
      },
      category: {
        id: CategoryIdEnum.move,
      },
      type: {
        id: 1,
      },
    },
  };
};
