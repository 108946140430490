import React, { useCallback } from 'react';
import { useMutation } from 'react-fetching-library';

import { useFormState } from 'common/hooks/useFormState/useFormState';
import { formatPhoneForApi } from 'common/utils/form';
import { updateAccountAction } from 'common/api/actions/account/accountActions';
import { useJunkRemovalContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';

import { WeWillTextModal } from './WeWillTextModal';
import { WeWillTextModalContainerProps } from './WeWillTextModal.types';

export const WeWillTextModalContainer = ({ onSubmit, open, onClose }: WeWillTextModalContainerProps) => {
  const { accountId, token, locationId } = useFormState();
  const [{ junkRemoval }, { junkRemovalDispatch }] = useJunkRemovalContext();
  const { mutate, loading, abort } = useMutation(updateAccountAction);

  const handleSubmit = useCallback(
    async ({ phone }: { phone: string }) => {
      if (!junkRemoval?.step2 || !accountId || !token || !locationId) return;
      const formattedPhone = formatPhoneForApi(phone);

      junkRemovalDispatch(junkRemovalActions.setStep2({ ...junkRemoval.step2, phone: formattedPhone }));

      abort();
      const { payload } = await mutate({ locationId, accountId, token, payload: { phone: formattedPhone } });

      if (!payload?.meta.errors?.length) {
        onClose();
        onSubmit();
      }
    },
    [mutate, onClose, onSubmit, abort, junkRemovalDispatch, junkRemoval, accountId, token, locationId],
  );

  return <WeWillTextModal open={open} onClose={onClose} onSubmit={handleSubmit} isLoading={loading} />;
};
