import { useContext } from 'react';

import { FormDispatchContextType } from 'common/context/form/formContext/FormContext.types';
import { FormDispatchContext } from 'common/context/form/formContext/FormContext';

type UseFormDispatch = () => FormDispatchContextType;

export const useFormDispatch: UseFormDispatch = () => {
  const context = useContext(FormDispatchContext);

  if (context === undefined) {
    throw new Error('useFormDispatch must be used within an FormContextController');
  }

  return context;
};
