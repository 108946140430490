import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    maxWidth: 489,
    marginBottom: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,

    '& > *:not(:last-child)': {
      marginBottom: 16,
    },
  },
  tipBox: {
    marginTop: 'auto',
  },
  errorText: {
    // + default marginTop=3px. sum 24px
    minHeight: 17,
    marginBottom: 4,
  },
}));
