import { BillingAddress } from 'common/api/actions/account/accountActions.types';

export const validateAddress = (fullAddr?: Partial<BillingAddress> | null) => {
  if (!fullAddr) return '*required';
  if (!fullAddr.address) return '*address is missing';
  // if (!fullAddr.city) return '*city is missing';
  if (!fullAddr.state) return '*state is missing';
  if (!fullAddr.country) return '*country is missing';
  if (!fullAddr.postal) return '*postal code is missing';
  return '';
};
