import React from 'react';

import { AllDone } from 'common/app/allDone/AllDone';

export const JunkRemovalAllDone = () => {
  return (
    <AllDone
      title="see you soon!"
      subtitle="You'll receive a confirmation email at:"
      reschedule
      isAnotherServiceBtnVisible
    />
  );
};
