import React, { useState, useCallback } from 'react';
import { Fade } from '@material-ui/core';

import { LoadedImageProps } from './LoadedImage.types';

export const LoadedImage = ({ alt, src, className }: LoadedImageProps) => {
  const [imgLoaded, setImgLoaded] = useState(false);

  const onImageLoad = useCallback(() => setImgLoaded(true), []);

  return (
    <Fade in={imgLoaded}>
      <img src={src} alt={alt || ''} className={className} onLoad={onImageLoad} data-testid="loadedImage" />
    </Fade>
  );
};
