import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const HeroImgDecorationBig = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} style={{ height: 163, width: 80 }} viewBox="0 0 80 163" fill="none">
      <path
        opacity="0.75"
        d="M0 0H80V157.276C80 157.744 79.836 158.197 79.5364 158.556L76.5364 162.156C75.7369 163.116 74.2631 163.116 73.4636 162.156L71.5364 159.844C70.7369 158.884 69.2631 158.884 68.4636 159.844L66.5364 162.156C65.7369 163.116 64.2631 163.116 63.4636 162.156L61.5364 159.844C60.7369 158.884 59.2631 158.884 58.4636 159.844L56.5364 162.156C55.7369 163.116 54.2631 163.116 53.4636 162.156L51.5364 159.844C50.7369 158.884 49.2631 158.884 48.4636 159.844L46.5364 162.156C45.7369 163.116 44.2631 163.116 43.4636 162.156L41.5364 159.844C40.7369 158.884 39.2631 158.884 38.4636 159.844L36.5364 162.156C35.7369 163.116 34.2631 163.116 33.4636 162.156L31.5364 159.844C30.7369 158.884 29.2631 158.884 28.4636 159.844L26.5364 162.156C25.7369 163.116 24.2631 163.116 23.4636 162.156L21.5364 159.844C20.7369 158.884 19.2631 158.884 18.4636 159.844L16.5364 162.156C15.7369 163.116 14.2631 163.116 13.4636 162.156L11.5364 159.844C10.7369 158.884 9.26315 158.884 8.46356 159.844L6.53644 162.156C5.73685 163.116 4.26315 163.116 3.46356 162.156L0.463557 158.556C0.164032 158.197 0 157.744 0 157.276V0Z"
        fill={colors.primary.main}
      />
    </SvgIcon>
  );
};
