import { Action } from 'common/api/types';

import { GetPartnerInfoAction } from './partnerActions.types';

export const getPartnerInfoAction = (values: GetPartnerInfoAction.Payload): Action<GetPartnerInfoAction.Response> => {
  return {
    method: 'POST',
    endpoint: `/public/website/subpartners/lookup`,
    body: values,
    config: {
      disableInterceptErrors: true,
    },
  };
};
