import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ShortArrowIcon = ({ className, onClick }: { className?: string; onClick: () => void }) => {
  return (
    <SvgIcon onClick={onClick} className={className} width="16" height="17" viewBox="0 0 16 17" fill="none">
      <path
        d="M10.5008 14.5C10.3692 14.5007 10.2387 14.4754 10.1169 14.4254C9.99505 14.3754 9.88423 14.3017 9.79079 14.2085L4.79079 9.18361C4.60454 8.99531 4.5 8.7406 4.5 8.4751C4.5 8.20959 4.60454 7.95488 4.79079 7.76658L9.79079 2.74166C9.98209 2.57701 10.2282 2.49098 10.4798 2.50075C10.7315 2.51052 10.9703 2.61537 11.1484 2.79435C11.3264 2.97334 11.4308 3.21326 11.4405 3.4662C11.4502 3.71913 11.3646 3.96643 11.2008 4.15868L6.91079 8.47007L11.2008 12.7815C11.3412 12.9214 11.4372 13.1001 11.4766 13.2949C11.516 13.4897 11.497 13.6918 11.4221 13.8757C11.3471 14.0597 11.2196 14.2172 11.0556 14.3283C10.8917 14.4394 10.6986 14.4991 10.5008 14.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
