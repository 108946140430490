import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CloseIcon = ({ className, onClick }: { className?: string; onClick?: () => void }) => {
  return (
    <SvgIcon onClick={onClick} className={className} width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.69672 8L13.6481 4.0486C14.1173 3.57942 14.1173 2.82106 13.6481 2.35188C13.1789 1.88271 12.4206 1.88271 11.9514 2.35188L8 6.30329L4.0486 2.35188C3.57942 1.88271 2.82106 1.88271 2.35188 2.35188C1.88271 2.82106 1.88271 3.57942 2.35188 4.0486L6.30329 8L2.35188 11.9514C1.88271 12.4206 1.88271 13.1789 2.35188 13.6481C2.58587 13.8821 2.89306 13.9997 3.20024 13.9997C3.50742 13.9997 3.81461 13.8821 4.0486 13.6481L8 9.69672L11.9514 13.6481C12.1854 13.8821 12.4926 13.9997 12.7998 13.9997C13.1069 13.9997 13.4141 13.8821 13.6481 13.6481C14.1173 13.1789 14.1173 12.4206 13.6481 11.9514L9.69672 8Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
