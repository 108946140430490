import React from 'react';
import Bugsnag from '@bugsnag/browser';
import BugsnagPluginReact from '@bugsnag/plugin-react';

import { ErrorBoundaryProps } from './ErrorBoundary.types';
import { ErrorBoundaryFallback } from './errorBoundaryFallback/ErrorBoundaryFallback';

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

export const ErrorBoundary = ({ children }: ErrorBoundaryProps) => {
  const bugsnagPlugin = Bugsnag.getPlugin('react');

  const BugsnagError = bugsnagPlugin?.createErrorBoundary(React);

  return (
    <>{!!BugsnagError ? <BugsnagError FallbackComponent={ErrorBoundaryFallback}>{children}</BugsnagError> : children}</>
  );
};
