import React, { ReactNode, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';
import qs from 'query-string';
import { useMutation } from 'react-fetching-library';
import { Loader } from '@chhjpackages/components';
import { Box } from '@material-ui/core';

import { AppRoute } from 'common/routing/AppRoute';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setPartnerData, setUserData } from '../form/formActionCreators/formActionCreators';
import { getPartnerInfoAction } from 'common/api/actions/partner/partnerActions';

import { VersionContext } from './VersionContext';
import { VersionContextValue, UrlParams, Version } from './VersionContext.types';
import { versionStorage, versionsDefaultState } from './VersionStorage';
import { mapPartnerResponseToStateData } from './VersionContextController.utils';

export const VersionContextController = ({ children }: { children: ReactNode }) => {
  const [servicesVersion, setServicesVersion] = useState(versionStorage.state);
  const { location, push } = useHistory();
  const formDispatch = useFormDispatch();

  const { mutate: getPartnerInfoMutate, abort: abortGetPartnerInfo, loading: isGettingPartnerInfo } = useMutation(
    getPartnerInfoAction,
  );

  const getPartnerInfo = useCallback(
    async (partnerToken: string) => {
      abortGetPartnerInfo();
      const { payload } = await getPartnerInfoMutate({ subpartner_token: partnerToken });

      if (!payload?.subpartners.length || payload.meta.errors?.length) {
        return;
      }

      formDispatch(setPartnerData(mapPartnerResponseToStateData(payload.subpartners[0], partnerToken)));
    },
    [getPartnerInfoMutate, abortGetPartnerInfo, formDispatch],
  );

  useEffect(() => {
    const urlParams = qs.parse(location.search) as Partial<UrlParams>;

    if (
      urlParams.junkVer ||
      urlParams.moveVer ||
      urlParams.partnerToken ||
      urlParams.firstName ||
      urlParams.lastName ||
      urlParams.email ||
      urlParams.phoneNumber
    ) {
      if (urlParams.junkVer || urlParams.moveVer) {
        const urlJunkVer = urlParams.junkVer?.toUpperCase() || '';
        const urlMoveVer = urlParams.moveVer?.toUpperCase() || '';

        const servicesVersion: VersionContextValue = {
          junkVer: ['A', 'B'].includes(urlJunkVer) ? (urlJunkVer as Version) : versionsDefaultState.junkVer,
          moveVer: ['A', 'B'].includes(urlMoveVer) ? (urlMoveVer as Version) : versionsDefaultState.moveVer,
        };

        setServicesVersion(servicesVersion);
        versionStorage.state = servicesVersion;
      }

      if (urlParams.partnerToken) {
        getPartnerInfo(urlParams.partnerToken);
      }

      if (urlParams.firstName || urlParams.lastName || urlParams.email || urlParams.phoneNumber) {
        formDispatch(
          setUserData({
            firstName: urlParams.firstName,
            lastName: urlParams.lastName,
            email: urlParams.email,
            phone: urlParams.phoneNumber,
          }),
        );
      }

      const url = qs.stringifyUrl(
        { url: AppRoute.home, query: urlParams },
        {
          encode: false,
        },
      );

      push(url);
    }
  }, [location.search, push, getPartnerInfo, formDispatch]);

  if (isGettingPartnerInfo) {
    return (
      <Box height="100vh">
        <Loader />
      </Box>
    );
  }

  return <VersionContext.Provider value={servicesVersion}>{children}</VersionContext.Provider>;
};
