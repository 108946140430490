import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DiamondIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="49" height="49" viewBox="0 0 49 49" fill="none">
      <g clipPath="url(#clip5)">
        <path d="M35.52 15.52H17.99L11.31 22.89L26.76 43.33L42.2 22.89L35.52 15.52Z" fill="#F1CCA5" />
        <path
          d="M32.97 12.9702H15.44L8.76001 20.3402L24.2 40.7702L39.65 20.3402L32.97 12.9702Z"
          stroke="#EC7200"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.49 15.52L19.78 17.76"
          stroke="#EC7200"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.82 15.52L28.57 17.76"
          stroke="#EC7200"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.76001 20.3398H39.65"
          stroke="#EC7200"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.63 22.9199L22.73 35.0099"
          stroke="#EC7200"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.76 22.9199L25.6 35.0099"
          stroke="#EC7200"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 10.5001L4.12 6.12012"
          stroke="#006A36"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.91 10.5001L44.28 6.12012"
          stroke="#006A36"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.5 35.3999L4.12 39.7699"
          stroke="#006A36"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M39.91 35.3999L44.28 39.7699"
          stroke="#006A36"
          strokeWidth="1.25"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip5">
          <rect width="41.4" height="37.83" fill="white" transform="translate(3.5 5.5)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
