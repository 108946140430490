import React, { useCallback, useMemo, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { Button, SelectOptionType } from '@chhjpackages/components';
import { useHistory, Redirect } from 'react-router-dom';
import moment from 'moment';

import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { useMovingStateContext } from 'aMove/hooks/useMoving/useMoving';
import { movingRoutes } from 'common/routing/AppRoute';
import { SummaryTabs } from 'common/ui/summaryTabs/SummaryTabs';
import { USADateFormat } from 'common/utils/dateTime';
import { AccessTypeEnum } from 'common/api/types';
import { truckParkingOptions, typeOfDwellingOptions } from 'aMove/app/moving/MovingData';
import { SingleTab } from 'common/ui/summaryTabs/singleTab/SingleTab.types';
import { setReadyToProcessing } from 'common/context/form/formActionCreators/formActionCreators';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { applyPhoneMask } from 'common/utils/form';
import { ReadyForRequestProcessingEnum } from 'common/context/form/formContext/FormContext.types';

import { useStyles } from './MovingStep6.styles';

export const MovingStep6 = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useFormDispatch();
  const { moving } = useMovingStateContext();

  useEffect(() => {
    gtag('config', 'UA-1180362-2', { page_path: '/get-started/moving/provide-more-details/' });
  }, []);

  const findLabelForSelectOptions = useCallback((options: SelectOptionType<number | string>[], id: number) => {
    return options.find(({ value }) => value === id)?.label || '';
  }, []);

  const summaryTabsData: SingleTab[] = useMemo(() => {
    if (!moving?.step1 || !moving?.step2 || !moving?.step3 || !moving?.step4 || !moving?.step5) {
      return [];
    }

    return [
      {
        title: 'Moving from',
        onClick: () => history.push(movingRoutes.step1),
        content: [
          { key: 'Address', value: moving.step1.shortAddress },
          { key: 'Apt/suite', value: moving.step1.fullAddress.address2 || '-' },
          { key: 'Dwelling', value: findLabelForSelectOptions(typeOfDwellingOptions, moving.step1.typeOfDwelling) },
          ...(typeof moving.step1.floor === 'number' ? [{ key: 'Floor', value: moving.step1.floor + 1 }] : []),
          { key: 'Access type', value: AccessTypeEnum[moving.step1.accessType] },
          { key: 'Parking truck', value: findLabelForSelectOptions(truckParkingOptions, moving.step1.truckParking) },
          { key: 'Bedrooms', value: moving.step1.rooms },
        ],
      },
      {
        title: 'Moving to',
        onClick: () => history.push(movingRoutes.step2),
        content: [
          { key: 'Address', value: moving.step2.shortAddress },
          { key: 'Apt/suite', value: moving.step2.fullAddress.address2 || '-' },
          { key: 'Dwelling', value: findLabelForSelectOptions(typeOfDwellingOptions, moving.step2.typeOfDwelling) },
          ...(typeof moving.step2.floor === 'number' ? [{ key: 'Floor', value: moving.step2.floor + 1 }] : []),
          { key: 'Access type', value: AccessTypeEnum[moving.step2.accessType] },
          { key: 'Parking truck', value: findLabelForSelectOptions(truckParkingOptions, moving.step2.truckParking) },
        ],
      },
      {
        title: 'Special items',
        onClick: () => history.push(movingRoutes.step3),
        content: [
          { key: 'Appliances larger than a dishwasher', value: moving.step3.appliances || '-' },
          { key: 'Items that require more than 2 people to lift', value: moving.step3.heavyItems || '-' },
          { key: 'Items that need to be assembled', value: moving.step3.assemblyItems || '-' },
        ],
        wideKeyField: true,
      },
      {
        title: 'Job date',
        onClick: () => history.push(movingRoutes.step4),
        content: [{ key: 'Day', value: moment(moving.step4.date).format(USADateFormat) }],
      },
      {
        title: 'Contact information',
        onClick: () => history.push(movingRoutes.step5),
        content: [
          { key: 'First name', value: moving.step5.firstName },
          { key: 'Last name', value: moving.step5.lastName },
          { key: 'E-mail', value: moving.step5.email },
          { key: 'Phone number', value: applyPhoneMask(moving.step5.phone) },
        ],
      },
    ];
  }, [moving, history, findLabelForSelectOptions]);

  const handleNextStep = useCallback(() => {
    dispatch(setReadyToProcessing({ readyForRequestProcessing: ReadyForRequestProcessingEnum.Moving }));
    history.push(movingRoutes.processing);
  }, [dispatch, history]);

  if (!moving?.step5) {
    return <Redirect to={movingRoutes.step5} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.summaryContent}>
        <div className={styles.textContent}>
          <Typography variant="h4" className={styles.title}>
            6. Summary
          </Typography>

          <Typography variant="body1" className={styles.subtitle}>
            Please, review your information below
          </Typography>
        </div>

        <SummaryTabs data={summaryTabsData} />
      </div>

      <ButtonsGroup className={styles.buttonsGroup}>
        <Button onClick={() => history.push(movingRoutes.step5)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>

        <Button onClick={handleNextStep} fullWidth size="large" buttonType="twoTone">
          Contact me
        </Button>
      </ButtonsGroup>
    </div>
  );
};
