import React, { useCallback, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Grid, Typography, Box } from '@material-ui/core';
import { Button, TimePickerSchedule } from '@chhjpackages/components';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';

import { CategoryIdEnum } from 'common/api/types';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import { USADateFormat } from 'common/utils/dateTime';
import { SliderSchedulePicker } from 'common/ui/sliderSchedulePicker/SliderSchedulePicker';
import { useAvailability } from 'common/hooks/useAvailability/useAvailability';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';

import { useStyles } from './MovingStep6.styles';

export const MovingStep6 = () => {
  const styles = useStyles();
  const history = useHistory();
  const [{ moving, jobDuration }, { movingDispatch }] = useMovingContext();
  const { isCallRequired } = useFormState();
  const { schedules, isCheckingAvailability, getAvailabilitySchedule } = useAvailability();
  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      schedule: moving?.step6?.schedule || null,
    },
  });

  const handleSubmitCallback = useCallback(
    ({ schedule }: { schedule: TimePickerSchedule }) => {
      movingDispatch(movingActions.setStep6({ schedule }));
      history.push(movingRoutes.step7);
    },
    [movingDispatch, history],
  );

  useEffect(() => {
    gtag('config', 'UA-1180362-2', { page_path: '/get-started/moving/provide-more-details/' });
  }, []);

  useEffect(() => {
    (async () => {
      if (!moving?.step1 || !moving?.step4) {
        return;
      }

      await getAvailabilitySchedule({
        postal: moving.step1.fullAddress.postal,
        categoryId: CategoryIdEnum.move,
        jobDate: moving.step4.date,
        jobDuration,
      });
    })();
  }, [moving?.step1, moving?.step4, jobDuration, getAvailabilitySchedule]);

  if (!moving?.step5) {
    return <Redirect to={movingRoutes.step5} />;
  }

  if (!moving?.step5 || (moving?.step5 && isCallRequired)) {
    return <Redirect to={movingRoutes.step5} />;
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className={styles.form}>
      <Grid container direction="column" spacing={3} className={styles.content}>
        <Grid item>
          <Typography variant="h4">6. Job time</Typography>
        </Grid>

        <Grid item>
          <Typography>Select the arrival window that works best for you for our HUNKS to arrive</Typography>
        </Grid>

        <Grid item>
          <Box display="flex" alignItems="center">
            <Typography>Selected day: </Typography>
            <Typography color="secondary" className={styles.date}>
              {moment(moving?.step4?.date).format(USADateFormat)}
            </Typography>
            <Button onClick={() => history.push(movingRoutes.step4)} size="medium" buttonType="text" color="primary">
              Change
            </Button>
          </Box>
        </Grid>

        <Grid item>
          <Controller
            control={control}
            name="schedule"
            defaultValue={null}
            rules={{ required: true }}
            render={({ onChange, value }) => (
              <SliderSchedulePicker
                value={value}
                onChange={onChange}
                initialPeriod={moving?.step6?.schedule.period}
                isLoading={isCheckingAvailability}
                schedules={schedules}
              />
            )}
          />
        </Grid>
      </Grid>
      <ButtonsGroup className={styles.buttonsGroup}>
        <Button onClick={() => history.push(movingRoutes.step5)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>

        <Button type="submit" disabled={!isValid || isCheckingAvailability} fullWidth size="large" buttonType="twoTone">
          Next
        </Button>
      </ButtonsGroup>
    </form>
  );
};
