import React from 'react';
import { Typography, TableContainer, Table, TableBody, TableRow, TableCell } from '@material-ui/core';

import { EditIcon } from 'common/assets/icons/EditIcon';

import { SingleTabProps } from './SingleTab.types';
import { useStyles } from './SingleTab.styles';

export const SingleTab = ({ data }: SingleTabProps) => {
  const styles = useStyles({ wideKeyField: data.wideKeyField });

  return (
    <div className={styles.wrapper}>
      <Typography variant="h4" className={styles.title}>
        {data.title}
      </Typography>
      <EditIcon onClick={data.onClick} className={styles.icon} />

      <TableContainer className={styles.contentWrapper}>
        <Table>
          <TableBody>
            {data.content.map(({ key, value }, index) => (
              <TableRow key={`${index}-${value}`} className={styles.row}>
                <TableCell component="th" scope="row" className={styles.cell}>
                  <Typography className={styles.key}>{key}:</Typography>
                </TableCell>
                <TableCell component="th" scope="row" className={styles.cell}>
                  <Typography>{value}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
