import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  SetPricing,
  SetJunkRemovalStep1,
  SetJunkRemovalStep2,
  SetJunkRemovalStep3,
  SetJunkRemovalStep4,
  ResetStep,
} from './JunkRemovalContext.types';
import { mapPricingResponse } from './JunkRemovalContextSlice.utils';
import { junkRemovalDefaultState, junkRemovalStorage } from './JunkRemovalContextStorage';

export const { actions: junkRemovalActions, reducer: junkRemovalReducer } = createSlice({
  name: 'junkRemovalSlice',
  initialState: junkRemovalDefaultState,
  reducers: {
    setPricing: (state, { payload }: PayloadAction<SetPricing>) => {
      state.pricing = mapPricingResponse(payload);
    },
    setStep1: (state, { payload }: PayloadAction<SetJunkRemovalStep1>) => {
      state.junkRemoval.step1 = payload;
    },
    setStep2: (state, { payload }: PayloadAction<SetJunkRemovalStep2>) => {
      state.junkRemoval.step2 = payload;
    },
    setStep3: (state, { payload }: PayloadAction<SetJunkRemovalStep3>) => {
      state.junkRemoval.step3 = payload;
    },
    setStep4: (state, { payload }: PayloadAction<SetJunkRemovalStep4>) => {
      state.junkRemoval.step4 = payload;
    },
    resetStep: (state, { payload }: PayloadAction<ResetStep>) => {
      delete state.junkRemoval[payload.step];
    },
    reset: () => {
      junkRemovalStorage.clear();
      return junkRemovalDefaultState;
    },
  },
});
