import React, { ReactNode } from 'react';

import { MovingContextController } from 'aMove/context/moving/MovingContextController';
import { MovingContextController as MovingContextControllerVerB } from 'bMove/context/moving/MovingContextController';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';

export const MovingProviderController = ({ children }: { children: ReactNode }) => {
  const { moveVer } = useVersionContext();

  return moveVer === 'A' ? (
    <MovingContextController>{children}</MovingContextController>
  ) : (
    <MovingContextControllerVerB>{children}</MovingContextControllerVerB>
  );
};
