import React from 'react';
import { SvgIcon } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const MealIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="80" height="80" viewBox="0 0 80 80" style={{ fill: 'none' }} fill="none">
      <path
        d="M74.0001 44.55V18.33H72.6101C67.6101 18.33 63.6201 22.41 63.6201 27.32V47.24H66.3101V61.04C66.3101 63.17 68.0701 64.93 70.2001 64.93C72.3301 64.93 74.0901 63.17 74.0901 61.04L74.0001 44.55Z"
        fill={colors.primary.light}
      />
      <path
        d="M22.8601 27.14V19.35H20.1701V27.13C20.1701 30.09 18.5001 32.5 16.2801 33.24V19.35H13.6001V33.25C11.3801 32.51 9.71008 30.01 9.71008 27.14V19.35H6.83008V27.13C6.83008 31.67 9.70008 35.38 13.5001 36.12V44.55H11.0001V61.04C11.0001 63.17 12.7601 64.93 14.8901 64.93C17.0201 64.93 18.7801 63.17 18.7801 61.04V44.55H16.2801V36.12C19.9901 35.38 22.8601 31.68 22.8601 27.14Z"
        fill={colors.primary.light}
      />
      <path
        d="M41.8501 23.24C30.4501 23.24 21.1001 32.5 21.1001 43.99C21.1001 55.48 30.3601 64.74 41.8501 64.74C53.3401 64.74 62.6001 55.48 62.6001 43.99C62.6001 32.5 53.2501 23.24 41.8501 23.24Z"
        fill={colors.primary.light}
      />
      <path
        d="M67.98 59.19C66.6 59.19 65.48 58.07 65.48 56.69V41.59H70.48V56.69C70.48 58.07 69.36 59.19 67.98 59.19Z"
        stroke={colors.primary.main}
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path
        d="M70.48 15.37V41.59H62.79V23.06C62.79 18.83 66.25 15.37 70.48 15.37Z"
        stroke={colors.primary.main}
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path d="M12.67 15V41.59" stroke={colors.primary.main} strokeWidth="1.4" strokeMiterlimit="10" />
      <path
        d="M12.67 59.19C11.29 59.19 10.17 58.07 10.17 56.69V41.59H15.17V56.69C15.17 58.07 14.05 59.19 12.67 59.19Z"
        stroke={colors.primary.main}
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path
        d="M19.3401 15V22.78C19.3401 27.04 16.3801 30.56 12.6701 30.56C8.96006 30.56 6.00006 27.13 6.00006 22.78V15"
        stroke={colors.primary.main}
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path
        d="M39.6301 58.91C50.3223 58.91 58.9901 50.2422 58.9901 39.55C58.9901 28.8578 50.3223 20.19 39.6301 20.19C28.9378 20.19 20.2701 28.8578 20.2701 39.55C20.2701 50.2422 28.9378 58.91 39.6301 58.91Z"
        stroke={colors.primary.main}
        strokeWidth="1.4"
        strokeMiterlimit="10"
      />
      <path
        d="M39.63 49.74C39.63 49.74 48.89 44.27 48.89 38.25C48.89 35.56 46.67 33.34 43.98 33.34C42.03 33.34 40.37 34.54 39.53 36.21C38.79 34.54 37.03 33.34 35.08 33.34C32.39 33.34 30.17 35.56 30.17 38.25C30.37 44.28 39.63 49.74 39.63 49.74Z"
        stroke={colors.secondary.main}
        strokeWidth="1.4"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
};
