import React from 'react';
import clsx from 'clsx';

import { ButtonsGroupProps } from './ButtonsGroup.types';
import { useStyles } from './ButtonsGroup.styles';

export const ButtonsGroup = ({ children, className }: ButtonsGroupProps) => {
  const styles = useStyles();

  return <div className={clsx(className, styles.wrapper)}>{children}</div>;
};
