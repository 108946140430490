import React, { useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { Button, Loader } from '@chhjpackages/components';

import { movingRoutes } from 'bMove/routing/AppRoute';
import { CheckIcon } from 'common/assets/icons/CheckIcon';
import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';
import { useMovingStateContext } from 'bMove/hooks/useMoving/useMoving';

import { useStyles } from './MovingStep7.styles';
import { PricingTable } from './pricingTable/PricingTable';

const listItems = [
  'A professional service that will pack all your things for you',
  'A promise that all your precious belongings will be transported with special care',
  'Professionals who will disassemble and assemble all necessary items',
  'The most important - trust that your items are in good hands',
];

export const MovingStep7 = () => {
  const styles = useStyles();
  const [{ moving }, { movingDispatch }] = useMovingContext();
  const history = useHistory();
  const { prepaymentData, pricingTableData } = useMovingStateContext();

  const handleNextStep = useCallback(() => {
    if (
      typeof pricingTableData?.numberOfHunks !== 'number' ||
      typeof prepaymentData?.amount !== 'number' ||
      !pricingTableData?.totalPrice
    ) {
      return;
    }

    const data = {
      numberOfHunks: pricingTableData.numberOfHunks,
      totalPrice: pricingTableData.totalPrice,
      prepaymentValue: prepaymentData.amount,
    };

    movingDispatch(movingActions.setStep7(data));
    history.push(movingRoutes.step8);
  }, [movingDispatch, history, pricingTableData, prepaymentData?.amount]);

  if (!moving.step7 && !moving?.step6) {
    return <Redirect to={movingRoutes.step6} />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Grid container direction="column" spacing={3} className={styles.textContent}>
          <Grid item>
            <Typography variant="h4">7. Pricing</Typography>
          </Grid>

          <Grid item>
            <Typography variant="body1">
              You're one step closer to booking your stress-free move. Here's an estimate based on the information
              you've provided.
            </Typography>
          </Grid>

          <Grid item>
            <Typography variant="h5">What can we offer you?</Typography>
          </Grid>

          {listItems.map((text) => (
            <Grid item key={text} className={styles.listItemsContainer}>
              <CheckIcon className={styles.icon} />
              <Typography>{text}</Typography>
            </Grid>
          ))}
        </Grid>

        {pricingTableData ? (
          <PricingTable data={pricingTableData} />
        ) : (
          <div className={styles.loader}>
            <Loader />
          </div>
        )}
      </div>

      <ButtonsGroup className={styles.buttonsGroup}>
        <Button onClick={() => history.push(movingRoutes.step6)} fullWidth size="large" buttonType="outlined">
          Back
        </Button>

        <Button onClick={handleNextStep} fullWidth size="large" buttonType="twoTone">
          Next
        </Button>
      </ButtonsGroup>
    </div>
  );
};
