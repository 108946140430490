import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  content: {
    maxWidth: 702,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  loaderContainer: {
    minHeight: 252,

    [theme.breakpoints.up('sm')]: {
      minHeight: 284,
    },

    [theme.breakpoints.up('md')]: {
      minHeight: 216, //placeholder container
    },

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  itemsContainer: {
    margin: '16px 16px 0',

    [theme.breakpoints.up('sm')]: {
      margin: '32px 24px 0',
    },

    [theme.breakpoints.up('md')]: {
      margin: '40px 0 0',
      display: 'flex',

      '& > *': {
        flexBasis: '33.3%',
      },
    },
  },
  itemsForPartnerContainer: {
    marginTop: 16,

    [theme.breakpoints.up('sm')]: {
      marginTop: 32,
      margin: '32px 24px 0',
    },

    [theme.breakpoints.up('md')]: {
      marginTop: 40,
    },
  },
}));
