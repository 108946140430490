import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles((theme) => ({
  boxBgc: {
    backgroundColor: colors.grey20,
  },
  form: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
  },
  box: {
    padding: '16px 0 16px 16px',
    marginLeft: -16,
    marginRight: -16,

    [theme.breakpoints.up('sm')]: {
      paddingLeft: 48,

      marginLeft: -48,
      marginRight: -48,
    },
  },
  label: {
    color: colors.grey140,
    marginBottom: 11,
  },
}));
