import { useContext } from 'react';

import { JunkRemovalDispatchContext, JunkRemovalStateContext } from 'common/context/junkRemoval/JunkRemovalContext';
import {
  JunkRemovalDispatchContextValue,
  JunkRemovalStateContextValue,
} from 'common/context/junkRemoval/JunkRemovalContext.types';

export const useJunkRemovalStateContext = () => {
  const context = useContext(JunkRemovalStateContext);

  if (context === undefined) {
    throw new Error('useJunkRemovalStateContext must be used within a JunkRemovalContextController');
  }

  return context;
};

export const useJunkRemovalDispatchContext = () => {
  const context = useContext(JunkRemovalDispatchContext);

  if (context === undefined) {
    throw new Error('useJunkRemovalDispatchContext must be used within a JunkRemovalContextController');
  }

  return context;
};

export const useJunkRemovalContext: () => [JunkRemovalStateContextValue, JunkRemovalDispatchContextValue] = () => [
  useJunkRemovalStateContext(),
  useJunkRemovalDispatchContext(),
];
