import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const HIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="48" height="48" viewBox="0 0 48 48" fill="none">
      <g clipPath="url(#clip4)">
        <path
          d="M6.86003 32.8C6.72003 30.05 5.41003 28.74 2.67003 28.61C2.65003 28.61 2.65003 28.59 2.67003 28.59C5.42003 28.45 6.73003 27.14 6.86003 24.4C6.86003 24.38 6.88003 24.38 6.88003 24.4C7.02003 27.15 8.33003 28.46 11.07 28.59C11.09 28.59 11.09 28.61 11.07 28.61C8.32003 28.75 7.01003 30.06 6.88003 32.8C6.88003 32.82 6.86003 32.82 6.86003 32.8Z"
          fill="#E9F0EB"
        />
        <path
          d="M41.45 12.76C41.32 9.98 40 8.66 37.22 8.53C37.2 8.53 37.2 8.51 37.22 8.51C40 8.38 41.32 7.06 41.45 4.28C41.45 4.26 41.47 4.26 41.47 4.28C41.6 7.06 42.92 8.38 45.7 8.51C45.72 8.51 45.72 8.53 45.7 8.53C42.92 8.66 41.6 9.98 41.47 12.76C41.47 12.78 41.45 12.78 41.45 12.76Z"
          fill="#E9F0EB"
        />
        <path
          d="M18.11 4.35C20.06 4.35 21.63 5.93 21.63 7.87V21C21.63 21.6 22.12 22.09 22.72 22.09H28.61C29.21 22.09 29.7 21.6 29.7 21V7.87C29.7 5.92 31.28 4.35 33.22 4.35C35.17 4.35 36.74 5.93 36.74 7.87V43.35C36.74 45.3 35.16 46.87 33.22 46.87C31.27 46.87 29.69 45.29 29.69 43.34V29.5C29.69 28.9 29.2 28.41 28.6 28.41H22.71C22.11 28.41 21.62 28.9 21.62 29.5V43.35C21.62 45.3 20.04 46.87 18.1 46.87C16.15 46.87 14.58 45.29 14.58 43.35V7.87C14.59 5.93 16.16 4.35 18.11 4.35Z"
          fill="#F1CCA5"
        />
        <path
          d="M16.03 2.62C17.98 2.62 19.55 4.2 19.55 6.14V19.26C19.55 19.86 20.04 20.35 20.64 20.35H26.53C27.13 20.35 27.62 19.86 27.62 19.26V6.15C27.62 4.2 29.2 2.63 31.14 2.63C33.09 2.63 34.66 4.21 34.66 6.15V41.63C34.66 43.58 33.08 45.15 31.14 45.15C29.19 45.15 27.61 43.57 27.61 41.62V27.78C27.61 27.18 27.12 26.69 26.52 26.69H20.63C20.03 26.69 19.54 27.18 19.54 27.78V41.63C19.54 43.58 17.96 45.15 16.02 45.15C14.07 45.15 12.5 43.57 12.5 41.63V6.15C12.5 4.2 14.08 2.62 16.03 2.62Z"
          stroke="#EC7200"
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.36006 30.32C4.16006 28.95 3.43006 28.21 2.05006 28.01C1.98006 28 1.98006 27.91 2.05006 27.9C3.42006 27.7 4.16006 26.97 4.36006 25.59C4.37006 25.52 4.46006 25.52 4.47006 25.59C4.67006 26.96 5.40006 27.7 6.78006 27.9C6.85006 27.91 6.85006 28 6.78006 28.01C5.41006 28.21 4.67006 28.94 4.47006 30.32C4.46006 30.39 4.37006 30.39 4.36006 30.32Z"
          fill="#226937"
        />
        <path
          d="M42.61 10.51C42.46 8.57 41.5 7.61 39.57 7.47C39.54 7.47 39.54 7.43 39.57 7.43C41.51 7.28 42.47 6.32 42.61 4.39C42.61 4.36 42.65 4.36 42.65 4.39C42.8 6.33 43.76 7.29 45.69 7.43C45.72 7.43 45.72 7.47 45.69 7.47C43.75 7.62 42.79 8.58 42.65 10.51C42.65 10.54 42.62 10.54 42.61 10.51Z"
          fill="#226937"
        />
        <path d="M43.0712 7.438L42.6328 6.99959L42.1944 7.438L42.6328 7.8764L43.0712 7.438Z" fill="white" />
        <path
          d="M7.59003 43.42C7.40003 40.88 6.16003 39.63 3.62003 39.45C3.59003 39.45 3.59003 39.4 3.62003 39.4C6.16003 39.21 7.41003 37.97 7.59003 35.43C7.59003 35.4 7.64003 35.4 7.64003 35.43C7.83003 37.97 9.07003 39.22 11.61 39.4C11.64 39.4 11.64 39.45 11.61 39.45C9.07003 39.64 7.82003 40.88 7.64003 43.42C7.63003 43.45 7.59003 43.45 7.59003 43.42Z"
          fill="#E9F0EB"
        />
        <path
          d="M9.84998 44.75C9.60998 41.89 8.18998 40.47 5.32998 40.23C5.27998 40.23 5.27998 40.16 5.32998 40.16C8.18998 39.92 9.60998 38.5 9.84998 35.64C9.84998 35.59 9.91998 35.59 9.91998 35.64C10.16 38.5 11.58 39.92 14.44 40.16C14.49 40.16 14.49 40.23 14.44 40.23C11.58 40.47 10.16 41.89 9.91998 44.75C9.91998 44.8 9.84998 44.8 9.84998 44.75Z"
          fill="#226937"
        />
        <path d="M10.541 40.1955L9.88342 39.5379L9.22582 40.1955L9.88342 40.8531L10.541 40.1955Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip4">
          <rect width="43.72" height="44.88" fill="white" transform="translate(2 2)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
