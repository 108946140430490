import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TelephoneIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6141 1.90215C4.60785 1.03262 6.00766 1.03262 7.00141 1.90215C7.01683 1.91564 7.03169 1.92976 7.04596 1.94447L9.85435 4.84063C10.4593 5.33118 10.5458 6.06869 10.5458 6.5547C10.5458 7.17375 10.3053 7.81685 9.88177 8.24037C9.55256 8.56959 9.55256 9.16118 9.88177 9.49039L14.5031 14.1118C14.6128 14.2215 14.8585 14.3364 15.1281 14.3364C15.3978 14.3364 15.6434 14.2215 15.7532 14.1118C16.6682 13.1968 18.2095 13.1968 19.1245 14.1118L22.0573 17.0445C22.9723 17.9595 22.9723 19.5009 22.0573 20.4159L21.3463 21.1269C19.4911 22.982 16.5246 23.279 14.2811 21.8514C14.2771 21.8488 14.273 21.8462 14.269 21.8435C14.2649 21.8408 14.2609 21.8381 14.2569 21.8353C9.56094 18.5842 5.40676 14.4304 2.15448 9.64229L2.15083 9.63691L2.15086 9.6369C0.710112 7.47578 1.01429 4.51018 2.94373 2.65821L3.54355 1.9727C3.56547 1.94764 3.58904 1.92407 3.6141 1.90215ZM4.63577 3.00234L4.05031 3.67144C4.0349 3.68905 4.01867 3.70593 4.00168 3.72202C2.5783 5.07049 2.35803 7.24073 3.39721 8.80227C6.5406 13.4293 10.5575 17.4475 15.0983 20.5934C16.7637 21.6455 18.9446 21.4073 20.2856 20.0662L20.9966 19.3552C21.3258 19.026 21.3258 18.4344 20.9966 18.1052L18.0638 15.1724C17.7346 14.8432 17.143 14.8432 16.8138 15.1724C16.3903 15.5959 15.7472 15.8364 15.1281 15.8364C14.5091 15.8364 13.866 15.5959 13.4425 15.1724L8.82112 10.5511C7.90612 9.63605 7.90612 8.09471 8.82112 7.17971C8.93083 7.07 9.04581 6.82438 9.04581 6.5547C9.04581 6.37586 9.027 6.24227 8.99542 6.14755C8.96692 6.06203 8.93389 6.0237 8.90145 5.99936C8.86997 5.97576 8.84041 5.94972 8.81302 5.92147L5.99292 3.01324C5.57895 2.66593 5.05209 2.66229 4.63577 3.00234Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
