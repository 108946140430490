import React, { useMemo } from 'react';
import { Hidden, Typography } from '@material-ui/core';
import { Button } from '@chhjpackages/components';

import { WeAreNotHereYetIcon } from 'common/assets/icons/WeAreNotHereYetIcon';

import { useStyles } from './Unserviced.styles';
import { UnservicedProps } from './Unserviced.types';

export const Unserviced = ({ type }: UnservicedProps) => {
  const styles = useStyles();

  const name = useMemo(() => {
    return type === 'junkHaulers' ? 'Junk Haulers' : 'Movers';
  }, [type]);

  const btnLink = useMemo(() => {
    return type === 'junkHaulers' ? 'http://www.homeadvisor.com/ext/32014119' : 'http://t.chhj.com/VendorMoveQuote';
  }, [type]);

  return (
    <div className={styles.bgWrapper}>
      <div className={styles.wrapper}>
        <Hidden smUp>
          <Typography variant="h2" className={styles.title}>
            sorry, We are not here yet
          </Typography>
        </Hidden>

        <div className={styles.flexWrapper}>
          <WeAreNotHereYetIcon className={styles.icon} />
          <div>
            <Hidden xsDown>
              <Typography variant="h2" className={styles.title}>
                sorry, We are not here yet
              </Typography>
            </Hidden>

            <Typography className={styles.bolderText}>We don’t service your neighborhood yet</Typography>
            <Typography className={styles.text}>
              Click the links below to find qualified {name} in your area, or to learn more about our other services.
            </Typography>
          </div>
        </div>

        <div className={styles.buttonsWrapper}>
          <Button href="https://www.collegehunkshaulingjunk.com" fullWidth size="large" buttonType="twoTone">
            about college h.u.n.k.s.
          </Button>

          <Button href={btnLink} fullWidth size="large" buttonType="outlined">
            {name} in my area
          </Button>
        </div>
      </div>
    </div>
  );
};
