import { makeStyles } from '@material-ui/core';
import { colors } from '@chhjpackages/components';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  margin: {
    marginBottom: 16,
  },
  field: {
    maxWidth: 343,
    marginBottom: 24,
  },
  content: {
    maxWidth: 432,

    [theme.breakpoints.up('md')]: {
      maxWidth: 651,
    },
  },
  buttonsWrapper: {
    marginTop: 32,

    [theme.breakpoints.up('md')]: {
      marginTop: 56,
    },
  },
  h5: {
    textTransform: 'uppercase',
    marginBottom: 24,
  },
  body1: {
    textAlign: 'center',
    marginBottom: 24,

    [theme.breakpoints.up('sm')]: {
      textAlign: 'left',
    },
  },
  error: {
    color: colors.functionals.alert,
    fontWeight: 'bold',
  },
}));
