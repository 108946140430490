import { useContext } from 'react';

import { MovingDispatchContext, MovingStateContext } from 'bMove/context/moving/MovingContext';
import { MovingDispatchContextValue, MovingStateContextValue } from 'bMove/context/moving/MovingContext.types';

export const useMovingStateContext = () => {
  const context = useContext(MovingStateContext);

  if (context === undefined) {
    throw new Error('useMovingStateContext must be used within a MovingContextController');
  }

  return context;
};

export const useMovingVerBOptionalStateContext = () => {
  return useContext(MovingStateContext);
};

export const useMovingVerBOptionalDispatchContext = () => {
  return useContext(MovingDispatchContext);
};

export const useMovingDispatchContext = () => {
  const context = useContext(MovingDispatchContext);

  if (context === undefined) {
    throw new Error('useMovingDispatchContext must be used within a MovingContextController');
  }

  return context;
};

export const useMovingContext: () => [MovingStateContextValue, MovingDispatchContextValue] = () => [
  useMovingStateContext(),
  useMovingDispatchContext(),
];
