import { BasicResponseStructure, CategoryIdEnum } from 'common/api/types';

/**
 * INFO for devs:
 * when providing address when creating account ex. for junkRemoval step3, all values should be provided
 * (except address2 which is always optional).
 * Address, city, and state is also optional only in account creation in "CallMeNowModal"
 */
export type BillingAddress = {
  address?: string; //Proper US order of address should be ${street_number} ${route} ex. 123 Main Street
  address2?: string; // address2 is value from Apartment/suite field
  city?: string;
  state?: string;
  postal: string;
  country: string;
};

export type AccountResponse = {
  id: number;
  first_name: string;
  last_name: string;
  company: {
    name: string;
  };
  phone: string;
  work: string;
  cell: string;
  email: string;
  billing_address: BillingAddress;
  type: {
    id: AccountTypeEnum;
    name: 'Commercial' | 'Residential';
  };
  notes?: {
    information?: string;
    special_instructions?: string;
  };
  source: {
    id: number;
    name: string;
  };
  category: {
    id: CategoryIdEnum;
    name: string;
  };
  location: {
    id: number;
    name: string;
  };
  created: {
    by: string;
    date: Date;
  };
};

export type TrackingData = {
  source?: string;
  medium?: string;
  campaign?: string;
  content?: string;
  term?: string;
};

export enum AccountTypeEnum {
  Commercial = 3,
  Residential = 5,
}

export type AccountPayload = {
  first_name: string;
  last_name: string;
  phone: string;
  email?: string; // not required in callMeModal
  billing_address: BillingAddress;
  category: {
    id: CategoryIdEnum;
  };
  tracking?: TrackingData;
  notes?: {
    information?: string;
    special_instructions?: string;
  };
  company?: {
    name: string;
  };
  type?: {
    id: AccountTypeEnum;
  };
  subpartner?: {
    id: number;
  };
};

export declare namespace CreateAccountAction {
  export type Params = {
    locationId: number;
    payload: AccountPayload;
  };
  export type TokenData = {
    token: string;
    account: AccountResponse;
  };
  export type Response = {
    tokens?: TokenData[];
  } & BasicResponseStructure;
}

export declare namespace UpdateAccountAction {
  export type Params = {
    locationId: number;
    accountId: number;
    token: string;
    payload: Partial<AccountPayload>;
  };
  export type Response = {
    accounts?: AccountResponse[];
  } & BasicResponseStructure;
}

export type PingPayload = {
  locationId: number;
  accountId: number;
  booking_complete: boolean;
  token: string;
};

export type PingResponse = {
  accounts?: Array<{
    id: number;
    booking_complete: boolean;
  }>;
} & BasicResponseStructure;

export type CreateAccountAddressPayload = {
  locationId: number;
  accountId: number;
  token: string;

  fullAddress: BillingAddress;
};

type AddressWithOrigin = {
  id: number;
} & BillingAddress;

export type CreateAccountAddressResponse = {
  addresses?: AddressWithOrigin[];
} & BasicResponseStructure;

export type CreateNewAppointmentPayload = {
  locationId: number;
  token: string;

  start_date: Date;
  end_date: Date;
  account: {
    id: number;
  };
  category: {
    id: CategoryIdEnum;
  };
  origin: {
    id: number;
  };
  create_schedule: boolean; // if true, resource->id required
  resource?: {
    id: number;
  };
  notes?: {
    type_of_junk: string;
  };
  purchase_order?: string;
  partner?: {
    id: number;
  };
};

type AppointmentData = {
  id: number;
  type: string;
  start_date: string;
  end_date: string;

  category: {
    id: CategoryIdEnum;
  };

  account: {
    id: number;
  };

  location: {
    id: number;
  };
};

export type CreateNewAppointmentResponse = {
  appointments?: AppointmentData[];
} & BasicResponseStructure;

export type CreateCallRequestPayload = {
  locationId: number;
  accountId: number;
  token: string;

  category: {
    id: CategoryIdEnum;
  };
  subpartner?: {
    id: number;
  };
};

export type CreateCallRequestResponse = {
  requests?: Array<{ scheduled: boolean }>;
} & BasicResponseStructure;

export type QuoteAddress = BillingAddress & {
  floor: number | null; // floor is not required, null === undefined
  access_type: number;
  long_walks: number;
};

export type QuoteRequestParams = {
  locationId: number;
  accountId: number;
  token: string;

  payload: {
    email_client: boolean;
    rooms: string;
    hunks: number;
    appliances: number;
    assembly_items: number;
    heavy_items: number;
    date: string;
    location: {
      id: number;
    };
    category: {
      id: number;
    };
    account: {
      id: number;
      first_name: string;
      last_name: string;
      phone: string;
      email: string;
    };
    type: {
      id: number;
    };
    origin: QuoteAddress;
    destination: QuoteAddress;
  };
};

export type CalculatedQuote = {
  call_required: boolean;
  hourly_rate: number;
  hunks: number;
  total_price: {
    low: number;
    high: number;
  };
  labor_hours: {
    low: number;
    high: number;
  };
  truck_and_travel: number;
  pre_payment: {
    amount: number;
    collect_online: number;
    refund_days: number;
    refundable: boolean;
    refundable_by: string;
    square_location_id: string;
  };
  // There is more properties, but no need to type them
};

export type QuoteRequestResponse = {
  quote?: CalculatedQuote[];
} & BasicResponseStructure;
