import { LaborStateContextValue } from './LaborContext.types';

export const LABOR_KEY = 'OBT/LABOR_STATE';

export const laborDefaultState: LaborStateContextValue = {
  labor: {},
};

class LaborStorageClass {
  private _state: LaborStateContextValue = laborDefaultState;

  constructor() {
    try {
      const formStateString = sessionStorage.getItem(LABOR_KEY);

      if (formStateString) {
        this._state = JSON.parse(formStateString);
      }
    } catch {
      this._state = laborDefaultState;
    }
  }

  get state(): LaborStateContextValue {
    const formStateString = sessionStorage.getItem(LABOR_KEY);

    if (formStateString) {
      this._state = JSON.parse(formStateString);
    }

    return this._state;
  }

  set state(value: LaborStateContextValue) {
    this._state = value;

    try {
      sessionStorage.setItem(LABOR_KEY, JSON.stringify(value));
    } catch {}
  }

  clear() {
    this._state = laborDefaultState;
    sessionStorage.removeItem(LABOR_KEY);
  }
}

export const laborStorage = new LaborStorageClass();
