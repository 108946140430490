import React from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Collapse } from '@material-ui/core';

import { GoogleMapsProps } from './GoogleMaps.types';
import { useStyles } from './GoogleMaps.styles';

/**
 * Must be wrapped wrapped by GoogleScriptsController
 */
export const GoogleMaps = ({ location }: GoogleMapsProps) => {
  const styles = useStyles();

  return (
    <Collapse in={!!location}>
      {location && (
        <GoogleMap
          center={location}
          zoom={13}
          options={{ fullscreenControl: false, zoomControl: false, mapTypeControl: false, streetViewControl: false }}
          mapContainerClassName={styles.map}
        >
          <Marker position={location} />
        </GoogleMap>
      )}
    </Collapse>
  );
};
