import { useCallback } from 'react';

import { resetForm } from 'common/context/form/formActionCreators/formActionCreators';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { useLaborDispatchContext } from 'common/hooks/useLabor/useLabor';
import { laborActions } from 'common/context/labor/LaborContextContextSlice';
import { junkRemovalActions } from 'common/context/junkRemoval/JunkRemovalContextSlice';
import { useJunkRemovalDispatchContext } from 'common/hooks/useJunkRemoval/useJunkRemoval';
import { useVersionContext } from 'common/hooks/useVersion/useVersion';
import { useMovingOptionalDispatchContext } from 'aMove/hooks/useMoving/useMoving';
import { movingActions } from 'aMove/context/moving/MovingContextSlice';
import { useMovingVerBOptionalDispatchContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions as movingVerBActions } from 'bMove/context/moving/MovingContextSlice';

export const useResetState = () => {
  const dispatch = useFormDispatch();
  const { moveVer } = useVersionContext();
  const { laborDispatch } = useLaborDispatchContext();
  const { junkRemovalDispatch } = useJunkRemovalDispatchContext();
  const movingDispatchContext = useMovingOptionalDispatchContext();
  const movingVerBDispatchContext = useMovingVerBOptionalDispatchContext();

  return useCallback(() => {
    dispatch(resetForm());

    laborDispatch(laborActions.reset());
    junkRemovalDispatch(junkRemovalActions.reset());
    if (moveVer === 'A' && movingDispatchContext) {
      movingDispatchContext.movingDispatch(movingActions.reset());
    }
    if (moveVer === 'B' && movingVerBDispatchContext) {
      movingVerBDispatchContext.movingDispatch(movingVerBActions.reset());
    }
  }, [dispatch, moveVer, laborDispatch, junkRemovalDispatch, movingDispatchContext, movingVerBDispatchContext]);
};
