import React from 'react';
import { Typography, Hidden } from '@material-ui/core';

import { Container } from 'common/app/home/container/Container';
import { LoadedImage } from 'common/ui/loadedImage/LoadedImage';
import inTheCommunityImg from 'common/assets/images/inTheCommunity.jpg';

import { useStyles } from './InTheCommunity.styles';

export const InTheCommunity = () => {
  const styles = useStyles();

  return (
    <Container className={styles.container}>
      <div className={styles.containerShadow}>
        <Hidden smUp>
          <Typography color="secondary" variant="h4" className={styles.title}>
            College HUNKS: In the Community
          </Typography>
        </Hidden>

        <div className={styles.flexContainer}>
          <div className={styles.content}>
            <Hidden xsDown>
              <Typography color="secondary" variant="h3" className={styles.title}>
                College HUNKS: In the Community
              </Typography>
            </Hidden>
            <Typography>
              Part of our company mission is to become a values-based, socially conscious brand. To support this
              mission, we're partnered with local charities to donate or recycle your unwanted items, provide meals to
              the hungry, and safely move domestic violence victims at a discounted rate. We strive to Move the World -
              not just physically but emotionally too.
            </Typography>
          </div>
          <div>
            <LoadedImage src={inTheCommunityImg} alt="In the community" className={styles.img} />
          </div>
        </div>
      </div>
    </Container>
  );
};
