import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  summaryContent: {
    flexGrow: 1,
  },
  textContent: {
    maxWidth: 489,
  },
  title: {
    marginBottom: 16,
  },
  subtitle: {
    marginBottom: 32,

    [theme.breakpoints.up('md')]: {
      marginBottom: 40,
    },
  },
  buttonsGroup: {
    marginTop: 48,
  },
}));
