const baseIntlOptions: Intl.NumberFormatOptions = {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
};

export const currencyFormatter = (options?: Intl.NumberFormatOptions) => {
  return new Intl.NumberFormat('en-US', {
    ...baseIntlOptions,
    ...options,
  });
};

export const formatCurrency = currencyFormatter();
export const formatCurrencyWithoutSymbol = currencyFormatter({
  style: undefined,
  currency: undefined,
});

// it returns price like: $10.50-13.15
export const getPriceRange = (price1: number, price2: number) => {
  return `${formatCurrency.format(price1)}-${formatCurrencyWithoutSymbol.format(price2)}`;
};
