import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router';
import { useTheme, useMediaQuery } from '@material-ui/core';

// Scroll to top (tablet/desktops) or to main section (like to title ex. "2. job date") if path changes
export const useScrollBehavior = () => {
  const theme = useTheme();
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const scrollToElementId = isMobile ? 'main' : 'header';

  useLayoutEffect(() => {
    document.getElementById(scrollToElementId)?.scrollIntoView({ behavior: 'smooth' });
  }, [pathname, scrollToElementId]);
};
