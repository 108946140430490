import { JunkRemovalContextReducerState } from './JunkRemovalContext.types';

export const JUNK_REMOVAL_KEY = 'OBT/JUNK_REMOVAL_STATE';

export const junkRemovalDefaultState: JunkRemovalContextReducerState = {
  junkRemoval: {},
  pricing: {},
};

class JunkRemovalStorageClass {
  private _state: JunkRemovalContextReducerState = junkRemovalDefaultState;

  constructor() {
    try {
      const formStateString = sessionStorage.getItem(JUNK_REMOVAL_KEY);

      if (formStateString) {
        this._state = JSON.parse(formStateString);
      }
    } catch {
      this._state = junkRemovalDefaultState;
    }
  }

  get state(): JunkRemovalContextReducerState {
    const formStateString = sessionStorage.getItem(JUNK_REMOVAL_KEY);

    if (formStateString) {
      this._state = JSON.parse(formStateString);
    }

    return this._state;
  }

  set state(value: JunkRemovalContextReducerState) {
    this._state = value;

    try {
      sessionStorage.setItem(JUNK_REMOVAL_KEY, JSON.stringify(value));
    } catch {}
  }

  clear() {
    this._state = junkRemovalDefaultState;
    sessionStorage.removeItem(JUNK_REMOVAL_KEY);
  }
}

export const junkRemovalStorage = new JunkRemovalStorageClass();
