import React, { useCallback } from 'react';
import { Box, Typography, Grid } from '@material-ui/core';
import { Button, IncrementInput } from '@chhjpackages/components';
import { Controller, useForm } from 'react-hook-form';
import { Redirect, useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { ButtonsGroup } from 'common/ui/buttonsGroup/ButtonsGroup';
import { TipBox } from 'common/ui/tipBox/TipBox';
import { movingRoutes } from 'bMove/routing/AppRoute';
import { useMovingContext } from 'bMove/hooks/useMoving/useMoving';
import { movingActions } from 'bMove/context/moving/MovingContextSlice';

import { MovingStep3Field, MovingStep3FormValues } from './MovingStep3.types';
import { useStyles } from './MovingStep3.styles';

const fields: MovingStep3Field[] = [
  {
    title: 'Appliances larger than a dishwasher',
    name: 'appliances',
    enableBgc: true,
  },
  {
    title: 'Items that require more than 2 people to lift',
    name: 'heavyItems',
  },
  {
    title: 'Items that need to be assembled',
    name: 'assemblyItems',
    enableBgc: true,
  },
];

export const MovingStep3 = () => {
  const styles = useStyles();
  const history = useHistory();
  const [{ moving }, { movingDispatch }] = useMovingContext();
  const { handleSubmit, control } = useForm<MovingStep3FormValues>({
    defaultValues: {
      appliances: moving?.step3?.appliances ?? 0,
      heavyItems: moving?.step3?.heavyItems ?? 0,
      assemblyItems: moving?.step3?.assemblyItems ?? 0,
    },
  });

  const handleSubmitCallback = useCallback(
    (values: MovingStep3FormValues) => {
      movingDispatch(movingActions.setStep3(values));
      history.push(movingRoutes.step4);
    },
    [movingDispatch, history],
  );

  if (!moving?.step2) {
    return <Redirect to={movingRoutes.step2} />;
  }

  return (
    <form onSubmit={handleSubmit(handleSubmitCallback)} className={styles.form}>
      <Grid container direction="column" className={styles.content}>
        <Grid item>
          <Typography variant="h4">3. Special items</Typography>
        </Grid>

        <Box mb={3} />

        <Grid item>
          <Typography variant="body1">
            These types of items require special handling and may take additional time to move.
          </Typography>
        </Grid>

        <Box mb={2} />

        {fields.map(({ title, name, enableBgc }) => (
          <Grid item className={clsx([enableBgc && styles.boxBgc, styles.box])} key={name}>
            <Typography variant="subtitle1" className={styles.label}>
              {title}
            </Typography>

            <Controller
              name={name}
              control={control}
              rules={{ required: '*required' }}
              render={({ onChange, value }) => (
                <IncrementInput
                  value={value}
                  onChange={(value) => onChange(typeof value === 'number' ? value : 0)}
                  min={0}
                  max={99}
                />
              )}
            />
          </Grid>
        ))}
      </Grid>

      <Box mt="auto">
        <TipBox iconName="muscle">The HUNKS are specially trained to handle heavy and special items.</TipBox>

        <ButtonsGroup>
          <Button onClick={() => history.push(movingRoutes.step2)} fullWidth size="large" buttonType="outlined">
            Back
          </Button>

          <Button type="submit" fullWidth size="large" buttonType="twoTone">
            Next
          </Button>
        </ButtonsGroup>
      </Box>
    </form>
  );
};
