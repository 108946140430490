import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-fetching-library';
import moment from 'moment';

import { rudderanalytics } from 'common/utils/rudderanalytics';
import { useFormState } from 'common/hooks/useFormState/useFormState';
import {
  calculateQuoteAction,
  createCallRequestAction,
  createNewQuoteAction,
} from 'common/api/actions/account/accountActions';
import { useFormDispatch } from 'common/hooks/useFormDispatch/useFormDispatch';
import { setBookingDoneAndContact } from 'common/context/form/formActionCreators/formActionCreators';
import { movingRoutes } from 'common/routing/AppRoute';
import { ProcessingRequest } from 'common/app/processingRequest/ProcessingRequest';
import { CategoryIdEnum } from 'common/api/types';
import { mapTruckParkingToLongWalks } from 'common/utils/form';
import { useMovingStateContext } from 'aMove/hooks/useMoving/useMoving';
import { DateFormatForAPI } from 'common/utils/dateTime';

export const MovingProcessingRequest = () => {
  const history = useHistory();
  const { locationId, accountId, token, partnerData } = useFormState();
  const { moving } = useMovingStateContext();
  const dispatch = useFormDispatch();
  const { mutate: createCallRequestMutate, abort: abortCallRequest } = useMutation(createCallRequestAction);
  const { mutate: calculateQuoteMutate, abort: calculateQuoteAbort } = useMutation(calculateQuoteAction);
  const { mutate: createQuoteMutate, abort: createQuoteAbort } = useMutation(createNewQuoteAction);

  const calculateQuote = useCallback(async () => {
    if (
      !moving?.step1 ||
      !moving?.step2 ||
      !moving?.step3 ||
      !moving?.step4 ||
      !moving?.step5 ||
      !locationId ||
      !accountId ||
      !token
    ) {
      return;
    }

    calculateQuoteAbort();
    const { payload: calculatePayload } = await calculateQuoteMutate({
      locationId,
      accountId,
      token,
      payload: {
        hunks: 2, // default value 2
        rooms: moving.step1.rooms,
        appliances: moving.step3.appliances,
        assembly_items: moving.step3.assemblyItems,
        heavy_items: moving.step3.heavyItems,
        email_client: false,
        date: moment(moving.step4.date).format(DateFormatForAPI),
        location: {
          id: locationId,
        },
        account: {
          id: accountId,
          first_name: moving.step5.firstName,
          last_name: moving.step5.lastName,
          email: moving.step5.email,
          phone: moving.step5.phone,
        },
        origin: {
          ...moving.step1.fullAddress,
          floor: moving.step1.floor,
          access_type: moving.step1.accessType,
          long_walks: mapTruckParkingToLongWalks(moving.step1.truckParking),
        },
        destination: {
          ...moving.step2.fullAddress,
          floor: moving.step2.floor,
          access_type: moving.step2.accessType,
          long_walks: mapTruckParkingToLongWalks(moving.step2.truckParking),
        },
        category: {
          id: CategoryIdEnum.move,
        },
        type: {
          id: 1,
        },
      },
    });

    if (!calculatePayload?.quote?.length) return undefined;

    return calculatePayload.quote[0].hunks;
  }, [calculateQuoteAbort, calculateQuoteMutate, moving, locationId, accountId, token]);

  const createQuote = useCallback(
    async (numberOfHunks: number) => {
      if (
        !moving?.step1 ||
        !moving?.step2 ||
        !moving?.step3 ||
        !moving?.step4 ||
        !moving?.step5 ||
        !locationId ||
        !accountId ||
        !token
      ) {
        return;
      }

      createQuoteAbort();
      await createQuoteMutate({
        locationId,
        accountId,
        token,
        payload: {
          hunks: numberOfHunks,
          email_client: false,
          rooms: moving.step1.rooms,
          appliances: moving.step3.appliances,
          assembly_items: moving.step3.assemblyItems,
          heavy_items: moving.step3.heavyItems,
          date: moment(moving.step4.date).format(DateFormatForAPI),
          location: {
            id: locationId,
          },
          account: {
            id: accountId,
            first_name: moving.step5.firstName,
            last_name: moving.step5.lastName,
            email: moving.step5.email,
            phone: moving.step5.phone,
          },
          origin: {
            ...moving.step1.fullAddress,
            floor: moving.step1.floor || 0,
            access_type: moving.step1.accessType,
            long_walks: mapTruckParkingToLongWalks(moving.step1.truckParking),
          },
          destination: {
            ...moving.step2.fullAddress,
            floor: moving.step2.floor || 0,
            access_type: moving.step2.accessType,
            long_walks: mapTruckParkingToLongWalks(moving.step2.truckParking),
          },
          category: {
            id: CategoryIdEnum.move,
          },
          type: {
            id: 1,
          },
        },
      });
    },
    [createQuoteAbort, createQuoteMutate, moving, token, locationId, accountId],
  );

  const createCallRequest = useCallback(async () => {
    if (!moving?.step5 || !token || !locationId || !accountId) {
      return;
    }

    abortCallRequest();

    const { payload: callPayload } = await createCallRequestMutate({
      locationId,
      accountId,
      token,
      category: { id: CategoryIdEnum.move },
      ...(partnerData ? { subpartner: { id: partnerData.subpartnerId } } : {}),
    });

    if (callPayload?.meta.status.code === 200) {
      rudderanalytics.track(`Web Lead Call Scheduled`);
      dispatch(
        setBookingDoneAndContact({
          type: 'phone',
          contact: moving.step5.phone,
        }),
      );
      history.push(movingRoutes.alldone);
    }
  }, [
    abortCallRequest,
    createCallRequestMutate,
    dispatch,
    history,
    moving?.step5,
    token,
    locationId,
    accountId,
    partnerData,
  ]);

  const process = useCallback(async () => {
    const numberOfHunks = await calculateQuote();

    if (numberOfHunks !== undefined) {
      await createQuote(numberOfHunks);
    }

    await createCallRequest();
  }, [calculateQuote, createCallRequest, createQuote]);

  return <ProcessingRequest onProcess={process} />;
};
