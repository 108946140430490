import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const FiveStarsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="80" height="80" viewBox="0 0 80 80" fill="none">
      <g clipPath="url(#clip1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.0503 19.1567L17.7181 24.5962C17.8335 24.9195 18.1454 25.035 18.4687 25.1389L24.451 25.9935C25.2016 26.0975 25.5134 27.056 24.9822 27.5988L20.7092 31.7679C20.5013 31.9642 20.3974 32.2876 20.3974 32.611L21.4598 38.5932C21.5638 39.3438 20.8247 39.979 20.074 39.5517L14.7385 36.78C14.4151 36.6761 14.1033 36.6761 13.8839 36.78L8.54839 39.5517C7.91321 39.8751 7.0586 39.3438 7.16254 38.5932L8.22502 32.611C8.22502 32.2876 8.22502 31.9758 7.90166 31.7563L3.5247 27.5873C2.99346 27.056 3.31682 26.0975 4.05594 25.9935L10.0382 25.1389C10.3615 25.1389 10.5694 24.9311 10.7888 24.6077L13.4566 19.1683C13.6645 18.5215 14.7385 18.5215 15.0503 19.1567Z"
          fill="#F1CCA5"
        />
        <path
          d="M17.718 38.42C17.4178 38.42 17.1175 38.3391 16.8288 38.1774L11.5857 35.4519C11.4702 35.4173 11.4009 35.4288 11.3778 35.4288L6.07695 38.189C5.47641 38.4892 4.77194 38.42 4.22915 38.0273C3.69791 37.6462 3.43229 37.0341 3.51313 36.3989L4.57562 30.4629C4.57562 30.3936 4.57562 30.3128 4.56407 30.2781L0.129364 26.109C-0.355681 25.624 -0.505815 24.9426 -0.297938 24.3074C-0.0900612 23.6723 0.452728 23.2103 1.09946 23.1179L7.08168 22.2633C7.11633 22.2749 7.16253 22.2402 7.26646 22.0785L9.88802 16.7315C10.0382 16.2464 10.5809 15.8422 11.2392 15.796C11.9668 15.7498 12.6251 16.1079 12.9369 16.7199L15.6046 22.1594C15.6277 22.194 15.7201 22.2287 15.8125 22.2633L21.7139 23.1064C22.3606 23.1988 22.9034 23.6492 23.1113 24.2959C23.3192 24.9311 23.169 25.624 22.7071 26.0859L18.4341 30.255C18.411 30.2781 18.3648 30.3705 18.3648 30.4744L19.4157 36.3643C19.5196 37.0688 19.2194 37.7386 18.665 38.1197C18.3763 38.3276 18.053 38.42 17.718 38.42ZM11.4355 33.812C11.6665 33.812 11.909 33.8467 12.1516 33.9275L12.2671 33.9737L17.6026 36.7454C17.7065 36.8031 17.7411 36.7916 17.7527 36.78C17.7758 36.7569 17.822 36.6992 17.8104 36.6184L16.7364 30.5322C16.7364 29.9778 16.9443 29.4582 17.2907 29.1117L21.5638 24.9426C21.5984 24.908 21.5869 24.8502 21.5753 24.8156C21.5638 24.7809 21.5522 24.7232 21.4945 24.7116L15.3737 23.8224C14.9925 23.6954 14.3458 23.4875 14.1148 22.7715L11.4933 17.436C11.4702 17.4129 11.3663 17.3898 11.3201 17.4129L8.66386 22.8869C8.45598 23.2219 8.01713 23.8686 7.19717 23.8686L1.33043 24.7116C1.27269 24.7232 1.24959 24.7809 1.23804 24.8156C1.22649 24.8502 1.21494 24.908 1.24959 24.9426L5.5688 29.054C6.18088 29.5044 6.18089 30.1742 6.18089 30.5322L6.16934 30.6708L5.10686 36.653C5.22234 36.7569 5.29164 36.7685 5.33783 36.7454L10.6618 33.9737C10.8928 33.8698 11.1584 33.812 11.4355 33.812Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M42.4323 19.1567L45.1001 24.5962C45.204 24.9195 45.5274 25.035 45.8508 25.1389L51.833 25.9935C52.5837 26.0975 52.8955 27.056 52.3642 27.5988L48.0912 31.7679C47.8833 31.9758 47.7678 32.2991 47.7678 32.6225L48.8303 38.6047C48.9343 39.3554 48.1951 39.9906 47.4445 39.5633L42.109 36.7916C41.7856 36.6876 41.4738 36.6876 41.2544 36.7916L35.9189 39.5633C35.2837 39.8866 34.4291 39.3554 34.533 38.6047L35.5955 32.6225C35.5955 32.2991 35.5955 31.9873 35.2721 31.7679L30.8952 27.5988C30.3639 27.0676 30.6873 26.109 31.4264 25.9935L37.4087 25.1389C37.732 25.1389 37.9399 24.9311 38.1593 24.6077L40.8271 19.1683C41.0465 18.5215 42.109 18.5215 42.4323 19.1567Z"
          fill="#F1CCA5"
        />
        <path
          d="M45.1001 38.42C44.7998 38.42 44.4995 38.3391 44.2108 38.1774L38.9677 35.4519C38.8522 35.4173 38.7829 35.4288 38.7598 35.4288L33.459 38.189C32.8584 38.4892 32.154 38.42 31.6112 38.0273C31.0799 37.6462 30.8143 37.0341 30.8952 36.3989L31.9461 30.4629C31.9461 30.3936 31.9461 30.3128 31.9345 30.2781L27.4998 26.0975C27.0148 25.624 26.8647 24.9311 27.0725 24.2959C27.2804 23.6607 27.8232 23.1988 28.4699 23.1064L34.4522 22.2518C34.4868 22.2633 34.533 22.2287 34.6369 22.067L37.2585 16.7199C37.4086 16.2349 37.9514 15.8422 38.6097 15.796C39.3257 15.7498 39.9955 16.1079 40.3074 16.7199L42.9751 22.1594C42.9982 22.194 43.0906 22.2287 43.183 22.2633L49.0844 23.1064C49.7311 23.1988 50.2739 23.6492 50.4818 24.2959C50.6896 24.9311 50.5395 25.624 50.0776 26.0859L45.8045 30.255C45.7814 30.2781 45.7352 30.359 45.7352 30.4744L46.7862 36.3643C46.8901 37.0688 46.5899 37.7386 46.0355 38.1197C45.7468 38.3276 45.4234 38.42 45.1001 38.42ZM38.806 33.812C39.037 33.812 39.2795 33.8467 39.522 33.9275L39.6375 33.9737L44.973 36.7454C45.077 36.8031 45.1116 36.7916 45.1232 36.78C45.1463 36.7569 45.1925 36.6992 45.1809 36.6184L44.1069 30.5322C44.1069 29.9778 44.3148 29.4582 44.6612 29.1117L48.9342 24.9426C48.9689 24.908 48.9573 24.8502 48.9458 24.8156C48.9342 24.7809 48.9111 24.7232 48.8534 24.7232L42.7326 23.8339C42.3515 23.7069 41.7048 23.499 41.4738 22.783L38.8522 17.4475C38.8291 17.4244 38.7252 17.4013 38.679 17.4244L36.0343 22.8869C35.8149 23.2219 35.3876 23.8686 34.5561 23.8686L28.7125 24.7116C28.6547 24.7232 28.6316 24.7809 28.6201 24.804C28.6085 24.8271 28.597 24.8964 28.6432 24.9426L32.9624 29.054C33.5745 29.5044 33.5745 30.1742 33.5745 30.5322L33.5629 30.6708L32.5004 36.653C32.6159 36.7569 32.6968 36.7685 32.7314 36.7454L38.0554 33.9737C38.2632 33.8698 38.5289 33.812 38.806 33.812Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M69.8027 19.1567L72.4705 24.5962C72.5859 24.9195 72.8978 25.035 73.2211 25.1389L79.2033 25.9935C79.954 26.0975 80.2658 27.056 79.7346 27.5988L75.4616 31.7679C75.2537 31.9758 75.1382 32.2991 75.1382 32.6225L76.2007 38.6047C76.3046 39.3554 75.5655 39.9906 74.8148 39.5633L69.4793 36.7916C69.156 36.6876 68.8442 36.6876 68.6247 36.7916L63.2892 39.5633C62.6541 39.8866 61.7994 39.3554 61.9034 38.6047L62.9659 32.6225C62.9659 32.2991 62.9659 31.9873 62.6425 31.7679L58.2655 27.5988C57.7343 27.0676 58.0577 26.109 58.7968 25.9935L64.779 25.1389C65.1024 25.1389 65.3103 24.9311 65.5297 24.6077L68.1974 19.1683C68.4169 18.5215 69.4909 18.5215 69.8027 19.1567Z"
          fill="#F1CCA5"
        />
        <path
          d="M72.4705 38.42C72.1703 38.42 71.8584 38.3391 71.5813 38.1774L66.3382 35.4519C66.2227 35.4173 66.1534 35.4288 66.1303 35.4288L60.8294 38.189C60.2289 38.4892 59.5244 38.42 58.9816 38.0273C58.4504 37.6462 58.1848 37.0341 58.2772 36.3989L59.3397 30.4629C59.3397 30.3936 59.3397 30.3128 59.3281 30.2781L54.8934 26.0975C54.4084 25.624 54.2582 24.9311 54.4661 24.2959C54.674 23.6607 55.2168 23.1988 55.8635 23.1064L61.8457 22.2518C61.8804 22.2633 61.9266 22.2287 62.0305 22.067L64.6521 16.7199C64.8022 16.2349 65.345 15.8422 66.0033 15.796C66.7308 15.7498 67.3891 16.1079 67.7009 16.7199L70.3687 22.1594C70.3918 22.194 70.4842 22.2287 70.5765 22.2633L76.4779 23.1064C77.1247 23.1988 77.6674 23.6492 77.8753 24.2959C78.0832 24.9311 77.9331 25.624 77.4711 26.0859L73.1981 30.255C73.175 30.2781 73.1288 30.359 73.1288 30.4744L74.1797 36.3643C74.2837 37.0688 73.9834 37.7386 73.4291 38.1197C73.1288 38.3276 72.8054 38.42 72.4705 38.42ZM66.188 33.812C66.419 33.812 66.6615 33.8582 66.9041 33.9275L67.0195 33.9737L72.355 36.7454C72.4705 36.8031 72.4936 36.7916 72.5052 36.78C72.5283 36.7569 72.5745 36.6992 72.5629 36.6184L71.4889 30.5206C71.4889 29.9663 71.6968 29.4466 72.0432 29.1001L76.3162 24.9311C76.3509 24.8964 76.3393 24.8387 76.3278 24.804C76.3163 24.7694 76.2932 24.7116 76.2354 24.7116L70.1146 23.8224C69.7335 23.6954 69.0868 23.4875 68.8558 22.7715L66.2458 17.4475C66.2227 17.4244 66.1187 17.4013 66.0725 17.4244L63.4279 22.8869C63.2085 23.2219 62.7812 23.8686 61.9497 23.8686L56.0829 24.7116C56.0252 24.7232 56.0021 24.7809 55.9905 24.804C55.979 24.8271 55.9674 24.8964 56.0136 24.9426L60.3328 29.054C60.9449 29.5044 60.9449 30.1742 60.9449 30.5322L60.9334 30.6708L59.8709 36.653C59.9864 36.7569 60.0672 36.7685 60.1019 36.7454L65.4258 33.9737C65.6452 33.8698 65.9109 33.812 66.188 33.812Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.3265 43.051L29.9943 48.4904C30.0982 48.8138 30.4216 48.9177 30.7449 49.0217L36.7271 49.8763C37.4778 49.9802 37.7896 50.9388 37.2584 51.4816L32.9854 55.6506C32.7775 55.8585 32.662 56.1819 32.662 56.5052L33.7245 62.4875C33.8284 63.2381 33.0893 63.8733 32.3386 63.446L27.0031 60.6743C26.6798 60.5704 26.368 60.5704 26.1485 60.6743L20.813 63.446C20.1779 63.7694 19.3233 63.2381 19.4272 62.4875L20.4897 56.5052C20.4897 56.1819 20.4897 55.8701 20.1663 55.6506L15.7893 51.4816C15.2581 50.9503 15.5815 49.9918 16.3206 49.8763L22.3028 49.0217C22.6262 49.0217 22.8341 48.8138 23.0535 48.4904L25.7212 43.051C25.9291 42.4158 27.0031 42.4158 27.3265 43.051Z"
          fill="#F1CCA5"
        />
        <path
          d="M29.9943 62.3142C29.6941 62.3142 29.3823 62.2334 29.1051 62.0717L23.862 59.3462C23.7465 59.3116 23.6772 59.3231 23.6541 59.3231L18.3532 62.0833C17.7527 62.3835 17.0482 62.3142 16.5054 61.9216C15.9742 61.5405 15.7086 60.9284 15.801 60.2932L16.8635 54.3572C16.8635 54.2879 16.8635 54.207 16.8519 54.1724L12.4172 49.9918C11.9322 49.5067 11.782 48.8254 11.9899 48.1902C12.1978 47.555 12.7406 47.093 13.3873 47.0007L19.3695 46.1461C19.4042 46.1576 19.4504 46.123 19.5543 45.9613L22.1643 40.6142C22.3144 40.1292 22.8572 39.7365 23.5155 39.6903C24.2431 39.6441 24.9014 40.0021 25.2132 40.6142L27.8809 46.0537C27.8925 46.0883 27.9964 46.123 28.0888 46.1576L33.9902 47.0007C34.6369 47.093 35.1797 47.5434 35.3876 48.1902C35.5955 48.8254 35.4453 49.5183 34.9834 49.9802L30.7104 54.1493C30.6873 54.1724 30.6411 54.2532 30.6411 54.3687L31.692 60.2586C31.7959 60.963 31.4957 61.6329 30.9413 62.014C30.6411 62.2219 30.3177 62.3142 29.9943 62.3142ZM23.7003 57.7063C23.9313 57.7063 24.1738 57.741 24.4163 57.8218L24.5318 57.868L29.8673 60.6397C29.9828 60.6974 30.0059 60.6859 30.0174 60.6743C30.0405 60.6512 30.0867 60.5935 30.0752 60.5126L29.0011 54.4149C29.0011 53.8606 29.209 53.3409 29.5555 52.9944L33.8285 48.8254C33.8631 48.7907 33.8516 48.733 33.84 48.6983C33.8285 48.6637 33.8054 48.6059 33.7477 48.6059L27.6268 47.7167C27.2457 47.5896 26.6106 47.3702 26.368 46.6773L23.7465 41.3418C23.7234 41.3187 23.6194 41.2956 23.5733 41.3187L20.9286 46.7812C20.7092 47.1161 20.2819 47.7629 19.4504 47.7629L13.5836 48.6059C13.5259 48.6175 13.5028 48.6752 13.4912 48.6983C13.4797 48.733 13.4681 48.7907 13.5143 48.8369L17.8335 52.9482C18.4456 53.3986 18.4456 54.0685 18.4456 54.4265L18.4341 54.5651L17.3716 60.5473C17.4871 60.6512 17.5564 60.6628 17.6026 60.6397L22.9265 57.868C23.1575 57.7641 23.4231 57.7063 23.7003 57.7063Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54.6969 43.051L57.3646 48.4904C57.4686 48.8138 57.7919 48.9177 58.1153 49.0217L64.0975 49.8763C64.8482 49.9802 65.16 50.9388 64.6288 51.4816L60.3557 55.6506C60.1479 55.8585 60.0324 56.1819 60.0324 56.5052L61.0949 62.4875C61.1988 63.2381 60.4597 63.8733 59.709 63.446L54.3735 60.6743C54.0501 60.5704 53.7383 60.5704 53.5189 60.6743L48.1834 63.446C47.5482 63.7694 46.6936 63.2381 46.7976 62.4875L47.86 56.5052C47.86 56.1819 47.86 55.8701 47.5367 55.6506L43.1597 51.4816C42.6285 50.9503 42.9518 49.9918 43.691 49.8763L49.6732 49.0217C49.9965 49.0217 50.2044 48.8138 50.4238 48.4904L53.0916 43.051C53.311 42.4158 54.3735 42.4158 54.6969 43.051Z"
          fill="#F1CCA5"
        />
        <path
          d="M57.3647 62.3142C57.0644 62.3142 56.7526 62.2334 56.4754 62.0717L51.2323 59.3462C51.1168 59.3116 51.0476 59.3231 51.0245 59.3231L45.7236 62.0833C45.1231 62.3835 44.4186 62.3142 43.8758 61.9216C43.3446 61.5405 43.079 60.9284 43.1713 60.2932L44.2338 54.3572C44.2338 54.2879 44.2338 54.207 44.2223 54.1724L39.7876 49.9918C39.3025 49.5067 39.1524 48.8254 39.3603 48.1902C39.5681 47.555 40.1109 47.093 40.7577 47.0007L46.7399 46.1461C46.7745 46.1576 46.8207 46.123 46.9247 45.9613L49.5462 40.6142C49.6964 40.1292 50.2391 39.7365 50.8974 39.6903C51.625 39.6441 52.2833 40.0021 52.5951 40.6142L55.2628 46.0537C55.2744 46.0883 55.3783 46.123 55.4707 46.1576L61.3721 47.0007C62.0188 47.093 62.5616 47.5434 62.7695 48.1902C62.9774 48.8254 62.8272 49.5183 62.3653 49.9802L58.0923 54.1493C58.0692 54.1724 58.023 54.2532 58.023 54.3687L59.0739 60.2586C59.1778 60.963 58.8776 61.6329 58.3232 62.014C58.023 62.2219 57.6996 62.3142 57.3647 62.3142ZM51.0707 57.7063C51.3016 57.7063 51.5442 57.7525 51.7867 57.8218L51.9022 57.868L57.2377 60.6397C57.3531 60.6974 57.3762 60.6859 57.3878 60.6743C57.4109 60.6512 57.4571 60.5935 57.4455 60.5126L56.3715 54.4149C56.3715 53.8606 56.5794 53.3409 56.9258 52.9944L61.1989 48.8254C61.2335 48.7907 61.222 48.733 61.2104 48.6983C61.1989 48.6637 61.1758 48.6059 61.118 48.6059L54.9972 47.7167C54.6161 47.5896 53.9809 47.3702 53.7384 46.6773L51.1168 41.3418C51.0937 41.3187 50.9898 41.2956 50.9436 41.3187L48.299 46.7812C48.0795 47.1161 47.6522 47.7629 46.8207 47.7629L40.954 48.6059C40.8962 48.6175 40.8731 48.6752 40.8616 48.6983C40.8501 48.733 40.8385 48.7907 40.8847 48.8369L45.2039 52.9482C45.816 53.3986 45.816 54.0685 45.816 54.4265L45.8044 54.5651L44.742 60.5473C44.8575 60.6512 44.9267 60.6628 44.9729 60.6397L50.2969 57.868C50.5394 57.7641 50.7935 57.7063 51.0707 57.7063Z"
          fill="#EA7200"
        />
      </g>
      <defs>
        <clipPath id="clip1">
          <rect width="80" height="80" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
