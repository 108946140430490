import React, { ReactNode, useReducer, useMemo, useEffect } from 'react';

import { LaborDispatchContext, LaborStateContext } from './LaborContext';
import { LaborStateContextValue, LaborDispatchContextValue } from './LaborContext.types';
import { laborReducer } from './LaborContextContextSlice';
import { laborStorage } from './LaborContextStorage';

export const LaborContextController = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(laborReducer, laborStorage.state);

  useEffect(() => {
    laborStorage.state = state;
  }, [state]);

  useEffect(() => {
    return () => laborStorage.clear();
  }, []);

  const stateValue = useMemo<LaborStateContextValue>(() => state, [state]);
  const dispatchValue = useMemo<LaborDispatchContextValue>(
    () => ({
      laborDispatch: dispatch,
    }),
    [dispatch],
  );

  return (
    <LaborStateContext.Provider value={stateValue}>
      <LaborDispatchContext.Provider value={dispatchValue}>{children}</LaborDispatchContext.Provider>
    </LaborStateContext.Provider>
  );
};
