import React, { useCallback } from 'react';
import { Redirect, useHistory } from 'react-router-dom';

import { movingRoutes } from 'common/routing/AppRoute';
import { MovingFormStep1Step2 } from 'aMove/app/moving/movingFormStep1Step2/MovingFormStep1Step2';
import { MovingStep1Step2FormValues } from 'aMove/app/moving/movingFormStep1Step2/MovingFormStep1Step2.types';
import { useMovingContext } from 'aMove/hooks/useMoving/useMoving';
import { movingActions } from 'aMove/context/moving/MovingContextSlice';

export const MovingStep2 = () => {
  const history = useHistory();
  const [{ moving }, { movingDispatch }] = useMovingContext();

  const handleSubmit = useCallback(
    (values: MovingStep1Step2FormValues, isDirty) => {
      if (!isDirty) history.push(movingRoutes.step3);

      const { apartment, rooms, ...restValues } = values;

      movingDispatch(
        movingActions.setStep2({
          ...restValues,
          fullAddress: { ...restValues.fullAddress, address2: apartment },
        }),
      );
      history.push(movingRoutes.step3);
    },
    [movingDispatch, history],
  );

  if (!moving?.step1) {
    return <Redirect to={movingRoutes.step1} />;
  }

  return (
    <MovingFormStep1Step2
      title="2. Moving to"
      onSubmit={handleSubmit}
      onBackClick={() => history.push(movingRoutes.step1)}
      defaultValues={{
        shortAddress: moving?.step2?.shortAddress || '',
        fullAddress: moving?.step2?.fullAddress || null,
        apartment: moving?.step2?.fullAddress.address2 || '',
        typeOfDwelling: moving?.step2?.typeOfDwelling ?? null,
        floor: moving?.step2?.floor ?? null,
        accessType: moving?.step2?.accessType ?? null,
        truckParking: moving?.step2?.truckParking ?? null,
      }}
      tipBoxData={{
        icon: 'diamond',
        text:
          'Our HUNKS are background checked, licensed and insured. You can rest easy knowing your valuables are kept safe.',
      }}
    />
  );
};
