import { colors } from '@chhjpackages/components';
import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 40,
    },
  },

  container: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    background: colors.basic.white,

    marginBottom: theme.spacing(3),

    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
      marginRight: 0,
      padding: 24,
    },

    boxShadow: '0px 0px 16px rgba(134, 145, 166, 0.16)',
  },

  title: {
    [theme.breakpoints.up('md')]: {
      ...theme.typography.h3,
    },
  },

  label: {
    fontFamily: 'Noah',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 1.5,
    textTransform: 'uppercase',
  },
  subtitle: {
    marginBottom: 32,

    [theme.breakpoints.up('md')]: {
      marginBottom: 40,
    },
  },
  buttonsGroup: {
    marginTop: 48,
    [theme.breakpoints.up('sm')]: {
      marginBottom: 32,
    },

    [theme.breakpoints.up('md')]: {
      marginBottom: 64,
    },
  },

  bold: {
    fontWeight: 'bold',
  },

  helperText: {
    fontSize: 14,
    height: 14,
    color: colors.grey140,
    margin: '4px 0 6px',
  },

  errorText: {
    color: theme.palette.error.main,
  },

  linkText: {
    textDecoration: 'underline',
    color: colors.secondary.main,
    display: 'inline-block',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const squareInputStyles = {
  color: colors.secondary.main,
  fontFamily: 'Helvetica Neue', // Noah not available for square fields
  fontWeight: '500',
  fontSize: '16px',
  lineHeight: '24px',
  padding: '16px',
  placeholderColor: colors.grey140,
  placeholderFontWeight: '500',
};
