import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const RoomOfItemsIcon = ({ className }: { className?: string }) => {
  return (
    <SvgIcon className={className} width="80" height="80" viewBox="0 0 80 80" fill="none">
      <g clipPath="url(#clip10)">
        <path
          d="M51.481 32.7207H24.5347C24.1153 32.7207 23.8008 33.0353 23.8008 33.4546V34.608C23.8008 35.0274 24.1153 35.3419 24.5347 35.3419H26.8414V53.6906H29.0432L30.9305 35.3419H44.9803L46.8676 53.6906H49.0695V35.4468H51.3761C51.7955 35.4468 52.1101 35.1322 52.1101 34.7128V33.4546C52.2149 33.0353 51.9004 32.7207 51.481 32.7207Z"
          fill="#F1CCA5"
        />
        <path
          d="M51.5858 57.2554H24.325C24.0105 57.2554 23.8008 57.4651 23.8008 57.7796V58.7233C23.8008 59.0378 24.0105 59.2475 24.325 59.2475H51.5858C51.9004 59.2475 52.1101 59.0378 52.1101 58.7233V57.7796C52.2149 57.4651 51.9004 57.2554 51.5858 57.2554Z"
          fill="#F1CBA1"
        />
        <path
          d="M50.223 59.457H25.6883C25.3738 59.457 25.1641 59.6667 25.1641 59.9813V65.2237C25.1641 65.5383 25.3738 65.748 25.6883 65.748C25.3738 65.748 25.1641 65.9577 25.1641 66.2722V71.5147C25.1641 71.8293 25.3738 72.0389 25.6883 72.0389H50.3279C50.6424 72.0389 50.8521 71.8293 50.8521 71.5147V66.2722C50.8521 65.9577 50.6424 65.748 50.3279 65.748C50.6424 65.748 50.8521 65.5383 50.8521 65.2237V59.9813C50.8521 59.6667 50.5376 59.457 50.223 59.457Z"
          fill="#F1CBA1"
        />
        <path
          d="M78.1131 77.806H63.4342C62.3857 77.806 61.5469 76.9672 61.5469 75.9187V61.2398C61.5469 60.1913 62.3857 59.3525 63.4342 59.3525H78.0082C79.0567 59.3525 79.8955 60.1913 79.8955 61.2398V75.8139C80.0003 76.9672 79.1615 77.806 78.1131 77.806Z"
          fill="#F1CBA1"
        />
        <path
          d="M76.4363 16.0498H60.4992C59.5556 16.0498 58.7168 16.8886 58.7168 17.8322V48.7628C58.7168 49.7064 59.5556 50.5452 60.4992 50.5452H61.6526V51.6986H63.9593V50.5452H73.186V51.6986H75.4927V50.5452H76.3315C77.2751 50.5452 78.1139 49.7064 78.1139 48.7628V17.8322C78.2188 16.8886 77.38 16.0498 76.4363 16.0498Z"
          fill="#F1CBA1"
        />
        <path
          d="M74.3388 49.4966H59.0308C57.458 49.4966 56.3047 48.2384 56.3047 46.7706V16.3643C56.3047 14.7915 57.5629 13.6382 59.0308 13.6382H74.3388C75.9115 13.6382 77.0648 14.8964 77.0648 16.3643V46.6657C77.0648 48.2384 75.8066 49.4966 74.3388 49.4966ZM59.0308 14.8964C58.192 14.8964 57.5629 15.5255 57.5629 16.3643V46.6657C57.5629 47.5045 58.192 48.1336 59.0308 48.1336H74.3388C75.1775 48.1336 75.8066 47.5045 75.8066 46.6657V16.3643C75.8066 15.5255 75.1775 14.8964 74.3388 14.8964H59.0308Z"
          fill="#EB7200"
        />
        <path d="M76.4356 26.0103H56.9336V27.2684H76.4356V26.0103Z" fill="#EB7200" />
        <path
          d="M59.6603 24.5424C59.3458 24.5424 59.0312 24.2279 59.0312 23.9133V17.7272C59.0312 17.4127 59.3458 17.0981 59.6603 17.0981C59.9749 17.0981 60.2894 17.4127 60.2894 17.7272V23.8085C60.2894 24.2279 60.0797 24.5424 59.6603 24.5424Z"
          fill="#006B34"
        />
        <path
          d="M59.6603 36.0758C59.3458 36.0758 59.0312 35.7612 59.0312 35.4467V29.3654C59.0312 29.0509 59.3458 28.7363 59.6603 28.7363C59.9749 28.7363 60.2894 29.0509 60.2894 29.3654V35.4467C60.2894 35.7612 60.0797 36.0758 59.6603 36.0758Z"
          fill="#006B34"
        />
        <path
          d="M62.176 50.6498H59.8693C59.5548 50.6498 59.2402 50.3353 59.2402 50.0207V48.8674C59.2402 48.5528 59.5548 48.2383 59.8693 48.2383H62.176C62.4906 48.2383 62.8051 48.5528 62.8051 48.8674V50.0207C62.8051 50.3353 62.5954 50.6498 62.176 50.6498Z"
          fill="#EB7200"
        />
        <path
          d="M73.7092 50.6498H71.4025C71.088 50.6498 70.7734 50.3353 70.7734 50.0207V48.8674C70.7734 48.5528 71.088 48.2383 71.4025 48.2383H73.7092C74.0238 48.2383 74.3383 48.5528 74.3383 48.8674V50.0207C74.4432 50.3353 74.1286 50.6498 73.7092 50.6498Z"
          fill="#EB7200"
        />
        <path
          d="M35.7539 11.4366V6.82325C35.7539 5.25051 34.6006 4.09717 33.0279 4.09717H11.1144C9.64648 4.09717 8.38829 5.25051 8.38829 6.82325V11.4366C6.7107 11.4366 5.34766 12.7997 5.34766 14.4772V20.0343V25.2767H38.48V20.0343V14.4772C38.5849 12.9045 37.3267 11.6463 35.7539 11.4366Z"
          fill="#F1CCA5"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.39534 23.2842C6.60504 23.2842 6.91959 23.4939 6.91959 23.8084V28.2121C6.91959 28.4218 6.70989 28.7363 6.39534 28.7363C6.08079 28.7363 5.87109 28.4218 5.87109 28.2121V23.8084C5.87109 23.389 6.08079 23.2842 6.39534 23.2842Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.7039 23.2842C34.9136 23.2842 35.2282 23.4939 35.2282 23.8084V28.2121C35.2282 28.4218 35.0185 28.7363 34.7039 28.7363C34.3894 28.7363 34.1797 28.5266 34.1797 28.2121V23.8084C34.2845 23.389 34.3894 23.2842 34.7039 23.2842Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.54102 14.2675C9.54102 14.0578 9.75071 13.8481 9.96041 13.8481H31.0351C31.2448 13.8481 31.4545 14.0578 31.4545 14.2675C31.4545 14.4772 31.2448 14.6869 31.0351 14.6869H9.96041C9.75071 14.6869 9.54102 14.4772 9.54102 14.2675Z"
          fill="#006A36"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.6156 12.9044C30.6156 11.0171 32.0835 9.44434 34.0756 9.44434C35.9629 9.44434 37.5356 11.0171 37.5356 12.9044V24.2281H3.56445V12.9044C3.56445 11.0171 5.03234 9.44434 7.02448 9.44434C8.91177 9.44434 10.4845 10.9122 10.4845 12.9044V18.042H30.6156V12.9044ZM34.0756 10.388C32.6077 10.388 31.5592 11.5413 31.5592 12.9044V18.8808H9.54086V12.9044C9.54086 11.4365 8.38752 10.388 7.02448 10.388C5.66144 10.388 4.40325 11.4365 4.40325 12.9044V23.2844H36.4871V12.9044C36.6968 11.4365 35.4386 10.388 34.0756 10.388Z"
          fill="#EA7200"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.6638 2C33.4463 2 34.8093 3.46789 34.8093 5.14548V9.8637H33.8657V5.14548C33.8657 3.88729 32.8172 2.94364 31.6638 2.94364H9.64548C8.38729 2.94364 7.44364 3.99214 7.44364 5.14548V9.8637H6.5V5.14548C6.5 3.36304 7.96789 2 9.64548 2H31.6638Z"
          fill="#EA7200"
        />
        <path
          d="M15.3076 48.6579H3.7742C2.83056 48.6579 2.20146 47.924 2.30631 46.9803L3.5645 35.342C3.5645 34.5032 4.1936 33.979 5.03239 33.979H14.0494C14.7834 33.979 15.5173 34.6081 15.5173 35.342L16.7755 46.9803C16.8804 47.8191 16.2513 48.6579 15.3076 48.6579Z"
          fill="#F1CCA5"
        />
        <path
          d="M16.2523 71.8296H2.8316C2.4122 71.8296 2.09766 72.1441 2.09766 72.5635V73.1926C2.09766 73.612 2.4122 73.9266 2.8316 73.9266H16.2523C16.6717 73.9266 16.9863 73.612 16.9863 73.1926V72.5635C16.9863 72.1441 16.6717 71.8296 16.2523 71.8296Z"
          fill="#F1CCA5"
        />
        <path
          d="M14.0491 48.0289H2.20112C1.67687 48.0289 1.04778 47.8192 0.733231 47.3998C0.418683 46.9804 0.208984 46.4561 0.208984 45.827L1.46718 33.8742C1.57202 32.8257 2.41082 32.0918 3.45931 32.0918H12.7909C13.8394 32.0918 14.6782 32.8257 14.783 33.8742L16.0412 45.827C16.1461 46.3513 15.9364 46.9804 15.517 47.3998C15.0976 47.8192 14.6782 48.0289 14.0491 48.0289ZM3.45931 33.35C3.14476 33.35 2.83022 33.6645 2.72537 33.9791L1.46718 46.0367C1.46718 46.2464 1.46718 46.4561 1.67687 46.561C1.78172 46.6658 1.99142 46.7707 2.20112 46.7707H14.1539C14.3636 46.7707 14.5733 46.6658 14.6782 46.561C14.783 46.4561 14.8879 46.2464 14.8879 46.0367L13.6297 34.0839C13.6297 33.7694 13.3151 33.4548 12.8957 33.4548H3.45931V33.35Z"
          fill="#EB7200"
        />
        <path d="M8.70155 47.3999H7.44336V70.5716H8.70155V47.3999Z" fill="#226937" />
        <path
          d="M14.8886 73.2976H1.36304C0.629096 73.2976 0 72.6685 0 71.9345V71.3054C0 70.5715 0.629096 69.9424 1.36304 69.9424H14.7837C15.5177 69.9424 16.1468 70.5715 16.1468 71.3054V71.9345C16.2516 72.6685 15.6225 73.2976 14.8886 73.2976ZM1.36304 71.3054C1.36304 71.3054 1.25819 71.3054 1.36304 71.3054L1.25819 71.9345C1.25819 71.9345 1.25819 72.0394 1.36304 72.0394H14.7837C14.7837 72.0394 14.8886 72.0394 14.8886 71.9345V71.3054C14.8886 71.3054 14.8886 71.2006 14.7837 71.2006H1.36304V71.3054Z"
          fill="#EB7200"
        />
        <path
          d="M50.1179 59.2477H23.1716C22.4377 59.2477 21.8086 58.6187 21.8086 57.8847V57.2556C21.8086 56.5217 22.4377 55.8926 23.1716 55.8926H50.1179C50.8518 55.8926 51.4809 56.5217 51.4809 57.2556V57.8847C51.4809 58.6187 50.8518 59.2477 50.1179 59.2477ZM23.1716 57.1508C23.1716 57.1508 23.0668 57.1508 23.1716 57.1508L23.0668 57.7799C23.0668 57.7799 23.0668 57.8847 23.1716 57.8847H50.1179C50.1179 57.8847 50.2228 57.8847 50.2228 57.7799V57.1508C50.2228 57.1508 50.2227 57.0459 50.1179 57.0459H23.1716V57.1508Z"
          fill="#EB7200"
        />
        <path
          d="M48.7551 65.7482H24.5349C23.801 65.7482 23.1719 65.1191 23.1719 64.3852V59.4573C23.1719 58.7233 23.801 58.0942 24.5349 58.0942H48.7551C49.489 58.0942 50.1181 58.7233 50.1181 59.4573V64.3852C50.223 65.1191 49.5939 65.7482 48.7551 65.7482ZM24.5349 59.3524C24.5349 59.3524 24.4301 59.3524 24.5349 59.3524L24.4301 64.3852C24.4301 64.3852 24.4301 64.49 24.5349 64.49H48.7551C48.7551 64.49 48.8599 64.49 48.8599 64.3852V59.4573C48.8599 59.4573 48.86 59.3524 48.7551 59.3524H24.5349Z"
          fill="#EB7200"
        />
        <path
          d="M48.7551 72.1442H24.5349C23.801 72.1442 23.1719 71.5151 23.1719 70.7812V65.8533C23.1719 65.1193 23.801 64.4902 24.5349 64.4902H48.7551C49.489 64.4902 50.1181 65.1193 50.1181 65.8533V70.7812C50.223 71.5151 49.5939 72.1442 48.7551 72.1442ZM24.5349 65.7484C24.5349 65.7484 24.4301 65.7484 24.5349 65.7484L24.4301 70.7812C24.4301 70.7812 24.4301 70.886 24.5349 70.886H48.7551C48.7551 70.886 48.8599 70.886 48.8599 70.7812V65.8533C48.8599 65.8533 48.86 65.7484 48.7551 65.7484H24.5349Z"
          fill="#EB7200"
        />
        <path
          d="M47.6018 78.1203C47.2873 78.1203 46.9727 77.8058 46.9727 77.4913V72.2488H26.4223V77.4913C26.4223 77.8058 26.1077 78.1203 25.7932 78.1203C25.4786 78.1203 25.1641 77.8058 25.1641 77.4913V71.5148C25.1641 71.2003 25.4786 70.8857 25.7932 70.8857H47.6018C47.9164 70.8857 48.2309 71.2003 48.2309 71.5148V77.4913C48.2309 77.8058 47.9164 78.1203 47.6018 78.1203Z"
          fill="#EB7200"
        />
        <path
          d="M31.5591 62.6029H28.3088C27.9942 62.6029 27.6797 62.2884 27.6797 61.9738C27.6797 61.6593 27.9942 61.3447 28.3088 61.3447H31.5591C31.8737 61.3447 32.1882 61.6593 32.1882 61.9738C32.1882 62.2884 31.8737 62.6029 31.5591 62.6029Z"
          fill="#226937"
        />
        <path
          d="M45.0845 62.6029H41.8342C41.5196 62.6029 41.2051 62.2884 41.2051 61.9738C41.2051 61.6593 41.5196 61.3447 41.8342 61.3447H45.0845C45.3991 61.3447 45.7136 61.6593 45.7136 61.9738C45.7136 62.2884 45.5039 62.6029 45.0845 62.6029Z"
          fill="#226937"
        />
        <path
          d="M31.5591 68.9989H28.3088C27.9942 68.9989 27.6797 68.6844 27.6797 68.3698C27.6797 68.0553 27.9942 67.7407 28.3088 67.7407H31.5591C31.8737 67.7407 32.1882 68.0553 32.1882 68.3698C32.1882 68.6844 31.8737 68.9989 31.5591 68.9989Z"
          fill="#226937"
        />
        <path
          d="M45.0845 68.9989H41.8342C41.5196 68.9989 41.2051 68.6844 41.2051 68.3698C41.2051 68.0553 41.5196 67.7407 41.8342 67.7407H45.0845C45.3991 67.7407 45.7136 68.0553 45.7136 68.3698C45.7136 68.6844 45.5039 68.9989 45.0845 68.9989Z"
          fill="#226937"
        />
        <path
          d="M77.4827 76.8625H60.9165C59.9728 76.8625 59.3438 76.1286 59.3438 75.2898V58.7236C59.3438 57.78 60.0777 57.1509 60.9165 57.1509H77.3778C78.3215 57.1509 78.9506 57.8848 78.9506 58.7236V75.185C79.0554 76.1286 78.3215 76.8625 77.4827 76.8625ZM60.9165 58.4091C60.7068 58.4091 60.6019 58.5139 60.6019 58.7236V75.185C60.6019 75.3947 60.7068 75.4995 60.9165 75.4995H77.3778C77.5875 75.4995 77.6924 75.3947 77.6924 75.185V58.7236C77.6924 58.5139 77.5875 58.4091 77.3778 58.4091H60.9165Z"
          fill="#EB7200"
        />
        <path d="M77.9027 61.4497H59.4492V62.7079H77.9027V61.4497Z" fill="#EB7200" />
        <path d="M78.4281 72.144H59.9746V73.4022H78.4281V72.144Z" fill="#EB7200" />
        <path
          d="M71.7155 73.4022C71.0864 73.4022 70.4573 73.1925 69.8282 72.878C69.6185 72.7731 69.5137 72.5634 69.5137 72.3537C69.5137 72.144 69.6185 71.9343 69.8282 71.8295C70.5622 71.4101 70.9816 70.6761 70.9816 69.8373C70.9816 68.9985 70.5622 68.2646 69.8282 67.8452C69.6185 67.7403 69.5137 67.5307 69.5137 67.321C69.5137 67.1113 69.6185 66.9016 69.8282 66.7967C70.4573 66.4822 70.9816 66.2725 71.7155 66.2725C73.7076 66.2725 75.3852 67.9501 75.3852 69.9422C75.3852 71.8295 73.7076 73.4022 71.7155 73.4022ZM71.5058 72.144C72.8688 72.2489 74.127 71.2004 74.127 69.8373C74.127 68.4743 72.8688 67.321 71.5058 67.5307C72.03 68.1597 72.3446 68.9985 72.3446 69.8373C72.3446 70.6761 72.03 71.4101 71.5058 72.144Z"
          fill="#EB7200"
        />
        <path
          d="M68.6764 73.4025C68.0473 73.4025 67.4183 73.1928 66.7892 72.8783C66.5795 72.7734 66.4746 72.5637 66.4746 72.354C66.4746 72.1443 66.5795 71.9346 66.7892 71.8298C67.5231 71.4104 67.9425 70.6764 67.9425 69.8376C67.9425 68.9988 67.5231 68.2649 66.7892 67.8455C66.5795 67.7407 66.4746 67.531 66.4746 67.3213C66.4746 67.1116 66.5795 66.9019 66.7892 66.797C67.9425 66.1679 69.3055 66.1679 70.4589 66.797C71.6122 67.4261 72.2413 68.6843 72.2413 69.9425C72.2413 71.2007 71.5074 72.4589 70.4589 73.088C69.9346 73.2977 69.4104 73.4025 68.6764 73.4025ZM68.4667 72.1443C68.991 72.1443 69.4104 72.0395 69.8298 71.8298C70.5637 71.4104 70.9831 70.6764 70.9831 69.8376C70.9831 68.9988 70.5637 68.2649 69.8298 67.8455C69.4104 67.6358 68.8861 67.531 68.4667 67.531C68.991 68.1601 69.3055 68.9988 69.3055 69.8376C69.3055 70.6764 69.0958 71.4104 68.4667 72.1443Z"
          fill="#EB7200"
        />
        <path
          d="M65.74 73.4024C63.7479 73.4024 62.0703 71.7248 62.0703 69.7327C62.0703 67.7406 63.7479 66.063 65.74 66.063C66.3691 66.063 66.9982 66.2727 67.6273 66.5872C68.7807 67.2163 69.4098 68.4745 69.4098 69.7327C69.4098 70.9909 68.6758 72.2491 67.6273 72.8782C66.9982 73.2976 66.3691 73.4024 65.74 73.4024ZM65.74 67.426C64.4818 67.426 63.4334 68.4745 63.4334 69.8376C63.4334 71.0958 64.4818 72.2491 65.74 72.2491C66.1594 72.2491 66.5788 72.1442 66.8934 71.9345C67.6273 71.5151 68.0467 70.7812 68.0467 69.9424C68.0467 69.1036 67.6273 68.3697 66.8934 67.9503C66.474 67.5309 66.1594 67.426 65.74 67.426Z"
          fill="#EB7200"
        />
        <path
          d="M65.1111 60.5058H62.0705C61.756 60.5058 61.4414 60.1912 61.4414 59.8767C61.4414 59.5621 61.756 59.2476 62.0705 59.2476H65.1111C65.4257 59.2476 65.7402 59.5621 65.7402 59.8767C65.7402 60.1912 65.4257 60.5058 65.1111 60.5058Z"
          fill="#226937"
        />
        <path
          d="M50.1179 35.0274H23.1716C22.4377 35.0274 21.8086 34.3983 21.8086 33.6643V32.511C21.8086 31.777 22.4377 31.1479 23.1716 31.1479H50.1179C50.8518 31.1479 51.4809 31.777 51.4809 32.511V33.6643C51.4809 34.3983 50.8518 35.0274 50.1179 35.0274ZM23.1716 32.4061C23.1716 32.4061 23.0668 32.4061 23.1716 32.4061L23.0668 33.6643C23.0668 33.6643 23.0668 33.7692 23.1716 33.7692H50.1179C50.1179 33.7692 50.2228 33.7692 50.2228 33.6643V32.511C50.2228 32.511 50.2227 32.4061 50.1179 32.4061H23.1716Z"
          fill="#EB7200"
        />
        <path
          d="M27.7841 53.3759H25.5822C25.2677 53.3759 24.9531 53.0613 24.9531 52.7468V34.3981C24.9531 34.0836 25.2677 33.769 25.5822 33.769H29.6713C29.881 33.769 29.9859 33.8739 30.1956 33.9787C30.3004 34.0836 30.4053 34.2933 30.4053 34.503L28.518 52.8516C28.4132 53.1662 28.0986 53.3759 27.7841 53.3759ZM26.2113 52.1177H27.155L28.8325 35.0272H26.2113V52.1177Z"
          fill="#EB7200"
        />
        <path
          d="M47.81 53.3759H45.6082C45.2936 53.3759 44.9791 53.1662 44.9791 52.7468L43.0918 34.503C43.0918 34.2933 43.0918 34.0836 43.3015 33.9787C43.4063 33.8739 43.616 33.769 43.8257 33.769H47.9149C48.2294 33.769 48.544 34.0836 48.544 34.3981V52.7468C48.4391 53.1662 48.1246 53.3759 47.81 53.3759ZM46.1324 52.1177H47.0761V35.0272H44.35L46.1324 52.1177Z"
          fill="#EB7200"
        />
      </g>
      <defs>
        <clipPath id="clip10">
          <rect width="80" height="76.1206" fill="white" transform="translate(0 2)" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
};
