import {
  FormAction,
  RESET_FORM,
  SET_READY_FOR_PROCESSING,
  SET_BOOKING_DONE_AND_CONTACT,
  SET_GLOBAL_DATA,
  FormStatereadyForRequestProcessingType,
  FormStateGlobalDataType,
  SET_CALL_ME_NOW,
  FormStateContact,
  FormStatePartnerData,
  SET_PARTNER_DATA,
  FormStateUserData,
  SET_USER_DATA,
} from '../formReducer/formReducer.types';

type ResetFormType = () => FormAction;
type SetReadyToProcessing = (payload: FormStatereadyForRequestProcessingType) => FormAction;
type SetCallMeNow = (payload: FormStateContact) => FormAction;
type SetGlobalData = (payload: FormStateGlobalDataType) => FormAction;
type SetBookingDoneAndContact = (payload: FormStateContact) => FormAction;
type SetPartnerData = (payload: FormStatePartnerData) => FormAction;
type SetUserData = (payload: FormStateUserData) => FormAction;

export const setGlobalData: SetGlobalData = (payload) => ({
  type: SET_GLOBAL_DATA,
  payload,
});

export const setReadyToProcessing: SetReadyToProcessing = (payload) => ({
  type: SET_READY_FOR_PROCESSING,
  payload,
});

export const setCallMeNowPhone: SetCallMeNow = (payload) => ({
  type: SET_CALL_ME_NOW,
  payload,
});

export const setBookingDoneAndContact: SetBookingDoneAndContact = (payload) => ({
  type: SET_BOOKING_DONE_AND_CONTACT,
  payload,
});

export const setPartnerData: SetPartnerData = (payload) => ({
  type: SET_PARTNER_DATA,
  payload,
});

export const resetForm: ResetFormType = () => ({
  type: RESET_FORM,
  payload: undefined,
});

export const setUserData: SetUserData = (payload) => ({
  type: SET_USER_DATA,
  payload,
});
