import React, { useMemo } from 'react';
import { Typography, useMediaQuery, useTheme } from '@material-ui/core';

import { Container } from 'common/app/home/container/Container';
import { CarouselSlider } from 'common/ui/carouselSlider/CarouselSlider';
import { FiveStarsIcon } from 'common/assets/icons/FiveStarsIcon';
import { MealIcon } from 'common/assets/icons/MealIcon';
import { CalendarIcon } from 'common/assets/icons/CalendarIcon';

import { useStyles } from './WhyHunks.styles';

const data = [
  {
    Icon: FiveStarsIcon,
    title: '100,000+ 5-star reviews',
    subTitle: "Our values demand that we do whatever it takes to fulfill our client's needs.",
  },
  {
    Icon: MealIcon,
    title: '2 meals donated per service',
    subTitle: 'We want to bring an end to child hunger around the world.',
  },
  {
    Icon: CalendarIcon,
    title: 'Same and next-day services',
    subTitle: 'We offer same-day and next day appointments for your convienience!',
  },
];

export const WhyHunks = () => {
  const styles = useStyles();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const slidesToShow = useMemo(() => {
    if (isDesktop) return 3;
    if (isTablet) return 2;
    return 1;
  }, [isTablet, isDesktop]);

  return (
    <div className={styles.wrapper}>
      <Container>
        <Typography variant="h3" className={styles.mainTitle}>
          Why H.U.N.K.S.?
        </Typography>

        <div className={styles.innerWrapper}>
          <CarouselSlider settings={{ slidesToShow }}>
            {data.map(({ Icon, title, subTitle }) => (
              <div key={title} className={styles.sliderItem}>
                <Icon className={styles.icon} />
                <Typography variant="h5" color="secondary" className={styles.title}>
                  {title}
                </Typography>

                <Typography className={styles.subTitle}>{subTitle}</Typography>
              </div>
            ))}
          </CarouselSlider>
        </div>
      </Container>
    </div>
  );
};
